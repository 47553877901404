import { Tooltip } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { memo, useCallback } from "react";
import useStore from "../../../../Store/Store";
import { Pre, Line, LineNo, LineContent } from "./styles";
import { unlinkCell } from "../../../../Store/StoreHandlers";
import { startRecordingActions } from "../../../../Store/UndoManager";

export interface VirtualizedListProps {
  virtualizedListRef: React.RefObject<HTMLPreElement>;
  tokens: any[];
  containerHeight: number;
  itemHeight: number;
  className: string;
  style: React.CSSProperties;
  visibleRows: any[];
  startIndex: number;
  popUpLoc: { x: number; y: number };
  selecting: boolean;
  selectionStartLine: number;
  selectionEndLine: number;
  userStillInSameFile: React.MutableRefObject<boolean>;
  getLineProps: (line: any) => any;
  getTokenProps: (token: any) => any;
  codeHighlightHandler: (i: number) => React.CSSProperties;
  lineClickHandler: (i: number) => void;
  startLineEndLineIdTagger: (i: number) => string;
  setScrollTop: (i: number) => void;
  setSelectedText: (text: string) => void;
  setPopUpLoc: (loc: { x: number; y: number }) => void;
  setSelecting: (b: boolean) => void;
  setSelectionStartLine: (i: number) => void;
  setSelectionEndLine: (i: number) => void;
}

const VirtualizedList: React.FC<VirtualizedListProps> = ({
  virtualizedListRef,
  tokens,
  containerHeight,
  itemHeight,
  className,
  style,
  visibleRows,
  startIndex,
  popUpLoc,
  selecting,
  selectionStartLine,
  selectionEndLine,
  userStillInSameFile,
  getLineProps,
  getTokenProps,
  codeHighlightHandler,
  lineClickHandler,
  startLineEndLineIdTagger,
  setScrollTop,
  setSelectedText,
  setPopUpLoc,
  setSelecting,
  setSelectionStartLine,
  setSelectionEndLine,
}) => {
  const { repoData, currentPath } = useStore((state) => ({
    repoData: state.repoData,
    currentPath: state.currentPath,
  }));

  const totalHeight = tokens.length * itemHeight;
  const onScroll = useCallback(
    (e: React.UIEvent<HTMLPreElement>) => {
      setScrollTop(e.currentTarget.scrollTop);
    },
    [setScrollTop]
  );

  const handleMouseDown = (lineNumber) => {
    if (!selecting) {
      setSelecting(true);
      setSelectionStartLine(lineNumber + 1);
    }
  };

  const getSelectedText = (startLine, endLine) => {
    const selectedTokens = tokens.slice(startLine, endLine + 1);
    return selectedTokens
      .map((line) => line.map((token) => token.content).join(""))
      .join("\n");
  };

  const handleMouseUp = (e, lineNumber) => {
    userStillInSameFile.current = true;
    // get selected text
    const isSomeTextSelected = window.getSelection().toString();
    if (selecting && selectionStartLine !== null && isSomeTextSelected) {
      const newSelectedText = getSelectedText(
        selectionStartLine - 1,
        lineNumber
      );

      // this is to handle the case of user clicking on a line but not selecting any text
      // to avoid confusion, we check if there is any selection, if not, we don't open popUp
      setSelectionEndLine(lineNumber + 1);
      setSelectedText(newSelectedText);
      setPopUpLoc({ x: e.clientX, y: e.clientY });
    } else {
      setSelectionStartLine(null);
      setSelectionEndLine(null);
    }
    setSelecting(false);
  };

  const deleteLinkHandler = async (e) => {
    await startRecordingActions("UNLINK", {
      supressWarning: true,
    });
    await e.stopPropagation(); // This will prevent the event from bubbling up to parent elements
    let currentRepoData = repoData[currentPath];
    if (currentRepoData?.wiki) {
      let message = `There is a wiki in this cell-code connection. Please select to save the wiki in the linked file or in the cell!`;
      useStore.getState().setDialog("UNLINK_DIALOG", {
        message: message,
        data: {
          cellIdentifier: currentRepoData?.cellId,
        },
      });
    } else {
      userStillInSameFile.current = true;
      await unlinkCell(currentRepoData.path, null, true);
    }
  };

  return (
    <Pre
      ref={virtualizedListRef}
      className={className + " virtualized-list"}
      style={{
        ...style,
        backgroundColor: "",
        height: containerHeight,
        overflowY: "scroll",
        position: "relative",
        margin: 0,
        padding: 0,
      }}
      onScroll={onScroll}
    >
      <div
        style={{
          height: totalHeight,
          position: "absolute",
          width: "100%",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: startIndex * itemHeight,
          width: "100%",
        }}
      >
        {visibleRows?.map((line, i) => {
          const lineNumber = i + startIndex;
          if (lineNumber === parseInt(repoData[currentPath]?.startLine)) {
          }
          return (
            <Line
              key={lineNumber}
              {...getLineProps({
                line,
                key: lineNumber,
              })}
              style={{ height: itemHeight }}
            >
              <div
                key={lineNumber}
                style={codeHighlightHandler(lineNumber)}
                onClick={() => lineClickHandler(lineNumber)}
                onMouseDown={() => handleMouseDown(lineNumber)}
                onMouseUp={(e) => handleMouseUp(e, lineNumber)}
                data-line-number={lineNumber}
              >
                <LineNo>
                  {
                    <span>
                      {parseInt(repoData[currentPath]?.startLine) - 1 !==
                      lineNumber ? (
                        lineNumber + 1
                      ) : (
                        <Tooltip title="Click to delete link to cell">
                          <div
                            className="unlinkIconWrapper"
                            onClick={async (e) => {
                              await deleteLinkHandler(e);
                            }}
                          >
                            <HighlightOffIcon style={{ height: "15px" }} />
                          </div>
                        </Tooltip>
                      )}
                    </span>
                  }
                </LineNo>

                <LineContent>
                  {line.map((token, key) => {
                    return (
                      <span
                        id={startLineEndLineIdTagger(lineNumber)}
                        key={key}
                        {...getTokenProps({
                          token,
                          key,
                        })}
                      />
                    );
                  })}
                </LineContent>
              </div>
            </Line>
          );
        })}
      </div>
    </Pre>
  );
};

export default memo(VirtualizedList);
