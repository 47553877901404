interface ISourceDocControlButtons {
  setHeight: Function;
  setIsMaxSD: Function;
  isMaxSD: Boolean;
  setIsOpenSD: Function;
  setWidth: Function;
}
export const SourceDocControlButtons: React.FC<ISourceDocControlButtons> = (
  props: ISourceDocControlButtons
) => {
  const { setIsMaxSD, setIsOpenSD } = props;

  return (
    <>
      <div className="SDMinimizeWrapper" onClick={() => setIsOpenSD(false)}>
        <div className="SDMinimize" />
      </div>
      <div
        className="SDMaximizeWrapper"
        id="sd-maximize-button"
        onClick={() => {
          setIsMaxSD((prevIsMaxSD) => !prevIsMaxSD);
        }}
      >
        <div className="SDMaximize" />
      </div>
    </>
  );
};
