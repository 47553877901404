import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  TooltipRenderProps,
} from "react-joyride";
import { useState, FC, useCallback } from "react";
import useStore from "../../Store/Store";
import { Typography, Box, Input } from "@mui/material";
import { theme } from "../../Themes/Themes";
import MessageDisplay from "../../utils/MessageDisplay";
import { loadPublicRepo } from "../LandingPage/LandingComponent";
import { logger } from "../../utils/Logger";

interface GuidedWizardProps {
  steps: Step[];
}

const CustomDialog: FC<TooltipRenderProps> = ({
  backProps,
  size,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
}: TooltipRenderProps) => {
  const [url, setUrl] = useState("");
  const onChange = useCallback(
    (val) => {
      setUrl(val?.target?.value);
    },
    [setUrl]
  );
  const handleClick = useCallback(
    (event) => {
      loadPublicRepo(url);
      primaryProps.onClick(event);
    },
    [url, primaryProps.onClick]
  );
  return (
    <Box
      {...tooltipProps}
      overflow="hidden"
      style={{
        backgroundColor: "whitesmoke",
        borderRadius: "30px",
        width: "35vw",
      }}
    >
      <br />
      <Typography
        textAlign={"center"}
        mx={3}
        my={0.8}
        fontSize="25px"
        fontFamily="Poppins-Light"
        color={theme.custom.darkerGrey}
        sx={{ paddingBottom: "15px", paddingTop: "15px" }}
      >
        {step?.title}
      </Typography>
      <Typography
        textAlign={"center"}
        mx={3}
        my={0.8}
        fontSize="15px"
        fontFamily="Poppins-Light"
        fontWeight="Light"
        color={theme.custom.darkerGrey}
        sx={{
          paddingBottom: "15px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <MessageDisplay message={step?.content.toString()} />
      </Typography>
      <div
        className="DialogButtonGroupHorizontal"
        style={{ paddingBottom: "10px" }}
      >
        {!isLastStep && (
          <div
            className="Button DialogButton"
            style={{ marginRight: "30px" }}
            {...skipProps}
          >
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontFamily="Poppins-Light"
              color="#ffaea6"
            >
              Skip
            </Typography>
          </div>
        )}
        {index > 0 && !isLastStep && (
          <div
            className="Button DialogButton"
            style={{ marginLeft: "30px" }}
            {...backProps}
          >
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontFamily="Poppins-Light"
              color="#ffaea6"
            >
              Back
            </Typography>
          </div>
        )}
        <div
          className="Button DialogButton"
          style={{ marginLeft: "30px" }}
          {...primaryProps}
        >
          <Typography
            mx={0.2}
            my={0.8}
            fontSize="15px"
            fontFamily="Poppins-Light"
            fontWeight="Normal"
            color="#ffaea6"
          >
            {isLastStep ? "Complete" : `Next (${index + 1}/${size})`}
          </Typography>
        </div>
      </div>
    </Box>
  );
};

const GuidedWizard: FC<GuidedWizardProps> = ({ steps }) => {
  const { start, setStart } = useStore((state) => ({
    start: state.startDemo,
    setStart: state.setStartDemo,
  }));
  const [data] = useState(steps);
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, step } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    try {
      if (
        action === "skip" ||
        (action === "next" && step?.target === ".SimulationTab")
      ) {
        const button = document.querySelector(".SimulationTab");
        if (button) {
          (button as HTMLElement)?.click();
        }
      }
    } catch (error) {
      logger.error("Error in handleJoyrideCallback", error);
    }

    if (finishedStatuses.includes(status)) {
      setStart(false);
    }
  };

  return (
    <Joyride
      tooltipComponent={CustomDialog}
      steps={data}
      scrollToFirstStep
      continuous={true}
      run={start}
      debug={true}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          arrowColor: "#ffaea6",
          backgroundColor: "rgb(247, 247, 247)",
          primaryColor: "#ffaea6",
          textColor: "#ffaea6",
          zIndex: 2000000,
        },
      }}
    />
  );
};

export default GuidedWizard;
