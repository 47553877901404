import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Themes/Themes";
import InSimulationSnackBar from "./utils/SnackBars/InSimulationSnackBar";
import SnackBar from "./utils/SnackBars/SnackBar";
import * as Sentry from "@sentry/react";
import { sentryConfig } from "./utils/SentryUtils";
import { initDataDog } from "./utils/datadog";

Sentry.init(sentryConfig);

if (
  process.env.REACT_APP_STAGE === "prod" &&
  localStorage.getItem("telemetryData") !== "false"
)
  initDataDog();

declare module "notistack" {
  interface VariantOverrides {
    inSimulationSnackBar: true;
    snackBar: {
      snackBarType?: "codeLinkingMode" | "loading" | "error" | "success";
    };
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        Components={{
          inSimulationSnackBar: InSimulationSnackBar,
          snackBar: SnackBar,
        }}
      >
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
