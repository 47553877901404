import "./SnackBars.css";
import { forwardRef, useCallback, useEffect } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import useStore from "../../Store/Store";
import { theme } from "../../Themes/Themes";
import { Tooltip } from "@mui/material";

interface InSimulationSnackBarProps extends CustomContentProps {}

const InSimulationSnackBar = forwardRef<
  HTMLDivElement,
  InSimulationSnackBarProps
>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const selectedSimulationKey = useStore(
    (state) => state.simulationsState.selectedSimulationKey
  );
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
    useStore.getState().setSelectedSimulationKey("");
  }, [id, closeSnackbar]);

  // set setIsRunningSimulationNotifId to id, used so we can close the notification when the user unselects the simulation
  useEffect(() => {
    if (!id) return;
    useStore.getState().setIsRunningSimulationNotifId(id);
  }, [id, selectedSimulationKey]);

  return (
    <SnackbarContent ref={ref} className="inSimulationSnackBar">
      <Card
        className="snackBarCard"
        style={{
          position: "relative",
          borderRadius: "30px",
          backgroundColor: "transparent",
          color: "#fffffffc",
          width: "200px",
          height: "30px",
          background: `${theme.custom.pink}`,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.1)",
        }}
      >
        <CardActions
          style={{
            position: "relative",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            style={{
              position: "relative",
              paddingLeft: "20px",
              height: "100%",
              verticalAlign: "middle",
            }}
          >
            {props.message}
          </Typography>
          <div style={{ position: "relative", left: "5px" }}>
            <Tooltip title="Exit simulation mode">
              <IconButton
                color="primary"
                size="small"
                onClick={handleDismiss}
                style={{ color: "#fffffffc" }}
              >
                <HighlightOffRoundedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

InSimulationSnackBar.displayName = "InSimulationSnackBar";

export default InSimulationSnackBar;
