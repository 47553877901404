import { useCallback, useEffect, useState } from "react";
import { getDefaultBranch } from "../../Api/ServerInterface";
import useStore from "../../Store/Store";
import { openDirectory } from "../../utils/LocalFileController/LocalFileController";
import { logger } from "../../utils/Logger";
import { removeURLParam, setURLParam } from "../../utils/URLUtils";
import "./LandingComponent.css";
import { RecentFilesDropdown } from "./RecentFilesDropdown";
import { storeOpenProjectMetadataInCodeCanvasIndexedDB } from "../../utils/CodeCanvasIndexedDB";
import { Typography } from "@mui/material";
import { theme } from "../../Themes/Themes";
import VideoTutorial from "../VideoTutorial/VideoTutorial";
import LinkIcon from "@mui/icons-material/Link";
import {
  AccountTree,
  DriveFolderUpload,
  GitHub,
  HighlightOff,
  PlayArrow,
  School,
} from "@mui/icons-material";
import { login } from "../../utils/AuthUtils";

export const loadPublicRepo = (publicURL) => {
  if (publicURL && publicURL.length > 0) {
    const state = useStore.getState();
    // remove existing URL params from address bar
    try {
      const repoName = publicURL.split("/")[4];
      const userName = publicURL.split("/")[3];
      // if branch exists in URL then extract it
      const branch = publicURL.split("/")[6];

      if (!repoName || !userName) {
        state.setErrorNotification(`Invalid URL`);
        return;
      }

      // Remove existing URL params from address bar
      ["repo", "branch", "owner"].forEach((param) => {
        removeURLParam(window, param);
      });

      state.setUsername(userName);
      state.setReposList([
        ...state.reposList,
        { name: repoName, owner: { login: userName } },
      ]);

      // Set URL Params
      setURLParam(window, "session", "unauthenticatedGithub");
      setURLParam(window, "repo", repoName);
      setURLParam(window, "owner", userName);
      state.setCurrentRepo(repoName, { owner: userName });

      if (branch) {
        setURLParam(window, "branch", branch);
        state.setCurrentBranch(branch);
      } else {
        // get default branch from github
        getDefaultBranch(repoName, userName).then((defaultBranch) => {
          if (defaultBranch) {
            let branch = defaultBranch as unknown as string;
            setURLParam(window, "branch", branch);
            state.setBranchesList([{ name: branch }]);
            state.setCurrentBranch(branch);
          }
        });
      }
      // store repoURL in indexedDB
      storeOpenProjectMetadataInCodeCanvasIndexedDB();
    } catch (e) {
      logger.error.log(e);
      state.setErrorNotification(`Failed to retrieve repo from URL`);
    }
  }
};

const LandingComponent = () => {
  const {
    isLoggedIn,
    directoryHandle,
    sessionMode,
    isUserChoosingDirectory,
    showLandingComponent,
    currentBranch,
    setShowLandingComponent,
    currentRepo,
  } = useStore((state) => ({
    isLoggedIn: state.session.isLoggedIn,
    directoryHandle: state.session.local.directory.handle,
    sessionMode: state.session.mode,
    isUserChoosingDirectory: state.isUserChoosingDirectory,
    showLandingComponent: state.showLandingComponent,
    currentBranch: state.currentBranch,
    setShowLandingComponent: state.setShowLandingComponent,
    currentRepo: state.currentRepo,
  }));

  const handleLogin = useCallback(async () => {
    if (!isLoggedIn) {
      login();
      return;
    }
  }, [isLoggedIn]);

  const loadDemo = () => {
    const currentDomain = window.location.origin;
    const demoURL = `${currentDomain}/?session=unauthenticatedGithub&repo=react-ecommerce&owner=Abdulnaser97&branch=master`;
    setShowLandingComponent(false);
    window.location.assign(demoURL);
  };

  const goNotion = () => {
    window.location.assign(
      `https://pie-crepe-38f.notion.site/Documentation-cf20cbdf134247cb93bf6366d9055076`
    );
  };

  const handleOpenFolder = async () => {
    try {
      await openDirectory(true);
      setShowLandingComponent(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      (sessionMode === "unauthenticatedGithub" && !currentRepo) ||
      (sessionMode === "local" && !directoryHandle && !isUserChoosingDirectory)
    ) {
      setShowLandingComponent(true);
    } else {
      setShowLandingComponent(false);
    }
  }, [
    directoryHandle,
    sessionMode,
    isUserChoosingDirectory,
    setShowLandingComponent,
    currentRepo,
  ]);

  const [publicURL, setPublicURL] = useState("");
  const handlePublicURLChange = (e) => {
    setPublicURL(e.target.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      loadPublicRepo(publicURL);
    }
  };

  const handleShowVideo = () => {
    useStore.getState().setVideoTutorialSection("GETTING_STARTED");
  };
  return (
    <>
      {showLandingComponent && (
        <div className="landing-wrapper">
          <div className="landing-title">
            <h1>CodeCanvas</h1>
          </div>
          <div className="landing-content">
            {(sessionMode === "unauthenticatedGithub" ||
              isLoggedIn ||
              currentBranch) && (
              <div
                className="landingComponentCloseButton"
                onClick={() => setShowLandingComponent(false)}
              >
                <HighlightOff className="landingComponentCloseButtonIcon" />
              </div>
            )}
            <svg
              className="landing-logo"
              viewBox="0 0 517 449"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#a)">
                <path
                  d="M467.347 291.533c-8.52 28.882-38.84 45.388-67.721 36.868-28.882-8.52-45.388-38.84-36.868-67.722 8.52-28.881 38.84-45.387 67.722-36.867 28.881 8.52 45.387 38.84 36.867 67.721Z"
                  fill="#FFAEA6"
                />
                <path
                  d="M451.655 286.919c-5.964 20.217-27.188 31.771-47.405 25.807-20.217-5.964-31.772-27.188-25.808-47.405 5.964-20.217 27.188-31.771 47.405-25.807 20.217 5.964 31.772 27.188 25.808 47.405Z"
                  fill="#FEF3F3"
                />
                <path
                  d="M281.642 111.715c-8.52 28.881-38.84 45.388-67.721 36.868-28.882-8.52-45.388-38.84-36.868-67.722 8.52-28.882 38.84-45.388 67.722-36.868 28.881 8.52 45.388 38.84 36.867 67.722Z"
                  fill="#FFAEA6"
                />
                <path
                  d="M265.958 107.075c-5.964 20.217-27.188 31.771-47.405 25.807-20.217-5.964-31.772-27.188-25.808-47.405 5.964-20.217 27.188-31.771 47.405-25.807 20.218 5.964 31.772 27.188 25.808 47.405Z"
                  fill="#FEF3F3"
                />
                <path
                  d="M123.024 292.582c-8.52 28.882-38.84 45.388-67.722 36.868-28.881-8.52-45.388-38.84-36.868-67.722 8.52-28.881 38.84-45.388 67.722-36.867 28.882 8.52 45.388 38.84 36.868 67.721Z"
                  fill="#FFAEA6"
                />
                <path
                  d="M107.335 287.958c-5.964 20.217-27.188 31.771-47.405 25.807-20.217-5.964-31.771-27.188-25.807-47.405 5.964-20.217 27.188-31.771 47.405-25.807 20.217 5.964 31.771 27.188 25.807 47.405Z"
                  fill="#FEF3F3"
                />
                <path
                  d="M426.353 179.989c-4.686 15.885-21.362 24.964-37.247 20.278-15.885-4.686-24.963-21.362-20.277-37.247 4.686-15.885 21.362-24.963 37.247-20.277 15.885 4.686 24.963 21.362 20.277 37.246Z"
                  fill="#FFAEA6"
                />
                <path
                  d="M415.897 176.897c-2.982 10.108-13.594 15.886-23.703 12.904-10.108-2.982-15.885-13.594-12.903-23.703 2.982-10.108 13.594-15.886 23.702-12.904 10.109 2.982 15.886 13.594 12.904 23.703Z"
                  fill="#FEF3F3"
                />
                <path
                  d="M162.834 167.6c-4.26 14.44-19.42 22.694-33.861 18.434-14.441-4.261-22.694-19.421-18.434-33.861 4.26-14.441 19.42-22.694 33.861-18.434 14.441 4.26 22.694 19.42 18.434 33.861Z"
                  fill="#FFAEA6"
                />
                <path
                  d="M152.37 164.532c-2.556 8.665-11.652 13.617-20.316 11.061-8.665-2.556-13.617-11.652-11.061-20.317 2.556-8.664 11.652-13.616 20.317-11.06 8.664 2.556 13.616 11.652 11.06 20.316Z"
                  fill="#FEF3F3"
                />
                <path
                  d="M288.352 378.017c-4.686 15.885-21.362 24.963-37.247 20.277-15.885-4.686-24.963-21.362-20.277-37.247 4.686-15.885 21.362-24.963 37.247-20.277 15.885 4.686 24.963 21.362 20.277 37.247Z"
                  fill="#FFAEA6"
                />
                <path
                  d="M277.896 374.922c-2.982 10.109-13.594 15.886-23.703 12.904-10.108-2.982-15.886-13.594-12.904-23.703 2.982-10.108 13.594-15.885 23.703-12.903 10.109 2.982 15.886 13.594 12.904 23.702Z"
                  fill="#FEF3F3"
                />
                <path
                  d="M299.23 244.799c-6.39 21.661-29.13 34.041-50.792 27.651-21.661-6.39-34.04-29.13-27.65-50.792 6.39-21.661 29.13-34.04 50.791-27.65 21.661 6.39 34.041 29.13 27.651 50.791Z"
                  fill="#FFAEA6"
                />
                <path
                  fill="#FFAEA6"
                  d="m379.432 186.473-82.856 36.776-4.026-9.071 82.856-36.776zM296.317 235.396l83.023 24.492-4.472 15.159-83.023-24.492zM244.62 134.941l16.766 64.534-15.297 3.974-16.766-64.534zM155.422 167.607l74.632 43.849-5.027 8.556-74.631-43.849 5.026-8.556ZM118.435 259.08l103.125-22.983 3.438 15.426-103.125 22.984zM255.341 342.053l1.756-70.058 9.92.249-1.756 70.058z"
                />
                <path
                  d="M283.625 239.882c-3.834 12.996-17.478 20.424-30.475 16.59-12.996-3.834-20.424-17.478-16.59-30.475 3.834-12.996 17.478-20.424 30.474-16.59 12.997 3.834 20.425 17.478 16.591 30.475Z"
                  fill="#FEF3F3"
                />
              </g>
              <defs>
                <filter
                  id="a"
                  x="12.192"
                  y="41.752"
                  width="461.398"
                  height="365.775"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1704_145"
                  />
                  <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1704_145"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            <div className="landing-content-ctas">
              <div className="landing-content-main">
                <div className="landing-url-wrapper">
                  <div className="landing-button-main-icon-wrapper">
                    <LinkIcon />
                  </div>
                  <input
                    placeholder="Enter Public GitHub Repo URL"
                    className="landing-button landing-button-main landing-url-input"
                    onChange={handlePublicURLChange}
                    onKeyUp={handleEnterKey}
                    value={publicURL}
                    type="url"
                  ></input>
                </div>
                <button
                  id="local-project-button"
                  className="landing-button landing-button-main"
                  onClick={handleOpenFolder}
                >
                  <div className="landing-button-main-icon-wrapper">
                    <DriveFolderUpload />
                  </div>
                  <Typography color={theme.custom.pink}>
                    Open Project From Local Device
                  </Typography>
                </button>
                <button
                  className="landing-button landing-button-main"
                  onClick={handleLogin}
                >
                  <div className="landing-button-main-icon-wrapper landing-button-icon-github">
                    <GitHub />
                  </div>
                  <Typography color={theme.custom.pink}>
                    Open Project From GitHub
                  </Typography>
                </button>
              </div>
              <div className="landing-content-info">
                <button
                  className="landing-button landing-button-info"
                  onClick={handleShowVideo}
                >
                  <Typography color={theme.custom.pink}>
                    <div className="landing-button-info-icon-wrapper">
                      <PlayArrow className="landing-button-playicon" />
                    </div>
                    Start Here
                  </Typography>
                </button>
                <button
                  id="demo_button"
                  className="landing-button landing-button-info"
                  onClick={loadDemo}
                >
                  <Typography color={theme.custom.pink}>
                    <div className="landing-button-info-icon-wrapper">
                      <AccountTree />
                    </div>
                    Demo
                  </Typography>
                </button>
                <button
                  className="landing-button landing-button-info"
                  onClick={goNotion}
                >
                  <div className="landing-button-info-icon-wrapper">
                    <School />
                  </div>
                  <Typography color={theme.custom.pink}>Learn More</Typography>
                </button>
              </div>
            </div>
            <Typography
              style={{ fontSize: "13px", paddingTop: "20px" }}
              color={theme.custom.darkGrey}
            >
              By using Code Canvas, you agree to our{" "}
              <a
                href="https://pie-crepe-38f.notion.site/CodeCanvas-Terms-and-Conditions-ef6b3e7a3ee54da3b363c48112062bce?pvs=4"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: "normal", color: theme.custom.pink }}
              >
                terms of service
              </a>{" "}
              and{" "}
              <a
                href="https://pie-crepe-38f.notion.site/CodeCanvas-Privacy-Policy-5466713f74724c9ab0da9a1d669ab379?pvs=4"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: "normal", color: theme.custom.pink }}
              >
                privacy policy
              </a>
            </Typography>
          </div>
          <RecentFilesDropdown />
        </div>
      )}
      <VideoTutorial />
    </>
  );
};

export default LandingComponent;
