export function setURLParam(window, param: string, value: string) {
  const url = new URL(window.location.href);
  const oldParam = url.searchParams.get(param);
  if (!oldParam || oldParam !== value) {
    url.searchParams.set(param, value);
    window.history.replaceState(null, null, url);
  }
}

export function getURLParam(window, param: string) {
  const url = new URL(window.location.href);
  return url.searchParams.get(param);
}

export function removeURLParam(window, param: string) {
  const url = new URL(window.location.href);
  const paramValue = url.searchParams.get(param);
  if (paramValue) {
    url.searchParams.delete(param);
    window.history.replaceState(null, null, url);
  }
}
