import { Step } from "react-joyride";

// Define the onboardingConfig with the type of Step[]
const onboardingConfig: Step[] = [
  {
    target: "body",
    title: "🚀 Welcome to Code Canvas!",
    content:
      "Create documentations and architecture diagrams linked to source code, and let Code Canvas help in keeping them in sync with source code to never go obsolete.",
    placement: "center",
  },
  {
    target: ".search-bar-wrapper",
    placement: "bottom",
    title: "Your Codebase at your fingerprints",
    content:
      "Use the search feature to quickly find files and connect them to your diagram.",
  },
  {
    target: ".CodeTab",
    placement: "bottom",
    title: "Context Switch Begone",
    content: "Use Code tab to select and link blocks to diagram cells",
  },
  {
    target: ".SimulationTab",
    placement: "bottom",
    title: "Marry Business with Code",
    content:
      "Create Tutorials and runtime simulations visualized on diagram. Minimize the need for source code tracing to understand the business logic.",
  },
  {
    target: ".simulationsDropdown",
    placement: "bottom",
    title: "Enter the future now!",
    content: `Get started by selecting the "Tutorial: e-commerce site architecture" simulation from the dropdown`,
  },
];

export default onboardingConfig;
