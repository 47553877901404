import useStore from "../Store/Store";

// logger.ts
type ConsoleMethod = "info" | "debug" | "warn" | "error";

class LevelLogger {
  private method: ConsoleMethod;
  private debug: boolean;

  constructor(method: ConsoleMethod, debug: boolean = false) {
    this.method = method;
    this.debug = debug;
  }
  public log(...args: any[]): void {
    if (!this.debug || useStore.getState().debugMode) {
      console[this.method](...args);
    }
  }
  public coloredLog(color: string, ...args: any[]): void {
    if (!this.debug || useStore.getState().debugMode) {
      // concatenate all args into a single string
      const msg = args.reduce((acc, curr) => {
        return acc + " " + curr;
      }, "");
      console.log(`%c${msg}`, `color: ${color}`);
    }
  }
}

export class Logger {
  private _info: LevelLogger;
  private _warn: LevelLogger;
  private _error: LevelLogger;
  private _debug: {
    info: LevelLogger;
    warn: LevelLogger;
    error: LevelLogger;
  };

  constructor() {
    this._info = new LevelLogger("info");
    this._warn = new LevelLogger("warn");
    this._error = new LevelLogger("error");

    this._debug = {
      info: new LevelLogger("info", true),
      warn: new LevelLogger("warn", true),
      error: new LevelLogger("error", true),
    };
  }

  get info() {
    return this._info.log.bind(this._info);
  }

  get warn() {
    return this._warn.log.bind(this._warn);
  }

  get error() {
    return this._error.log.bind(this._error);
  }

  get debug() {
    return {
      info: this._debug.info.log.bind(this._debug.info),
      warn: this._debug.warn.log.bind(this._debug.warn),
      error: this._debug.error.log.bind(this._debug.error),
      coloredLog: this._debug.info.coloredLog.bind(this._debug.info),
    };
  }
}

const logger = new Logger();
export { logger };
