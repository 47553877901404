import "./CheckRunView.css";

// react
import { useState, createContext } from "react";

// third-party components
import { Tooltip, Typography } from "@mui/material";
import { Resizable } from "re-resizable";

import { CheckRunTab } from "./TabViews/CheckRunTab/CheckRunTab";
import useStore from "../../Store/Store";
import { saveFileToGithub } from "../../Api/ServerInterface";
import { buildRepoDataSnapshot } from "../../utils/RepoDataAction";
import { getURLParam } from "../../utils/URLUtils";

type ICheckRunContext = {
  unreviewedCellsCount: number;
  setUnreviewedCellsCount: React.Dispatch<React.SetStateAction<number>>;
};

export const CheckRunContext = createContext<ICheckRunContext | undefined>(
  undefined
);

function onResizeStart() {
  // create a transparent overlay to prevent user from clicking on other elements
  const overlay = document.createElement("div");
  overlay.style.position = "absolute";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.width = "103.1vw";
  overlay.style.height = "103.1vh";
  overlay.style.zIndex = "1000";
  overlay.style.backgroundColor = "transparent";
  document.body.appendChild(overlay);
  // remove overlay when resizing is done
  const removeOverlay = () => {
    document.body.removeChild(overlay);
    window.removeEventListener("mouseup", removeOverlay);
  };
  window.addEventListener("mouseup", removeOverlay);
}

export const CheckRunView: React.FC = () => {
  const [isOpenCheckRunView, setIsOpenCheckRunView] = useState(true);
  const [width, setWidth] = useState("470px");
  const [height, setHeight] = useState("400px");
  const { checkRunImpactedCells } = useStore((state) => ({
    checkRunImpactedCells: state.checkRunImpactedCells,
  }));

  const [unreviewedCellsCount, setUnreviewedCellsCount] = useState(
    checkRunImpactedCells.length
  );

  const handleReturnToPRPage = () => {
    const prURL = getURLParam(window, "prURL");
    window.location.assign(prURL);
  };
  const handleReviewCompleted = async () => {
    const diagramData = useStore.getState().diagramData;
    const currentRepo = useStore.getState().currentRepo;
    const currentBranch = useStore.getState().currentBranch;
    const currentRepoMetadata = useStore.getState().currentRepoMetadata;
    const saveResponse = await saveFileToGithub(
      currentRepo,
      currentBranch,
      {
        ...diagramData,
        repoData: await buildRepoDataSnapshot(),
        simulations: useStore.getState().simulationsState.simulations,
        cellToPath: useStore.getState().cellToPath,
        lastReviewedSHA: getURLParam(window, "sha"),
      },
      currentRepoMetadata.owner,
      `${currentRepo}.CodeCanvas`
    );

    if (saveResponse.status === 200 || saveResponse.status === 201) {
      setIsOpenCheckRunView(false);
      setUnreviewedCellsCount(0);
      useStore.getState().setCheckRunImpactedCells([]);
      useStore
        .getState()
        .setSuccessNotification(
          "Review Completed, CodeCanvas Scanner check run should pass soon."
        );
      const dialogMessage =
        "Review Completed, CodeCanvas Scanner check run should pass soon.";
      useStore.getState().setDialog("DIALOG", {
        message: dialogMessage,
        button1Label: "Return to PR Page",
        button1Function: handleReturnToPRPage,
        button2Label: "Stay here",
        button2Function: () => {},
      });
    } else {
      useStore
        .getState()
        .setErrorNotification(
          "Review Failed, Please Report this issue on Discord."
        );
    }
  };

  const handleCancel = () => {
    setIsOpenCheckRunView(false);
  };

  return (
    isOpenCheckRunView && (
      <CheckRunContext.Provider
        value={{
          unreviewedCellsCount,
          setUnreviewedCellsCount,
        }}
      >
        <div className="openCheckRunView">
          <Resizable
            size={{ width, height }}
            className="CheckRunViewContainer"
            style={{
              position: "absolute",
              top: "10vh",
              left: "5vw",
              borderRadius: "15px",
            }}
            onResizeStart={onResizeStart}
            onResizeStop={(e, direction, ref, d) => {
              setWidth(width + d.width);
              setHeight(height + d.height);
            }}
            minHeight={"20vh"}
          >
            <div
              className="CheckRunViewContentContainer"
              style={{ height: "calc(100% - 30px" }}
            >
              <div className="CheckRunViewContent">
                <div className="CheckRunTitleWrapper">
                  <p className="CheckRunTitle">
                    Cell ids that might be impacted by PR
                  </p>
                </div>
                <CheckRunTab />
              </div>
              <div className="CheckRunButtonsContainer">
                <div
                  className="Button FullDialogButton CancelButton"
                  onClick={handleCancel}
                >
                  <Typography
                    mx={0.2}
                    my={0.8}
                    fontSize="12px"
                    fontWeight="Normal"
                    color="#FFAEA6"
                  >
                    Cancel
                  </Typography>
                </div>

                <Tooltip
                  title={
                    unreviewedCellsCount > 0
                      ? "Mark all cells as reviewed to enable button"
                      : "This will make the CodeCanvas Scanner check run pass on github!"
                  }
                >
                  <div className="CompleteReviewButtonWrapper">
                    <div
                      className={`Button FullDialogButton CompleteReviewButton ${
                        unreviewedCellsCount > 0 ? "disabled" : ""
                      }`}
                      onClick={handleReviewCompleted}
                    >
                      <Typography
                        mx={0.2}
                        my={0.8}
                        fontSize="12px"
                        fontWeight="Normal"
                        color="#FFAEA6"
                      >
                        Complete Review
                      </Typography>
                    </div>
                  </div>
                </Tooltip>
              </div>
            </div>
          </Resizable>
        </div>
      </CheckRunContext.Provider>
    )
  );
};
