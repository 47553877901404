import { useEffect, useRef } from "react";
import { getDiffs } from "./DeepObjectCompare";

export function useRenderCount() {
  const count = useRef(1);
  useEffect(() => {
    count.current++;
  });
  return count.current;
}

export default function useDebugInformation(componentName, props) {
  const count = useRenderCount();
  const changedProps = useRef({});
  const previousProps = useRef(props);
  const lastRenderTimestamp = useRef(Date.now());

  const propKeys = Object.keys({ ...props, ...previousProps });
  changedProps.current = propKeys.reduce((obj, key) => {
    if (props[key] === previousProps.current[key]) return obj;
    const diffs = getDiffs(previousProps.current[key], props[key]);
    if (Object.keys(diffs).length === 0) return obj;
    return {
      ...obj,
      [key]: diffs,
    };
  }, {});
  const info = {
    count,
    changedProps: changedProps.current,
    timeSinceLastRender: Date.now() - lastRenderTimestamp.current,
    lastRenderTimestamp: lastRenderTimestamp.current,
  };

  useEffect(() => {
    previousProps.current = props;
    lastRenderTimestamp.current = Date.now();
    if (info.changedProps && Object.keys(info.changedProps).length > 0) {
      if (info.changedProps.lastDrawioEventName) {
        const { lastDrawioEventName, ...rest } = info.changedProps;
        if (Object.keys(rest).length > 0) {
          console.warn("[CHANGED_PROPS]", componentName, rest);
        }
      } else {
        console.warn("[CHANGED_PROPS]", componentName, info.changedProps);
      }
      // console.log("RERENDER_COUNT", count);
    }
  });

  return info;
}
