import styled from "styled-components";

const BackToLandingPageButtonCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const BackToLandingPageButtonSVG = () => {
  return (
    <BackToLandingPageButtonCard className="BackToLandingPageButtonCard">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 55 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="BackToLandingPageButton">
          <mask
            id="mask0_1608_146"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="55"
            height="71"
          >
            <rect
              id="Rectangle 97"
              x="55"
              y="71"
              width="55"
              height="71"
              transform="rotate(-180 55 71)"
              fill="red"
            />
          </mask>
          <g mask="url(#mask0_1608_146)">
            <g id="Group 58" filter="url(#filter0_d_1608_146)">
              <g id="Mask Group">
                <g id="PlusSign">
                  <circle
                    id="Ellipse 26"
                    className="halfCircle"
                    cx="4.5"
                    cy="35.5"
                    r="32.5"
                    transform="rotate(-180 4.5 35.5)"
                    fill="#FFB6A6"
                  />
                </g>
              </g>
            </g>
            {
              // Add the icon here
            }
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_1608_146"
            x="-29"
            y="1"
            width="69"
            height="69"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1608_146"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1608_146"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </BackToLandingPageButtonCard>
  );
};

export default BackToLandingPageButtonSVG;
