import { useContext, useEffect, useState } from "react";
import DatasetLinkedOutlinedIcon from "@mui/icons-material/DatasetLinkedOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CodeIcon from "@mui/icons-material/Code";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FolderIcon from "@mui/icons-material/Folder";
import useStore from "../../../../../Store/Store";
import { toggleSingleCellVisibility } from "../../../../../Store/StoreHandlers";
import { Checkbox, Tooltip } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";

import "./CheckRunCellIdElement.css";
import { CheckRunContext } from "../../../CheckRunView";

interface ICheckRunCellIdElement {
  path: string;
  cellId: string;
}

// Custom circular unchecked icon
const CircleUnchecked = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <circle cx="10" cy="10" r="7" strokeWidth="0.7" stroke="grey" fill="none" />
  </SvgIcon>
);

// Custom circular checked icon
const CircleChecked = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <circle cx="10" cy="10" r="7" fill="currentColor" />
    <path
      d="M6.5 10l2 2 5-5"
      fill="none"
      stroke="#fff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="scale(0.7) translate(4.5,5.2)"
    />
  </SvgIcon>
);

const CircularCheckbox = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    width: "23px",
    height: "23px",
  },
  "& .MuiIconButton-root": {
    padding: "5px", // Adjust the padding to position the icon as needed
  },
});

export const CheckRunCellIdElement: React.FC<ICheckRunCellIdElement> = (
  props: ICheckRunCellIdElement
) => {
  const {
    currentPath,
    setCurrentPath,
    setSelectedTab,
    repoData,
    setOpenFolderOnDoubleClickFlag,
    setSearchResults,
    currentRepo,
    searchResults,
  } = useStore((state) => ({
    currentPath: state.currentPath,
    setCurrentPath: state.setCurrentPath,
    setSelectedTab: state.setSelectedTab,
    repoData: state.repoData,
    setOpenFolderOnDoubleClickFlag: state.setOpenFolderOnDoubleClickFlag,
    setSearchResults: state.setSearchResults,
    currentRepo: state.currentRepo,
    searchResults: state.searchResults,
  }));

  const [file, setFile] = useState({} as any);

  const { setUnreviewedCellsCount, unreviewedCellsCount } =
    useContext(CheckRunContext);

  useEffect(() => {
    setFile(repoData[props.path]);
  }, [repoData, props.path, props.cellId]);
  // if path is invalid in repoData or
  // path is for root level, dont return anything
  if (!file || file.path === currentRepo) return;
  var fileName = file.fileName;
  var displayClass = "";
  var fileIcon = null;

  // left-aligned icon selection logic
  if (file.cellId === file.path && !file.startLine) {
    fileIcon = <DashboardIcon fontSize="small" />;
  } else if (file.type !== "tree") {
    fileIcon = <CodeIcon fontSize="small" />;
  } else {
    fileName = "/" + fileName;
    fileIcon = <FolderIcon fontSize="small" />;
  }

  // openArtifact must exist to match names
  var selected =
    currentPath === file.path ||
    (!searchResults && repoData[currentPath]?.parentPath === file.path)
      ? "selectedFile"
      : "";

  function fileClickHandler(e, file) {
    // want this to register if click on anything
    // except the plus button

    // e is null on inside clickHandler so its children
    // can be clicked for same affect
    if (!e || e.target === e.currentTarget) {
      toggleSingleCellVisibility(file.cellId, true);
      setCurrentPath(file.path);
    }
  }

  function fileDoubleClickHandler(file) {
    if (file.visible) {
      if (searchResults) {
        toggleSingleCellVisibility(file.cellId, true);
      }
      setOpenFolderOnDoubleClickFlag(true);
      setCurrentPath(file.path);
      setSearchResults(null);
      if (file.type === "blob" || file.startLine) {
        setSelectedTab(1);
      }
    } else {
      if (file.type === "blob" || file.startLine) {
        setSelectedTab(1);
      }
    }
  }

  function handleCheckboxChange(e) {
    if (e.target.checked) {
      setUnreviewedCellsCount(unreviewedCellsCount - 1);
    } else {
      setUnreviewedCellsCount(unreviewedCellsCount + 1);
    }
  }

  return file ? (
    <div className={`CheckRunViewFile ${displayClass} ${selected}`}>
      <div
        onClick={(e) => fileClickHandler(e, file)}
        onDoubleClick={() => fileDoubleClickHandler(file)}
        className="CheckRunViewFileInternalWrapper"
      >
        <div
          className="CheckRunViewFileInternalContent"
          onClick={() => fileClickHandler(null, file)}
        >
          <div className="iconWrapper">{fileIcon}</div>
          <span className="CheckRunViewFileInternalText">{fileName}</span>

          <span className="CheckRunViewFileInternalTextSub">
            {file.cellName || file.cellId}
          </span>
        </div>
      </div>

      {!file?.visible && (
        <Tooltip title="Cell hidden outside simulation">
          <div className="linkedIconWrapper">
            <VisibilityOffIcon style={{ width: "100%" }} fontSize="small" />
          </div>
        </Tooltip>
      )}

      {(file?.children || file?.startline) && (
        <Tooltip title="Code is linked to this cell or child cell!">
          <div className="linkedIconWrapper">
            <DatasetLinkedOutlinedIcon style={{ width: "80%" }} />
          </div>
        </Tooltip>
      )}

      {
        <div className="iconWrapper hoverEffect">
          <CircularCheckbox
            size="small"
            icon={<CircleUnchecked />}
            checkedIcon={<CircleChecked />}
            onChange={handleCheckboxChange}
            data-testid="checkbox"
          />
        </div>
      }
    </div>
  ) : (
    <></>
  );
};
