import { Header } from "./Components/Header/Header";
import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { DrawioInterface } from "./DrawioClient/DrawioInterface";
import { SourceDoc } from "./Components/SourceDoc/SourceDoc";
import useStore from "./Store/Store";
import { SnackbarKey, useSnackbar } from "notistack";
import Dialogs from "./Components/Dialog/Dialogs";
import EditButton from "./Components/CanvasToolbar/EditButton";
import LandingComponent, {
  loadPublicRepo,
} from "./Components/LandingPage/LandingComponent";
import { getURLParam } from "./utils/URLUtils";
import { loadDiagram } from "./Api/LoadDiagram";
import { DrawioEvent } from "./DrawioClient/DrawioTypes";
import DevTools from "./utils/DevTools";
import useStateChange from "./utils/useStateChange";
import { handleKeyDown } from "./utils/keyboardShortcuts";
import { CheckRunView } from "./Components/CheckRunView/CheckRunView";
import GuidedWizard from "./Components/GuidedWizard/GuidedWizard";
import onboardingConfig from "./utils/OnboardingData";
import BackToLandingPageButton from "./Components/CanvasToolbar/BackToLandingPageButton";
import { setupTestMode, simulateTestMode } from "./utils/TestUtils";

const REACT_APP_DRAWIO_URL = process.env.REACT_APP_DRAWIO_URL;
const DEMO_REPO = "react-ecommerce";
const App: React.FC = () => {
  const {
    drawioInterface,
    loadingNotification,
    errorNotification,
    successNotification,
    landingPageLoaded,
    doLoadDiagram,
    drawioClient,
    selectedSimulationKey,
    pausePathChangeToDrawio,
    debugMode,
    setErrorNotification,
    setSuccessNotification,
    setDrawioInterface,
    setSessionMode,
    repoData,
    removeFromFuse,
    addToFuse,
    checkRunImpactedCells,
  } = useStore((state) => ({
    drawioInterface: state.drawioInterface,
    loadingNotification: state.notification.loading,
    errorNotification: state.notification.error,
    successNotification: state.notification.success,
    landingPageLoaded: state.landingPageLoaded,
    doLoadDiagram: state.doLoadDiagram,
    drawioClient: state.drawioClient,
    selectedSimulationKey: state.simulationsState.selectedSimulationKey,
    pausePathChangeToDrawio: state.pausePathChangeToDrawio,
    debugMode: state.debugMode,
    setErrorNotification: state.setErrorNotification,
    setSuccessNotification: state.setSuccessNotification,
    setDrawioInterface: state.setDrawioInterface,
    setSessionMode: state.setSessionMode,
    repoData: state.repoData,
    removeFromFuse: state.removeFromFuse,
    addToFuse: state.addToFuse,
    checkRunImpactedCells: state.checkRunImpactedCells,
  }));
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  //Dereference ToolBar function to access render
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loadingNotificationIds, setLoadingNotificationIds] = useState<
    SnackbarKey[]
  >([]);
  const historicalRepoData = useRef<Object>();
  const [codeLinkingModeNotifId, setCodeLinkingModeNotifId] =
    useState<SnackbarKey>(null);

  // Redirect to notion page if mobile
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      window.location.href =
        "https://pie-crepe-38f.notion.site/Documentation-cf20cbdf134247cb93bf6366d9055076";
    }
  }, []);

  // Warn user if not using Chrome
  useEffect(() => {
    // Check if the user's browser is not Chrome
    if (!window.navigator.userAgent.includes("Chrome")) {
      // Display a warning message
      alert(
        "This application is best viewed in Google Chrome and is supported only on desktop. Please consider switching to this browser and platform for the best experience."
      );
    }
  }, []);

  // Sets codeLinkingMode notification
  useEffect(() => {
    if (useStore.getState().isPerformingUndoRedo) return;
    if (pausePathChangeToDrawio) {
      const notificationId = enqueueSnackbar(
        `Select code from the SourceDoc to link it to the selected cell! Any
      other diagram clicks will cancel.`,
        {
          variant: "snackBar",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { vertical: "top", horizontal: "left" },
          snackBarType: "codeLinkingMode",
        }
      );
      setCodeLinkingModeNotifId(notificationId);
    } else {
      closeSnackbar(codeLinkingModeNotifId);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pausePathChangeToDrawio]);

  // Sets and clears loading notification whenever it's set in zustand store
  useEffect(() => {
    if (useStore.getState().isPerformingUndoRedo) return;
    if (loadingNotification && loadingNotification.length > 0) {
      const notificationId = enqueueSnackbar(loadingNotification, {
        variant: "snackBar",
        persist: true,
        anchorOrigin: { vertical: "top", horizontal: "left" },
        snackBarType: "loading",
      });
      setLoadingNotificationIds((notifIdArr) => [
        ...notifIdArr,
        notificationId,
      ]);
    }
    if (
      loadingNotificationIds.length > 0 &&
      (!loadingNotification || loadingNotification.length === 0)
    ) {
      const notifIdToClose = loadingNotificationIds[-1];
      closeSnackbar(notifIdToClose);
      setLoadingNotificationIds((notifIdArr) => {
        notifIdArr.pop();
        return notifIdArr;
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingNotification]);

  // Sets error and success notifications whenever they are set in zustand store
  useEffect(() => {
    if (useStore.getState().isPerformingUndoRedo) return;
    if (errorNotification && errorNotification.length > 0) {
      enqueueSnackbar(errorNotification, {
        variant: "snackBar",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "left" },
        snackBarType: "error",
      });
      setErrorNotification("");
    }
    if (successNotification && successNotification.length > 0) {
      enqueueSnackbar(successNotification, {
        variant: "snackBar",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "left" },
        snackBarType: "success",
      });
      setSuccessNotification("");
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorNotification, successNotification]);

  // Sets and clears "In Simulation" motification whenever selectedSimulationKey changes in zustand store
  useEffect(() => {
    if (selectedSimulationKey) {
      const notificationId = enqueueSnackbar(`In Simulation`, {
        variant: "inSimulationSnackBar",
        persist: true,
        preventDuplicate: true,
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
      useStore.getState().setIsRunningSimulationNotifId(notificationId);
    }
    const isRunningSimulationNotifId =
      useStore.getState().simulationsState.isRunningSimulationNotifId;
    if (isRunningSimulationNotifId && !selectedSimulationKey) {
      closeSnackbar(isRunningSimulationNotifId);
      useStore.getState().setIsRunningSimulationNotifId(null);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSimulationKey]);

  useEffect(() => {
    if (!drawioInterface) {
      setDrawioInterface(new DrawioInterface(iFrameRef));
    }
  }, [drawioInterface, setDrawioInterface]);

  useEffect(() => {
    function handleDrawioEvent(e) {
      if (e.origin !== process.env.REACT_APP_DRAWIO_URL) return;
      if (drawioClient) {
        drawioClient.handleEvent(JSON.parse(e.data as string) as DrawioEvent);
      }
    }
    window.addEventListener("message", handleDrawioEvent);
    return () => {
      window.removeEventListener("message", handleDrawioEvent);
    };
  }, [drawioClient]);

  // set session mode from url param
  useEffect(() => {
    const testMode = getURLParam(window, "testMode");
    if (testMode === "1") {
      simulateTestMode(window);
    }
    setupTestMode(window);

    const urlParamSessionMode = getURLParam(window, "session");
    // if urlParamSessionMode is github or local, set session mode
    if (
      urlParamSessionMode === "github" ||
      urlParamSessionMode === "local" ||
      urlParamSessionMode === "unauthenticatedGithub"
    ) {
      setSessionMode(urlParamSessionMode as SessionMode);
    }
    if (urlParamSessionMode === "unauthenticatedGithub") {
      const repo = getURLParam(window, "repo");
      if (repo === DEMO_REPO) useStore.getState().setStartDemo(true);
      const branch = getURLParam(window, "branch");
      const owner = getURLParam(window, "owner");
      let publicRepoURL = `https://github.com/${owner}/${repo}`;
      useStore
        .getState()
        .setReposList([{ name: repo, owner: { login: owner } }]);
      if (branch) {
        useStore.getState().setBranchesList([{ name: branch }]);
        publicRepoURL += `/tree/${branch}`;
      }
      loadPublicRepo(publicRepoURL);
    }
    useStore.getState().setURLParamParsingDone(true);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // loads the diagram only after drawio is initialized
  useEffect(() => {
    if (landingPageLoaded && doLoadDiagram) {
      loadDiagram();
    }
  }, [landingPageLoaded, doLoadDiagram]);

  // useEffect to identify changes in repoData for actions
  // may need to be extended further if we go forward with this pattern
  // without any issues in performance
  // currently only used for update and removals to fuse document db
  useEffect(() => {
    let pathsToUpdate = [];
    for (const key in repoData) {
      if (
        repoData[key] !== historicalRepoData.current[key] &&
        key !== "fileContent"
      ) {
        pathsToUpdate.push(key);
      }
    }
    if (pathsToUpdate.length >= 1) {
      removeFromFuse(pathsToUpdate);
      addToFuse(pathsToUpdate);
    }
  }, [repoData, addToFuse, removeFromFuse]);

  // useEffect to store history of repoData
  useEffect(() => {
    if (repoData) {
      historicalRepoData.current = repoData;
    }
  }, [repoData]);
  // set debug mode from url param
  useEffect(() => {
    const urlParamDebugMode = getURLParam(window, "debug");
    if (urlParamDebugMode === "true" || urlParamDebugMode === "verbose") {
      useStore.getState().setDebugMode(urlParamDebugMode);
    }
  }, []);

  // Listens to keydown events and propagates them to the iframe
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useStateChange(useStore.getState());

  useEffect(() => {
    const redirectURL = sessionStorage.getItem("redirectUrl");
    if (redirectURL) {
      // clear redirect url from local storage
      sessionStorage.removeItem("redirectUrl");
      // redirect to the url
      window.location.assign(redirectURL);
    }
  }, [])

  return (
    <div className="App">
      {debugMode && <DevTools />}
      <Header />
      <iframe
        id="drawio-iframe"
        className="DrawIOFrame"
        ref={iFrameRef}
        src={`${REACT_APP_DRAWIO_URL}/index.html`}
        width="1200"
        height="900"
        title="Child iFrame"
      ></iframe>
      <SourceDoc />
      {checkRunImpactedCells?.length > 0 && <CheckRunView />}
      <EditButton />
      <BackToLandingPageButton />
      <Dialogs />
      <LandingComponent />
      <GuidedWizard steps={onboardingConfig} />
    </div>
  );
};

export default App;
