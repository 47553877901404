import { Typography } from "@mui/material";
import { useContext } from "react";
import useStore from "../../../../Store/Store";
import { theme } from "../../../../Themes/Themes";
import {
  INITIAL_MODIFIED_SIMULATION_STATE,
  SimulationsContext,
} from "./SimulationsTab";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  discardRecordedActions,
  saveRecordedActions,
} from "../../../../Store/UndoManager";

export const SimulationsEditNameDelete: React.FC = () => {
  const {
    setSimulationNameError,
    simulationNameError,
    saveModifiedSimulation,
    currentUnsavedSimulationObject,
  } = useContext(SimulationsContext);
  const {
    simulations,
    setSimulations,
    setDialog,
    setIsEditingSimulations,
    selectedSimulationKey,
    setCurrentUnsavedSimulationObject,
    setSelectedSimulationKey,
  } = useStore((state) => ({
    simulations: state.simulationsState.simulations,
    setSimulations: state.setSimulations,
    setDialog: state.setDialog,
    setIsEditingSimulations: state.setIsEditingSimulations,
    selectedSimulationKey: state.simulationsState.selectedSimulationKey,
    setCurrentUnsavedSimulationObject: state.setCurrentUnsavedSimulationObject,
    setSelectedSimulationKey: state.setSelectedSimulationKey,
  }));

  const handleSimulationNameChange = async (e) => {
    await setCurrentUnsavedSimulationObject({
      ...currentUnsavedSimulationObject,
      name: e.target.value,
    });
    // check if name is already taken
    if (
      Object.keys(simulations).includes(e.target.value) &&
      e.target.value !== selectedSimulationKey
    ) {
      setSimulationNameError("Name Already Taken!");
    } else {
      setSimulationNameError("");
    }
  };

  const handleKeyPress = async (e) => {
    if (currentUnsavedSimulationObject.name === "") {
      setSimulationNameError("Name cannot be empty!");
      return;
    }
    if (
      e.key === "Enter" &&
      !simulationNameError &&
      currentUnsavedSimulationObject.name !== selectedSimulationKey
    ) {
      await saveModifiedSimulation();
    }
  };

  const removeSimulation = async () => {
    await setIsEditingSimulations("");
    // This creates a new object that includes all attributes from the read-only object except the to-be-removed simulation
    const newSimulationsWithCurrentOneRemoved = Object.fromEntries(
      Object.entries(simulations).filter(
        ([key]) => key !== selectedSimulationKey
      )
    );
    await setSelectedSimulationKey("");
    await setSimulations(newSimulationsWithCurrentOneRemoved);
    await setCurrentUnsavedSimulationObject(INITIAL_MODIFIED_SIMULATION_STATE);
    if (!selectedSimulationKey) {
      await discardRecordedActions("CURRENT_UNSAVED_SIMULATION_OBJECT");
    }
    await saveRecordedActions("SIMULATION_MODIFIED");
  };
  const handleRemoveSimulation = () => {
    const dialogMessage = `Are you sure you want to delete the simulation: "${selectedSimulationKey}" ?`;
    setDialog("WARNING_DIALOG", {
      message: dialogMessage,
      button1Function: removeSimulation,
    });
  };

  return (
    <div className="simulationsEditNameDelete">
      <input
        placeholder="Simulation Name"
        className="simulationNameInputTop"
        onChange={handleSimulationNameChange}
        value={currentUnsavedSimulationObject.name}
        onKeyPress={handleKeyPress}
      ></input>
      {simulationNameError && (
        <Typography
          variant="body2"
          style={{
            color: theme.custom.pinkerPink,
            marginLeft: "10px",
            width: "25%",
          }}
        >
          {simulationNameError}
        </Typography>
      )}

      <div className="circularButton" id="simulation-delete-button">
        <DeleteRoundedIcon
          style={{
            width: "70%",
            height: "70%",
            fill: theme.custom.lighterPink,
          }}
          onClick={handleRemoveSimulation}
        />
      </div>
    </div>
  );
};
