import styled from "styled-components";

const EditButtonCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  &:hover .halfCircle {
    fill: white;
  }
  &:hover .pencil {
    fill: #ffb6a6;
  }
`;

const EditButtonSVG = () => {
  return (
    <EditButtonCard className="EditButtonCard">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 55 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="EditButton">
          <mask
            id="mask0_1608_146"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="55"
            height="71"
          >
            <rect
              id="Rectangle 97"
              x="55"
              y="71"
              width="55"
              height="71"
              transform="rotate(-180 55 71)"
              fill="red"
            />
          </mask>
          <g mask="url(#mask0_1608_146)">
            <g id="Group 58" filter="url(#filter0_d_1608_146)">
              <g id="Mask Group">
                <g id="PlusSign">
                  <circle
                    id="Ellipse 26"
                    className="halfCircle"
                    cx="4.5"
                    cy="35.5"
                    r="32.5"
                    transform="rotate(-180 4.5 35.5)"
                    fill="#FFB6A6"
                  />
                </g>
              </g>
            </g>
            <path
              id="Pencil"
              className="pencil"
              d="M24 38.6671V42H20.6671L10.8374 32.1703L14.1703 28.8374L24 38.6671ZM8.25996 29.5928C7.91335 29.2462 7.91335 28.6863 8.25996 28.3397L10.3397 26.26C10.6863 25.9133 11.2462 25.9133 11.5928 26.26L13.2193 27.8864L9.8864 31.2193L8.25996 29.5928V29.5928Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_1608_146"
            x="-29"
            y="1"
            width="69"
            height="69"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1608_146"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1608_146"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </EditButtonCard>
  );
};

export default EditButtonSVG;
