import { useEffect, useState } from "react";
import { getBranches } from "../../Api/ServerInterface";
import useStore from "../../Store/Store";
import { getURLParam, setURLParam } from "../../utils/URLUtils";
import { Dropdown } from "./Dropdown/Dropdown";
import { BranchesListResponse } from "../../Api/Responses";
import { getRepoDataFromGitHub } from "../../utils/RepoDataAction";
import { storeOpenProjectMetadataInCodeCanvasIndexedDB } from "../../utils/CodeCanvasIndexedDB";

export function BranchesDropdown() {
  const [branchOptions, setBranchOptions] = useState(null);

  const {
    currentRepo,
    currentBranch,
    branchesList,
    username,
    currentRepoMetadata,
    sessionMode,
    isLoggedIn,
    setCurrentBranch,
    setBranchesList,
    setDiagramFileMetadata,
    setErrorNotification,
    setLoadingNotification,
  } = useStore((state) => ({
    currentRepo: state.currentRepo,
    currentBranch: state.currentBranch,
    branchesList: state.branchesList,
    username: state.session.user.username,
    currentRepoMetadata: state.currentRepoMetadata,
    sessionMode: state.session.mode,
    isLoggedIn: state.session.isLoggedIn,
    setCurrentBranch: state.setCurrentBranch,
    setBranchesList: state.setBranchesList,
    setDiagramFileMetadata: state.setDiagramFileMetadata,
    setErrorNotification: state.setErrorNotification,
    setLoadingNotification: state.setLoadingNotification,
  }));

  const handleBranchChange = (event: any) => {
    // Reset file handle
    setDiagramFileMetadata({
      handle: null,
      fileName: null,
      fileXML: "",
    });
    setCurrentBranch(event.target.value);
    // store repoURL in indexedDB
    storeOpenProjectMetadataInCodeCanvasIndexedDB();
  };

  const renderBranches = () => {
    if (branchesList.length === 0) {
      return <option value="">Choose repo to view branches</option>;
    }
    let branchesComponents = [];
    branchesComponents.push(
      <option key={"selectBranch"} value={""}>
        Select branch
      </option>
    );
    for (let i = 0; i < branchesList.length; i++) {
      let name = branchesList[i].name;
      branchesComponents.push(
        <option key={i} value={name}>
          {name}
        </option>
      );
    }

    return branchesComponents;
  };

  // STEP 4.B: When currentRepo is set, get branch lists
  useEffect(() => {
    if (currentRepo) {
      if (sessionMode === "github") {
        if (isLoggedIn) {
          getBranches(currentRepo, currentRepoMetadata.owner)
            .then((branchesList: BranchesListResponse) =>
              setBranchesList(branchesList.data)
            )
            .catch((e) => {
              console.error(e);
              setErrorNotification("Error retrieving branches");
              setLoadingNotification("");
            });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRepo, username, currentRepoMetadata.owner, isLoggedIn]);

  // STEP 5: When branches are are retrieved, render branches, and choose default branch if exists in URL Params
  useEffect(() => {
    setBranchOptions(renderBranches());
    const urlParamBranch = getURLParam(window, "branch");
    if (urlParamBranch) {
      setCurrentBranch(urlParamBranch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchesList]);

  // STEP 6.A: When currentBranch is set, set repoFiles
  useEffect(() => {
    if (
      currentBranch &&
      currentRepoMetadata.owner &&
      (sessionMode === "unauthenticatedGithub" ||
        (sessionMode === "github" && isLoggedIn))
    ) {
      getRepoDataFromGitHub();
    }
  }, [
    currentBranch,
    currentRepoMetadata.owner,
    sessionMode,
    isLoggedIn,
  ]);

  // STEP 6.B: When currentBranch is set, set branch URL param
  useEffect(() => {
    if (currentBranch) {
      setURLParam(window, "branch", currentBranch);
      // store repoURL in indexedDB
      storeOpenProjectMetadataInCodeCanvasIndexedDB();
    }
  }, [currentBranch, branchesList]);

  // If current branch not in branchOptions, add currentBranch to branchOptions
  useEffect(() => {
    if (currentBranch && branchOptions && !branchOptions.type) {
      const branchNames = branchOptions.map((option) => option.props.value);
      if (!branchNames.includes(currentBranch)) {
        setBranchOptions([
          ...branchOptions,
          <option key={branchOptions.length} value={currentBranch}>
            {currentBranch}
          </option>,
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch, branchOptions]);

  return (
    <Dropdown
      options={branchOptions}
      onChange={handleBranchChange}
      highlighted={currentRepo && !currentBranch && sessionMode !== "local"}
      disabled={sessionMode === "local"}
      placeholder={"Choose branch"}
      value={currentBranch}
      dataTestId="branchesDropdown"
    />
  );
}
