import { Typography } from "@mui/material";
import { TabPanel } from "../../TabPanel";
import { useState, useEffect } from "react";
import { TextEditor } from "../../../TextEditor/TextEditor";
import useStore from "../../../../Store/Store";

import "./DocsTab.css";
import { theme } from "../../../../Themes/Themes";

interface IDocsTab {
  isMaxSD: boolean;
}

export const DocsTab: React.FC<IDocsTab> = (props: IDocsTab) => {
  const {
    repoData,
    currentPath,
    currentBranch,
    currentOwner,
    currentRepo,
    setWikiContent,
    selectedTab,
  } = useStore((state) => ({
    repoData: state.repoData,
    currentPath: state.currentPath,
    currentBranch: state.currentBranch,
    currentOwner: state.currentRepoMetadata?.owner,
    currentRepo: state.currentRepo,
    setWikiContent: state.setWikiContent,
    cellToPath: state.cellToPath,
    selectedTab: state.selectedTab,
  }));

  const { isMaxSD } = props;
  const [wiki, setWiki] = useState(repoData[currentPath]?.wiki || "");
  const [isEditing, setIsEditing] = useState(false);

  const [commitHistoryLink, setCommitHistoryLink] = useState("");
  // set DocsTab data based on currentPath
  // empty data otherwise
  useEffect(() => {
    if (repoData[currentPath]) {
      setCommitHistoryLink(
        `https://github.com/${currentOwner}/${currentRepo}/commits/${currentBranch}/${currentPath}`
      );
    } else {
      setCommitHistoryLink("");
    }
  }, [repoData, currentPath]);

  const saveWikiToNode = () => {
    setWikiContent(currentPath, wiki);
  };

  const handleWikiChange = (data) => {
    setWiki(data);
  };

  const handleCancel = () => {
    setWiki(repoData[currentPath]?.wiki || "");
    setIsEditing(false);
  };

  return (
    <TabPanel value={selectedTab} index={2}>
      <div className="docsContainer">
        <div className="docsControlContainer">
          <div className="docsTitleAndCommits">
            <Typography
              variant="h5"
              fontWeight="bold"
              color={theme.custom.evenDarkerThanDeepestDarkestGrey}
            >
              {repoData[currentPath]?.cellName ||
                repoData[currentPath]?.fileName ||
                "Untitled Cell"}
            </Typography>
            <a href={commitHistoryLink} target="_blank">
              <Typography variant={"body2"} fontWeight={"Light"}>
                commit history
              </Typography>
            </a>
          </div>
          {isEditing ? (
            <div className="savelCancelButtonsWrapper">
              <div
                className="EditWikiButtonWrapper"
                style={{ width: "50px" }}
                onClick={handleCancel}
              >
                <Typography fontSize="12px" fontWeight="Normal" color="primary">
                  Cancel
                </Typography>
              </div>
              <div
                className="EditWikiButtonWrapper"
                style={{ width: "50px", marginLeft: "10px" }}
                onClick={() => {
                  saveWikiToNode();
                  setIsEditing(false);
                }}
              >
                <Typography fontSize="12px" fontWeight="Normal" color="primary">
                  Save
                </Typography>
              </div>
            </div>
          ) : (
            <div
              className="EditWikiButtonWrapper"
              style={{ top: "15px" }}
              onClick={() => setIsEditing(true)}
            >
              <Typography fontSize="12px" color="primary">
                Edit
              </Typography>
            </div>
          )}
        </div>
        <div className="text-editor-container">
          {isEditing && (
            <TextEditor
              content={repoData[currentPath]?.wiki || ""}
              disabled={!isEditing}
              handleWikiChange={handleWikiChange}
              isMaxSD={isMaxSD}
            />
          )}
          {repoData[currentPath]?.wiki?.length && !isEditing && (
            <TextEditor
              content={repoData[currentPath]?.wiki || ""}
              disabled={!isEditing}
              handleWikiChange={handleWikiChange}
              isMaxSD={isMaxSD}
            />
          )}
          {!isEditing && !repoData[currentPath]?.wiki?.length && (
            <div className="largeGreyButtonWrapper">
              <div
                className="largeGreyButton"
                onClick={(e) => setIsEditing(true)}
              >
                <Typography variant="h6" fontWeight={"thin"}>
                  + Add Wiki
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </TabPanel>
  );
};
