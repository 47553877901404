import { useEffect, useRef } from "react";
import { getDiffs } from "./DeepObjectCompare";
import useStore from "../Store/Store";
import { logger } from "./Logger";

export interface storeStateChangeEvents {
  [key: string]: StateChange[];
}

export default function useStateChange(props) {
  const changedPropsRef = useRef<storeStateChangeEvents>({});
  const previousProps = useRef(props);

  const propKeys = Object.keys({ ...props, ...previousProps }).filter(
    (key) => !isUntrackedState(key)
  );
  changedPropsRef.current = propKeys.reduce((obj, key) => {
    if (props[key] === previousProps.current[key]) return obj;
    const diffs = getDiffs(previousProps.current[key], props[key]);
    if (Object.keys(diffs).length === 0) return obj;
    return {
      ...obj,
      [key]: diffs,
    };
  }, {});
  const info = {
    changedProps: changedPropsRef.current,
  };

  useEffect(() => {
    // if (useStore.getState().recordStateChange !== "START") {
    //   return;
    // }
    previousProps.current = props;
    const changedProps: storeStateChangeEvents = info.changedProps;
    if (changedProps && Object.keys(changedProps).length > 0) {
      // loop through changed props and attach the key to the path inside the entry object, but attach it to the beginning of the path
      // e.g. {repoData: [{path: "bookingHistory"}]}
      Object.keys(changedProps).forEach((key) => {
        changedProps[key].forEach((entry) => {
          entry.path = entry.path ? `${key}/${entry.path}` : key;
        });
      });
    }

    // Once we prefixed the keys to the paths, now we can flatten the array so that storeStateChangeEvents become an array of StateChange objects
    // e.g. [{path: "repoData/bookingHistory", previous: "someValue or null", current: "some value"}]
    let changedPropsArray: StateChange[] = [];
    Object.keys(changedProps).forEach((key) => {
      changedPropsArray.push(...changedProps[key]);
    });

    // Filter out untracked states
    changedPropsArray = changedPropsArray.filter(
      (entry) => !isUntrackedState(entry.path)
    );

    // extract the simulations state changes to unsavedSimulationsStateChanges and filter them out of changedPropsArray
    if (
      changedPropsArray.length > 0 &&
      useStore
        .getState()
        .ongoingUndoRedoActionsRecordings.includes("SIMULATION_MODIFIED")
    ) {
      const isChangeAvisibilityToggleForSimulationNode = (
        entry: StateChange
      ) => {
        const path = entry.path;
        if (!path.startsWith("repoData") && !path.endsWith("visible"))
          return false;
        const cellsWithUnsavedVisibilityFlag =
          useStore.getState().simulationsState.cellsWithUnsavedVisibilityFlag;
        // path is something like "repoData/<CELL_ID>/visibile"
        // we check if CELL_ID is in cellsWithUnsavedVisibilityFlag
        const numberOfSlashes = path.split("/").length - 1;
        if (numberOfSlashes < 2) return false;
        const cellId = path.split("/")[1];
        // this assumes that we don't clear the unsavedVisibilityFlag when we save the simulation until after
        // we have saved the simulation
        return cellId in cellsWithUnsavedVisibilityFlag;
      };

      const simulationsStateChanges: StateChange[] = changedPropsArray.filter(
        (entry) =>
          entry.path.startsWith("simulationsState") ||
          entry.path.startsWith("currentUnsavedSimulationObject") ||
          isChangeAvisibilityToggleForSimulationNode(entry)
      );
      useStore
        .getState()
        .setUnsavedSimulationsStateChanges([
          ...useStore.getState().unsavedSimulationsStateChanges,
          ...simulationsStateChanges,
        ]);
      changedPropsArray = changedPropsArray.filter(
        (entry) =>
          !entry.path.startsWith("simulationsState") &&
          !entry.path.startsWith("currentUnsavedSimulationObject") &&
          !isChangeAvisibilityToggleForSimulationNode(entry)
      );
    }

    if (useStore.getState().recordStateChange !== "START") {
      if (
        changedPropsArray.length > 0 &&
        useStore.getState().debugMode === "verbose"
      ) {
        logger.debug.info(
          "CODECANVAS_STATE_CHANGE - codecanvas - IGNORED (because recordStateChange !== START) state change: ",
          changedPropsArray,
          " AND Unfiltered changedProps: ",
          changedProps
        );
      }
      return;
    }

    if (useStore.getState().landingPageLoaded && changedPropsArray.length > 0) {
      useStore.getState().postToDrawioWaitForResponse({
        action: "CODECANVAS_STATE_CHANGE",
        data: changedPropsArray,
      });
    } else {
      if (useStore.getState().debugMode === "verbose") {
        logger.debug.info(
          "CODECANVAS_STATE_CHANGE - codecanvas - IGNORED state change: ",
          changedPropsArray,
          " AND Unfiltered changedProps: ",
          changedProps
        );
      }
    }
  });
}

function isUntrackedState(key: string) {
  const untrackedStates = new Set([
    "lastDrawioEventName",
    "flags",
    "ongoingUndoRedoActionsRecordings",
    "recordStateChange",
  ]);
  // "flags/..." or "ongoingUndoRedoActionsRecordings/..." are untracked
  if (
    key.startsWith("flags") ||
    key.startsWith("ongoingUndoRedoActionsRecordings")
  )
    return true;
  return untrackedStates.has(key);
}
