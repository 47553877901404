import * as packageJson from "../../package.json";
import { DrawioLibraryData } from "./DrawioTypes";

export class Config {
  public readonly packageJson: {
    version: string;
    versionName?: string;
    name: string;
    feedbackUrl?: string;
  } = packageJson;

  public getDiagramConfig(): DiagramConfig {
    return new DiagramConfig(this);
  }
}

export class DiagramConfig {
  //#region Styles
  // #endregion

  // #region Custom Libraries

  // #endregion

  constructor(private readonly config: Config) {}
  public get customLibraries(): Promise<DrawioLibraryData[]> {
    return Promise.resolve([]);
  }
}

type DrawioCustomLibrary = (
  | {
      xml: string;
    }
  | {
      url: string;
    }
  | {
      json: string;
    }
  | {
      file: string;
    }
) & { libName: string; entryId: string };
