import "./RecentFilesDropdown.css";
import { useState, useEffect, useCallback } from "react";
import useStore from "../../Store/Store";
import { Box, FormControl } from "@mui/material";
import {
  getDirectoryHandleFromCodeCanvasIndexedDB,
  getOpenProjectsMetadataFromCodeCanvasIndexedDB,
} from "../../utils/CodeCanvasIndexedDB";
import { verifyPermission } from "../../utils/LocalFileController/LocalFileHelpers";
import { openDirectory } from "../../utils/LocalFileController/LocalFileController";

export interface IRecentlyOpenedEntry {
  github?: string;
  local?: unknown;
}

export function RecentFilesDropdown() {
  const { setErrorNotification, setRecentlyOpenedList, recentlyOpenedList } =
    useStore((state) => ({
      setErrorNotification: state.setErrorNotification,
      setRecentlyOpenedList: state.setRecentlyOpenedList,
      recentlyOpenedList: state.recentlyOpenedList,
    }));

  const [filesDropdownOptions, setFilesDropdownOptions] = useState(null);

  const selectRecentFile = async (event: any) => {
    const name = event.target.value;
    const recentlyOpenedEntry = await getDirectoryHandleFromCodeCanvasIndexedDB(
      name
    );
    if (
      event.target.options[event.target.selectedIndex].getAttribute(
        "itemType"
      ) === "github"
    ) {
      const URL = recentlyOpenedEntry?.github as string;
      // reload window with URL
      window.location.assign(URL);
    } else if (
      event.target.options[event.target.selectedIndex].getAttribute(
        "itemType"
      ) === "local"
    ) {
      const directoryHandle =
        recentlyOpenedEntry?.local as FileSystemDirectoryHandle;
      verifyPermission(directoryHandle, true)
        .then(async (permission) => {
          if (permission === false) {
            console.error("No permission to access local directory");
            setErrorNotification("No permission to access local directory");
            return;
          }

          // set directory handle for local file
          const setLocalDirectoryMetaData =
            useStore.getState().setLocalDirectoryMetaData;
          setLocalDirectoryMetaData({
            handle: directoryHandle,
          });
          await openDirectory(false, directoryHandle);
        })
        .catch((err) => console.error(err));
    }
  };

  const renderRecentFiles = useCallback(() => {
    if (!recentlyOpenedList || recentlyOpenedList.length === 0) {
      return;
    }

    let recentFilesComponents = [];
    recentFilesComponents.push(
      <option key={0} value={""}>
        {`Recently Opened: ...`}
      </option>
    );

    let keyCtr = 0;
    for (let i = 0; i < recentlyOpenedList.length; i++) {
      let repoName = recentlyOpenedList[i][0];
      let repoMetaData: IRecentlyOpenedEntry = recentlyOpenedList[i][1];
      // eslint-disable-next-line no-loop-func
      Object.keys(repoMetaData).forEach((key) => {
        if (key === "github") {
          keyCtr += 1;
          recentFilesComponents.push(
            <option key={keyCtr} value={repoName} itemType={"github"}>
              {repoName} (Github)
            </option>
          );
        }

        if (key === "local") {
          keyCtr += 1;
          recentFilesComponents.push(
            <option key={keyCtr} value={repoName} itemType={"local"}>
              {repoName} (Local)
            </option>
          );
        }
      });
    }

    return recentFilesComponents;
  }, [recentlyOpenedList]);

  // If local mode, look up indexedDB of CodeCanvas to retrieve the directory handle
  useEffect(() => {
    getOpenProjectsMetadataFromCodeCanvasIndexedDB().then(
      async (indexedDBFiles) => {
        if (indexedDBFiles === null || indexedDBFiles.length === 0) {
          return;
        }
        setRecentlyOpenedList(indexedDBFiles);
      }
    );
  }, []);

  // When recentlyOpenedList is retrieved from indexedDB, render recent files
  useEffect(() => {
    setFilesDropdownOptions(renderRecentFiles());
  }, [recentlyOpenedList, renderRecentFiles]);

  return (
    <div
      className="RecentFiles"
      style={{
        display: `${
          !recentlyOpenedList || recentlyOpenedList.length === 0 ? "none" : ""
        }`,
      }}
    >
      <Box className="RecentFilesDropDownWrapper">
        <FormControl fullWidth variant="outlined">
          <select className={`RecentFilesDropdown`} onChange={selectRecentFile}>
            <option value="" disabled>
              Choose recent file
            </option>
            {filesDropdownOptions}
          </select>
        </FormControl>
      </Box>
    </div>
  );
}
