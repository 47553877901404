import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    custom: ThemeOptions["custom"];
    typography: TypographyOptions;
  }
  interface TypographyOptions {
    fontFamily: string;
    fontWeightThin: number;
    fontWeightLight: number;
    fontWeightRegular: number;
    fontWeightMedium: number;
    fontWeightBold: number;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      lighterPink: string;
      pinkerPink: string;
      pink: string;
      translucentPink: string;
      grey: string;
      lightGrey: string;
      lightestGrey: string;
      darkGrey: string;
      darkerGrey: string;
      darkestGrey: string;
      deepestDarkestGrey: string;
      evenDarkerThanDeepestDarkestGrey: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#FFAEA6",
    },
    secondary: {
      main: "#ffadad",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    button: {
      textTransform: "none",
    },
  },

  custom: {
    lighterPink: "#ffb6af",
    pink: "#FFAEA7",
    pinkerPink: "#ff8b8b",
    translucentPink: "#ff434373",
    grey: "#d4d4d4",
    darkGrey: "#8f8f8f",
    darkerGrey: "#5d5d5d",
    darkestGrey: "#767676",
    deepestDarkestGrey: "#4d4d4d",
    evenDarkerThanDeepestDarkestGrey: "#272727",
    lightGrey: "#adadad",
    lightestGrey: "#faf8f8",
  },
});
