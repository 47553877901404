import { Dialog as MUIDialog, DialogContent, Typography } from "@mui/material";
import useStore from "../../Store/Store";
import { theme } from "../../Themes/Themes";
import MessageDisplay from "../../utils/MessageDisplay";
import "./Dialog.css";
import { discardRecordedActions } from "../../Store/UndoManager";

export default function WarningDialog() {
  const { dialog, setDialog, isPerformingUndoRedo } = useStore((state) => ({
    dialog: state.dialog,
    setDialog: state.setDialog,
    currentRepo: state.currentRepo,
    setCurrentPath: state.setCurrentPath,
    isPerformingUndoRedo: state.isPerformingUndoRedo,
  }));

  async function handleProceed() {
    try {
      await dialog.data.button1Function();
    } catch (error) {
      console.error(error);
    }
    closeDialog();
  }

  async function closeDialog() {
    if (dialog.data?.button2Function) {
      await dialog.data.button2Function();
    }
    setDialog("");
  }

  function handleCancel() {
    closeDialog();
    discardRecordedActions("*");
  }

  return (
    !isPerformingUndoRedo && (
      <MUIDialog
        open={dialog.type === "WARNING_DIALOG" && dialog.data !== null}
        sx={{
          "& .MuiPaper-root": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            borderRadius: "30px",
            width: "50vw",
          },
        }}
        onClose={closeDialog}
      >
        <DialogContent className="DialogContent">
          <br />
          <Typography
            textAlign={"center"}
            mx={3}
            my={0.8}
            fontSize="18px"
            fontWeight="Light"
            color={theme.custom.darkerGrey}
            sx={{ paddingBottom: "10px" }}
          >
            {typeof dialog?.data?.message === "string" ? (
              <MessageDisplay message={dialog?.data?.message} />
            ) : (
              dialog?.data?.message
            )}
          </Typography>

          <div
            className="DialogButtonGroupHorizontal"
            style={{ paddingBottom: "10px" }}
          >
            <div
              className="Button DialogButton"
              style={{ marginRight: "30px" }}
              onClick={handleCancel}
            >
              <Typography
                mx={0.2}
                my={0.8}
                fontSize="15px"
                fontWeight="Normal"
                color="#FFAEA6"
              >
                Cancel
              </Typography>
            </div>
            {dialog?.data?.button1Function && (
              <div
                className="Button DialogButton"
                style={{ marginLeft: "30px" }}
                onClick={handleProceed}
              >
                <Typography
                  mx={0.2}
                  my={0.8}
                  fontSize="15px"
                  fontWeight="Normal"
                  color="#FFAEA6"
                >
                  Proceed
                </Typography>
              </div>
            )}
          </div>
        </DialogContent>
      </MUIDialog>
    )
  );
}
