import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import useStore from "../../../../Store/Store";
import { IApplyCellsStyle, SimulationsContext } from "./SimulationsTab";
import { useContext, useEffect, useMemo, useState } from "react";
import { theme } from "../../../../Themes/Themes";
import { logger } from "../../../../utils/Logger";
import { Tooltip } from "@mui/material";

interface ISimulationNodeProp {
  simStep: SimStep;
  index: number;
  className?: string;
}

const SimulationsNode: React.FC<ISimulationNodeProp> = (
  props: ISimulationNodeProp
) => {
  const { simStep, className } = props;
  const simStepId = simStep.simStepId;
  const simStepLabel = simStep.simStepLabel;
  const diagramNodeId = simStep.diagramNodeId;
  const nodeOrEdgeClass = simStep.isEdge
    ? "simulationEdge"
    : "simulationNode linked";
  const { currentStep, setCurrentStep, isRunning, setIsRunning } =
    useContext(SimulationsContext);
  const {
    currentUnsavedSimulationObject,
    setCurrentUnsavedSimulationObject,
    isEditingSimulations,
    selectedSimulationKey,
    cellsSetToCorrespondingVisiblityState,
    repoData,
    cellToPath,
    cellsWithUnsavedVisibilityFlag,
  } = useStore((state) => ({
    currentUnsavedSimulationObject: state.currentUnsavedSimulationObject,
    setCurrentUnsavedSimulationObject: state.setCurrentUnsavedSimulationObject,
    isEditingSimulations: state.simulationsState.isEditingSimulations,
    selectedSimulationKey: state.simulationsState.selectedSimulationKey,
    cellsSetToCorrespondingVisiblityState:
      state.simulationsState.cellsSetToCorrespondingVisiblityState,
    repoData: state.repoData,
    cellToPath: state.cellToPath,
    cellsWithUnsavedVisibilityFlag:
      state.simulationsState.cellsWithUnsavedVisibilityFlag,
  }));

  const [displayLabel, setDisplayLabel] = useState(
    simStepLabel ? simStepLabel : simStepId
  );
  const [newStepLabel, setNewStepLabel] = useState(displayLabel);

  const path = useMemo(
    () => cellToPath[diagramNodeId] || diagramNodeId,
    [cellToPath, diagramNodeId]
  );

  const [visibleOutsideSimulation, setVisibleOutsideSimulation] = useState(
    !!repoData[path]?.visible
  );

  const [cellExistsOnDiagram, setCellExistsOnDiagram] = useState(
    path in repoData
  );

  let selectedClassName = simStep.isEdge ? "selectedEdge" : "selectedNode";
  let selected = currentStep === simStepId ? selectedClassName : "";

  // Sets simstep display label
  useEffect(() => {
    const { simStepLabel, simStepId } = simStep;
    const displayLabel = simStepLabel || simStepId;
    // We don't want to display the simStepId if it's an edge
    if (
      simStep.isEdge &&
      (!simStepLabel || simStepLabel === simStepId) &&
      !isEditingSimulations
    ) {
      setDisplayLabel("");
    } else {
      setDisplayLabel(displayLabel);
    }
  }, [simStepLabel, simStep, isEditingSimulations, currentStep]);

  const simStepMouseDownHandler = () => {
    // hacky way to force useEffect in SimulationsControls to run, if we have only one simstep and we click on it
    // currentStep will be the same, so useEffect won't run
    if (currentUnsavedSimulationObject.simSteps.length === 1) {
      useStore.getState().setCurrentUnsavedSimulationObject({
        ...currentUnsavedSimulationObject,
        simSteps: [{ ...currentUnsavedSimulationObject.simSteps[0] }],
      });
    }

    setCurrentStep(simStepId);
    if (!isRunning) {
      setIsRunning(true);
    }
  };

  async function handleRemoveSimulationStep() {
    // handle remove simulationDiagramElement
    let simulationNodesAndEdges = {
      ...currentUnsavedSimulationObject.simulationNodesAndEdges,
    };

    if (simStep.diagramNodeId) {
      let simulationDiagramElement = {
        ...simulationNodesAndEdges[simStep.diagramNodeId],
      };
      // check if it has any other simSteps
      if (simulationDiagramElement.simStepIds.length === 1) {
        // if not, remove it
        delete simulationNodesAndEdges[simStep.diagramNodeId];
      } else {
        // if it does, remove this simStepId from the simStepIds array

        let simStepIds = simulationDiagramElement.simStepIds.filter(
          (id) => id !== simStepId
        );
        simulationDiagramElement.simStepIds = simStepIds;
        simulationNodesAndEdges[simStep.diagramNodeId] =
          simulationDiagramElement;
      }
    }

    let simSteps = currentUnsavedSimulationObject.simSteps;
    let newSimSteps = simSteps.filter((step) => step.simStepId !== simStepId);
    await setCurrentUnsavedSimulationObject({
      ...currentUnsavedSimulationObject,
      simSteps: newSimSteps,
      simulationNodesAndEdges: simulationNodesAndEdges,
    });
    // set current step to first step if current step is deleted
    if (currentStep === simStepId && newSimSteps?.length > 0) {
      setCurrentStep(newSimSteps[0].simStepId);
    }
    logger.debug.info(
      "simulationNodesAndEdges",
      currentUnsavedSimulationObject.simulationNodesAndEdges
    );
  }

  function handleElementVisibleChange(visible: boolean) {
    // retrive the set of cells that have the have different visibility flag from the repoData
    const cellsWithUnsavedVisibilityFlag = {
      ...useStore.getState().simulationsState.cellsWithUnsavedVisibilityFlag,
    };

    // if new visiblity flag is same as repoData, remove it from cellsWithUnsavedVisibilityFlag
    if (visible === repoData[path]?.visible) {
      if (path in cellsWithUnsavedVisibilityFlag) {
        delete cellsWithUnsavedVisibilityFlag[path];
      }
    } else {
      // if new visibility flag is different from repoData, add it to cellsWithUnsavedVisibilityFlag
      cellsWithUnsavedVisibilityFlag[path] = visible;
    }
    useStore
      .getState()
      .setCellsWithUnsavedVisibilityFlag(cellsWithUnsavedVisibilityFlag);
  }

  const subscribe = useStore.subscribe;

  useEffect(() => {
    let prevVisibility;
    // sets the state of the visible flag based on the state from the store
    const reflectVisibilityStateChange = async (state) => {
      const element = state.repoData[path];
      if (
        state.repoData &&
        Object.keys(state.repoData).length > 0 &&
        !element
      ) {
        setCellExistsOnDiagram(false);
        // set diagramNodeId to null in the simStep
        // create a deep copy of each step in simSteps
        let simSteps = currentUnsavedSimulationObject.simSteps;

        let newSimSteps = simSteps.map((step) => {
          const stepDeepCopy = { ...step };
          if (stepDeepCopy.simStepId === simStepId) {
            stepDeepCopy.diagramNodeId = null;
          }
          return stepDeepCopy;
        });
        // remove cell from simulationNodesAndEdges
        let simulationNodesAndEdges = {
          ...currentUnsavedSimulationObject.simulationNodesAndEdges,
        };
        if (diagramNodeId && diagramNodeId in simulationNodesAndEdges) {
          delete simulationNodesAndEdges[diagramNodeId];
          await setCurrentUnsavedSimulationObject({
            ...currentUnsavedSimulationObject,
            simSteps: newSimSteps,
            simulationNodesAndEdges: simulationNodesAndEdges,
          });
        }
        return;
      }

      if (path in cellsWithUnsavedVisibilityFlag) {
        // if the cell has an unsaved visibility flag, use that
        setVisibleOutsideSimulation(cellsWithUnsavedVisibilityFlag[path]);
      } else if (element && element.visible !== prevVisibility) {
        // else use the visibility flag from the repoData
        setVisibleOutsideSimulation(element?.visible);
        prevVisibility = element?.visible;
      }
    };

    const unsubscribe = subscribe(reflectVisibilityStateChange);

    // Cleanup function: unsubscribe when the component unmounts
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    diagramNodeId,
    subscribe,
    selectedSimulationKey,
    path,
    cellsWithUnsavedVisibilityFlag,
  ]);

  // handles diagram node styling
  useEffect(() => {
    if (!cellsSetToCorrespondingVisiblityState) {
      return;
    }
    // When the component mounts, set the temporary styles on the diagram node
    const actionData: IApplyCellsStyle = {
      cellIds: [diagramNodeId],
      // mxGraph styles
      style: {
        stroke: theme.custom.translucentPink,
        strokeWidth: 14,
        visible: true,
        flowAnimation: 1,
      },
      options: {
        temporaryStyleChange: true,
      },
    };
    useStore
      .getState()
      .postToDrawioWaitForResponse({
        action: "CHANGE_CELLS_STYLE",
        data: actionData,
      })
      .catch((error) => {
        if (!cellExistsOnDiagram) {
          return;
        }
        logger.error(error);
        logger.error(
          `Could not apply temporary styles to node ${diagramNodeId}`
        );
        useStore
          .getState()
          .setErrorNotification(
            `Could not apply temporary styles to node ${diagramNodeId}`
          );
      });
    // when simstep component unmounts (either when deleted, or when we close simulation), remove temporary styles
    return () => {
      useStore
        .getState()
        .postToDrawioWaitForResponse({
          action: "CHANGE_CELLS_STYLE",
          data: {
            cellIds: [diagramNodeId],
            options: {
              removeTemporaryStyles: true,
            },
          },
        })
        .catch((error) => {
          if (!cellExistsOnDiagram) {
            return;
          }
          logger.error(error);
          logger.error(
            `Could not remove temporary styles to node ${diagramNodeId}`
          );
          useStore
            .getState()
            .setErrorNotification(
              `Could not remove temporary styles to node ${diagramNodeId}`
            );
        });
    };
  }, [
    diagramNodeId,
    cellsSetToCorrespondingVisiblityState,
    isEditingSimulations,
    selectedSimulationKey,
    cellExistsOnDiagram,
  ]);

  const setStepLabelInCurrentSimulationObject = async () => {
    if (newStepLabel !== simStepLabel) {
      await setCurrentUnsavedSimulationObject({
        ...currentUnsavedSimulationObject,
        simSteps: currentUnsavedSimulationObject.simSteps.map((step, index) => {
          if (step.simStepId === simStepId) {
            return { ...step, simStepLabel: newStepLabel };
          }
          return step;
        }),
      });
    }
  };
  // Triggers once every 300 ms at max and only if newStepLabel is different from simStepLabel
  // allows, the save button to be active or disabled based on whether there are any unsaved changes
  useEffect(
    () => {
      const timer = setTimeout(setStepLabelInCurrentSimulationObject, 300);
      return () => clearTimeout(timer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newStepLabel]
  );

  return (
    <div className={`${selected} ${className} ${nodeOrEdgeClass}`}>
      <div
        className="simulationNodeInternalWrapper"
        onMouseDown={simStepMouseDownHandler}
      >
        <div className="simulationNodeInternalContent">
          {simStep.isEdge && (
            <SouthRoundedIcon
              style={{
                height: "30px",
                width: "50px",
                color: theme.custom.darkGrey,
              }}
              fontSize="small"
            />
          )}

          {isEditingSimulations ? (
            <input
              // remove outline and border
              style={{
                outline: "none",
                border: "none",
                color:
                  simStep.isEdge && selected ? theme.custom.pink : "inherit",
              }}
              className="simStepLabelInput simulationNodeInternalText"
              value={newStepLabel}
              placeholder={"Add label"}
              onBlur={setStepLabelInCurrentSimulationObject}
              onChange={(e) => {
                setNewStepLabel(e.target.value);
              }}
            />
          ) : (
            <p className="simulationNodeInternalText">{displayLabel}</p>
          )}
        </div>
      </div>

      {!cellExistsOnDiagram && (
        <Tooltip title="Cell not found in diagram">
          <div
            className="iconWrapper"
            style={{ cursor: "default", color: theme.custom.pink }}
          >
            <ErrorRoundedIcon style={{ width: "100%" }} fontSize="small" />
          </div>
        </Tooltip>
      )}
      {isEditingSimulations && (
        <>
          {cellExistsOnDiagram && (
            <div className="iconWrapper">
              {!visibleOutsideSimulation && (
                <Tooltip title="Cell hidden outside simulation">
                  <VisibilityOffIcon
                    style={{ width: "100%" }}
                    fontSize="small"
                    onClick={() => handleElementVisibleChange(true)}
                  />
                </Tooltip>
              )}
              {visibleOutsideSimulation && (
                <Tooltip title="Cell visible outside simulation. Click to hide">
                  <VisibilityIcon
                    style={{ width: "100%" }}
                    fontSize="small"
                    onClick={() => handleElementVisibleChange(false)}
                  />
                </Tooltip>
              )}
            </div>
          )}

          <div className="iconWrapper">
            <DeleteRoundedIcon
              style={{ width: "100%" }}
              fontSize="small"
              onClick={handleRemoveSimulationStep}
            />
          </div>
          <div
            className="iconWrapper"
            style={{ marginRight: "20px", cursor: "grab" }}
            onMouseDown={simStepMouseDownHandler}
          >
            <DragHandleRoundedIcon fontSize="small" />
          </div>
        </>
      )}
    </div>
  );
};

export default SimulationsNode;
