import useStore from "../../Store/Store";
import { buildRepoDataSnapshot } from "../RepoDataAction";

/**
 * Writes the contents to disk.
 *
 * @param {FileSystemFileHandle} fileHandle File handle to write to.
 * @param {string} contents Contents to write.
 */
export async function writeFile(fileHandle, contents) {
  // Support for Chrome 82 and earlier.
  if (fileHandle.createWriter) {
    // Create a writer (request permission if necessary).
    const writer = await fileHandle.createWriter();
    // Write the full length of the contents
    await writer.write(0, contents);
    // Close the file and write the contents to disk
    await writer.close();
    return;
  }
  // For Chrome 83 and later.
  // Create a FileSystemWritableFileStream to write to.
  const writable = await fileHandle.createWritable();
  // Write the contents of the file to the stream.
  await writable.write(contents);
  // Close the file and write the contents to disk.
  await writable.close();
}

/**
 * Updates the UI with the current file name.
 * @param {FileHandle|string} fileHandle Filename to display in header.
 */
export const setDiagramMetadataFromLocalFile = (fileHandle) => {
  const setDiagramFileMetadata = useStore.getState().setDiagramFileMetadata;

  if (fileHandle && fileHandle.name) {
    setDiagramFileMetadata({
      handle: fileHandle,
      fileName: fileHandle.name,
    });
  } else {
    setDiagramFileMetadata({ handle: null, fileName: fileHandle });
  }
};

/**
 * Reads the raw text from a file.
 *
 * @param {File} file
 * @return {!Promise<string>} A promise that resolves to the parsed string.
 */
export function readFileHelper(file) {
  // If the new .text() reader is available, use it.
  if (file.text) {
    return file.text();
  }
  // Otherwise use the traditional file reading technique.
  return _readFileLegacy(file);
}

/**
 * Reads the raw text from a file.
 *
 * @private
 * @param {File} file
 * @return {Promise<string>} A promise that resolves to the parsed string.
 */
function _readFileLegacy(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      const text = (e as any).srcElement.result;
      resolve(text);
    });
    reader.readAsText(file);
  });
}

/**
 * Verify the user has granted permission to read or write to the file, if
 * permission hasn't been granted, request permission.
 *
 * @param {FileSystemFileHandle | FileSystemDirectoryHandle} handle file or folder handle to check.
 * @param {boolean} withWrite True if write permission should be checked.
 * @return {boolean} True if the user has granted read/write permission.
 */
export async function verifyPermission(handle, withWrite) {
  const opts = {} as any;
  if (withWrite) {
    opts.writable = true;
    // For Chrome 86 and later...
    opts.mode = "readwrite";
  }
  // Check if we already have permission, if so, return true.
  if ((await handle.queryPermission(opts)) === "granted") {
    return true;
  }
  // Request permission to the file, if the user grants permission, return true.
  if ((await handle.requestPermission(opts)) === "granted") {
    return true;
  }
  // The user did nt grant permission, return false.
  return false;
}

/**
 * Create a handle to a new (text) file on the local file system.
 *
 * @return {!Promise<FileSystemFileHandle>} Handle to the new file.
 */
export function getNewFileHandle() {
  // For Chrome 86 and later...
  if ("showSaveFilePicker" in window) {
    const currentRepo = useStore.getState().currentRepo;
    const suggestedName = currentRepo ? `${currentRepo}` : `Diagram1`;
    const opts = {
      suggestedName: suggestedName,
      types: [
        {
          description: "Text file",
          accept: { "text/plain": [".CodeCanvas"] },
        },
      ],
    };
    return (window as any).showSaveFilePicker(opts);
  }
  // For Chrome 85 and earlier...
  const opts = {
    type: "save-file",
    accepts: [
      {
        description: "Text file",
        extensions: ["CodeCanvas"],
        mimeTypes: ["text/plain"],
      },
    ],
  };
  return (window as any).chooseFileSystemEntries(opts);
}

/**
 * Open a handle to an existing file on the local file system.
 *
 * @return {!Promise<FileSystemFileHandle>} Handle to the existing file.
 */
export function getFileHandle() {
  // For Chrome 86 and later...
  if ("showOpenFilePicker" in window) {
    return (window as any).showOpenFilePicker().then((handles) => handles[0]);
  }
  // For Chrome 85 and earlier...
  return (window as any).chooseFileSystemEntries();
}

/**
 * Returns a handle to an existing file on the local file system.
 * @param {path} path to the file from given directory
 * @param {string} parentDirectoryHandle handle to an ancestor directory.
 * @return {!Promise<FileSystemFileHandle>} Handle to the existing file.
 */
export async function getFileHandleFromParentHandle(
  path,
  parentDirectoryHandle,
  createFile = true
) {
  if (!path || path.length === 0) {
    throw new Error("Invalid path");
  }
  const pathArray = path.split("/");
  let parentHandle = parentDirectoryHandle;
  for (let i = 0; i < pathArray.length; i++) {
    if (pathArray[i] === "") {
      continue;
    }
    if (i === pathArray.length - 1) {
      return await parentHandle.getFileHandle(pathArray[i], {
        create: createFile,
      });
    }
    // get directory handle
    parentHandle = await parentHandle.getDirectoryHandle(pathArray[i], {
      create: true,
    });
  }
}

/**
 * Open a handle to an existing directory on the local file system.
 *
 * @return {!Promise<FileSystemDirectoryHandle>} Handle to the existing directory.
 */
export function getDirectoryHandle() {
  // For Chrome 86 and later...
  if ("showDirectoryPicker" in window) {
    return (window as any).showDirectoryPicker();
  }
  // For Chrome 85 and earlier...
  return (window as any).chooseFileSystemEntries();
}

/**
 * assembles diagram data to export to a file
 *
 * @return {object} diagram data
 */
export async function getDiagramData() {
  const currentRepo = useStore.getState().currentRepo;
  const currentBranch = useStore.getState().currentBranch;
  const currentRepoMetadata = useStore.getState().currentRepoMetadata;
  const simulations = useStore.getState().simulationsState.simulations;
  const cellToPath = useStore.getState().cellToPath;
  let diagramData = structuredClone(useStore.getState().diagramData);
  if (!diagramData.fileURL || diagramData.fileURL === "") {
    diagramData.fileURL = "local";
  }
  if (!currentRepo || !currentBranch || !currentRepoMetadata?.owner) {
    useStore.getState().setDialog("CHOOSE_REPO_AND_BRANCH");
    return;
  }
  diagramData["repo"] = currentRepo;
  diagramData["branch"] = currentBranch;
  diagramData["owner"] = currentRepoMetadata?.owner;
  diagramData["simulations"] = simulations;
  diagramData["cellToPath"] = cellToPath;
  diagramData["repoData"] = await buildRepoDataSnapshot();

  return diagramData;
}

/**
 * retrieves the directory metadata from the .git folder
 * @param {FileSystemDirectoryHandle} rootDirectoryHandle root directory handle (directory containing .git folder)
 * @return {object} directory metadata
 */
export async function getDirectoryMetadata(rootDirectoryHandle) {
  // extract directory name from rootDirectoryHandle
  const dirName = rootDirectoryHandle.name;

  let gitDir = null;
  try {
    gitDir = await rootDirectoryHandle.getDirectoryHandle(".git", {
      create: false,
    });
  } catch (error) {
    // Not a git repo
    return {
      repo: dirName,
      branch: "unknown",
      username: "unknown",
    };
  }

  // extract directory branch from .git/HEAD
  const headFile = await gitDir.getFileHandle("HEAD", { create: false });
  const headFileContents = await headFile.getFile();
  const headFileContentsText = await headFileContents.text();
  const branch = headFileContentsText.split("/").pop().trim();

  // extract github username from .git/config
  const config = await gitDir.getFileHandle("config", { create: false });
  const configContents = await config.getFile();
  const configContentsText = await configContents.text();

  const originUrlLine = configContentsText
    .split("\n")
    .find((line) => line.trim().startsWith("url ="));

  let username;
  if (originUrlLine.includes("https://github.com")) {
    username = originUrlLine.split("github.com/")[1].split("/")[0];
  } else if (originUrlLine.includes("git@github.com")) {
    username = originUrlLine.split("git@github.com:")[1].split("/")[0];
  }

  return {
    repo: dirName,
    branch: branch,
    username: username,
  };
}

/**
 * recursively retrieves all files in a directory
 * @param {FileSystemDirectoryHandle} directoryHandle directory handle
 * @return {object} directory metadata
 */
export async function getDirectoryContent(
  directoryHandle = null,
  repoData = {},
  path: string = ""
) {
  try {
    if (!directoryHandle) {
      throw new Error("No directory handle");
    }
    // If a directoryHandle is provided, verify we have permission to read/write it
    if ((await verifyPermission(directoryHandle, true)) === false) {
      console.error(
        `User did not grant permission to '${directoryHandle.name}'`
      );
      return;
    }

    // This only filters out node dependencies, not other languages (e.g. python)
    // So if performance became an issue when opening local folders for other languages,
    // we need to include python, java, etc. node_modules equivalent to this set
    const ignoreList = new Set([".git", "node_modules", "dist", "build"]);

    const directoryPromises = [];
    const contents = [];
    for await (const entry of directoryHandle.values()) {
      if (ignoreList.has(entry.name)) {
        continue;
      }
      if (entry.kind === "file") {
        const filePath = path + entry.name;
        repoData[filePath] = {
          type: "blob",
          path: filePath,
          fileName: entry.name,
        };
        contents.push(filePath);
      } else if (entry.kind === "directory") {
        const dirPath = path + entry.name;
        contents.push(dirPath);
        directoryPromises.push(
          getDirectoryContent(entry, repoData, path + entry.name + "/")
        );
      }
    }

    await Promise.all(directoryPromises);
    let currentDirectoryPath = path.slice(0, -1);

    // Hanle error case where currentDirectoryPath is empty string
    if (currentDirectoryPath === "") {
      currentDirectoryPath = directoryHandle.name;
    }
    repoData[currentDirectoryPath] = {
      type: "tree",
      path: currentDirectoryPath,
      fileName: directoryHandle.name,
      contents: contents,
    };
  } catch (error) {
    throw error;
  }
}
