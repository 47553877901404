import { Dialog as MUIDialog, DialogContent, Typography } from "@mui/material";
import { theme } from "../../Themes/Themes";
import useStore from "../../Store/Store";
import "./Dialog.css";

export default function ChooseRepoBranchDialog() {
  const { dialog, setDialog } = useStore((state) => ({
    dialog: state.dialog,
    setDialog: state.setDialog,
  }));

  async function closeDialog() {
    if (dialog.data?.button2Function) {
      await dialog.data.button2Function();
    }
    setDialog("");
  }
  return (
    <MUIDialog
      open={dialog.type === "CHOOSE_REPO_AND_BRANCH"}
      sx={{
        "& .MuiPaper-root": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          borderRadius: "30px",
          width: "50vw",
        },
      }}
      onClose={closeDialog}
    >
      <DialogContent className="DialogContent">
        <br />
        <Typography
          textAlign={"center"}
          mx={1}
          my={0.8}
          fontSize="18px"
          fontWeight="Light"
          color={theme.custom.darkerGrey}
        >
          Please select a repository and branch and try again
        </Typography>
        <div className="DialogButtonGroupHorizontal">
          <div className="Button DialogButton" onClick={closeDialog}>
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontWeight="Normal"
              color="#FFAEA6"
            >
              Ok
            </Typography>
          </div>
        </div>
      </DialogContent>
    </MUIDialog>
  );
}
