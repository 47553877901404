export function extractTextFromHTML(html: string): string {
  if (!html) {
    return "";
  }

  // Replace <br> tags with spaces
  html = html.replace(/<br\s*\/?>/gi, " ");

  // Remove all other HTML tags and trim whitespace
  const regex = /<[^>]*>/g;
  return html.replace(regex, "").trim();
}
