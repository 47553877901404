import useStore from "../../../../Store/Store";
import { Pre, Line, LineNo, LineContent } from "./styles";
import { memo } from "react";
import { unlinkCell } from "../../../../Store/StoreHandlers";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Tooltip } from "@mui/material";
import { startRecordingActions } from "../../../../Store/UndoManager";

export interface UnVirtualizedListProps {
  tokens: any[];
  className: string;
  style: React.CSSProperties;
  virtualizedListRef: React.RefObject<HTMLPreElement>;
  selectedText: string;
  userStillInSameFile: React.MutableRefObject<boolean>;
  setSelectedText: (text: string) => void;
  setPopUpLoc: (loc: { x: number; y: number }) => void;
  getLineProps: (line: any) => any;
  getTokenProps: (token: any) => any;
  codeHighlightHandler: (i: number) => React.CSSProperties;
  lineClickHandler: (i: number) => void;
  setSelectionStartLine: (i: number) => void;
  startLineEndLineIdTagger: (i: number) => string;
}

const UnVirtualizedList: React.FC<UnVirtualizedListProps> = ({
  tokens,
  className,
  style,
  virtualizedListRef,
  selectedText,
  userStillInSameFile,
  setSelectedText,
  setPopUpLoc,
  getLineProps,
  getTokenProps,
  codeHighlightHandler,
  lineClickHandler,
  setSelectionStartLine,
  startLineEndLineIdTagger,
}) => {
  const { repoData, currentPath, setDialog } = useStore((state) => ({
    repoData: state.repoData,
    currentPath: state.currentPath,
    setDialog: state.setDialog,
  }));

  const handleTextSelection = (e) => {
    try {
      userStillInSameFile.current = true;
      if (window.getSelection) {
        if (!selectedText) {
          var boundingClient = window.getSelection().toString();
          if (boundingClient.length > 0) {
            setSelectedText(boundingClient);
            setPopUpLoc({ x: e.clientX, y: e.clientY });
          }
        } else {
          setSelectedText(null);
        }
      } else {
        setSelectedText(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLinkHandler = async (e) => {
    await startRecordingActions("UNLINK", {
      supressWarning: true,
    });
    await e.stopPropagation();
    let currentRepoData = repoData[currentPath];
    if (currentRepoData?.wiki) {
      let message = `There is a wiki in this cell-code connection. Please select to save the wiki in the linked file or in the cell!`;
      setDialog("UNLINK_DIALOG", {
        message: message,
        data: {
          cellIdentifier: currentRepoData?.cellId,
        },
      });
    } else {
      userStillInSameFile.current = true;
      await unlinkCell(currentRepoData.path, null, true);
    }
  };

  return (
    <Pre
      ref={virtualizedListRef}
      className={className + " un-virtualized-list"}
      style={{ ...style, backgroundColor: "" }}
      onMouseUp={(e) => handleTextSelection(e)}
    >
      {tokens.map((line, i) => {
        if (i === parseInt(repoData[currentPath]?.startLine)) {
        }
        return (
          <Line
            key={i}
            {...getLineProps({
              line,
              key: i,
            })}
          >
            <div
              key={i}
              style={codeHighlightHandler(i)}
              onClick={() => lineClickHandler(i)}
              onMouseDown={() => {
                setSelectionStartLine(i + 1);
              }}
              data-line-number={i}
            >
              <LineNo>
                {
                  <span>
                    {parseInt(repoData[currentPath]?.startLine) - 1 !== i ? (
                      i + 1
                    ) : (
                      <Tooltip title="Click to delete link to cell">
                        <div
                          className="unlinkIconWrapper"
                          onClick={async (e) => {
                            deleteLinkHandler(e);
                          }}
                        >
                          <HighlightOffIcon style={{ height: "15px" }} />
                        </div>
                      </Tooltip>
                    )}
                  </span>
                }
              </LineNo>

              <LineContent>
                {line.map((token, key) => {
                  return (
                    <span
                      id={startLineEndLineIdTagger(i)}
                      key={key}
                      {...getTokenProps({
                        token,
                        key,
                      })}
                    />
                  );
                })}
              </LineContent>
            </div>
          </Line>
        );
      })}
    </Pre>
  );
};

export default memo(UnVirtualizedList);
