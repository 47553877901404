import styled from "styled-components";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import { useContext, useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { theme } from "../../../../Themes/Themes";
import {
  INITIAL_MODIFIED_SIMULATION_STATE,
  SimulationsContext,
} from "./SimulationsTab";
import useStore from "../../../../Store/Store";
import { logger } from "../../../../utils/Logger";
import { toggleCellsVisibility } from "./SimulationUtils";
import {
  discardRecordedActions,
  startRecordingActions,
} from "../../../../Store/UndoManager";
import { areObjectsEqual } from "../../../../utils/DeepObjectCompare";

const CircleDiv = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fdfdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  box-shadow: #ffaea6 0px 0px 0px 1px;
  &:hover {
    background-color: ${theme.custom.lighterPink};
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 3px 1px;
  }
  &: hover path {
    fill: white;
  }
`;

export const SimulationsControls: React.FC = () => {
  const {
    isRunning,
    setIsRunning,
    currentStep,
    setCurrentStep,
    setIsMaxSD,
    isNewSimulation,
    setIsNewSimulation,
    saveModifiedSimulation,
    currentUnsavedSimulationObject,
    setWiki,
    simulationDescription,
    setSimulationDescription,
  } = useContext(SimulationsContext);

  const {
    simulations,
    repoData,
    currentPath,
    setSimulations,
    isEditingSimulations,
    setIsEditingSimulations,
    selectedSimulationKey,
    setSelectedSimulationKey,
    setCurrentUnsavedSimulationObject,
    cellsWithUnsavedVisibilityFlag,
  } = useStore((state) => ({
    simulations: state.simulationsState.simulations,
    repoData: state.repoData,
    currentPath: state.currentPath,
    setSimulations: state.setSimulations,
    isEditingSimulations: state.simulationsState.isEditingSimulations,
    setIsEditingSimulations: state.setIsEditingSimulations,
    selectedSimulationKey: state.simulationsState.selectedSimulationKey,
    setSelectedSimulationKey: state.setSelectedSimulationKey,
    setCurrentUnsavedSimulationObject: state.setCurrentUnsavedSimulationObject,
    cellsWithUnsavedVisibilityFlag:
      state.simulationsState.cellsWithUnsavedVisibilityFlag,
  }));
  const [isModified, setIsModified] = useState(false);
  const [disableBackward, setDisableBackward] = useState(true);
  const [disableForward, setDisableForward] = useState(false);
  const handleForward = () => {
    let flags = useStore.getState().flags;
    if (flags.isChangingPath || flags.isHandlingSelectionChange) return;
    if (currentStep) {
      // get the index of the current step from the simSteps array
      const currentIdx = currentUnsavedSimulationObject.simSteps.findIndex(
        (step) => step.simStepId === currentStep
      );
      if (currentIdx === currentUnsavedSimulationObject.simSteps.length - 1) {
        return;
      }
      let newIdx = currentIdx + 1;
      // if statement below is used to skip arrows without labels
      // if simStep at the new index satisfies the condition below, and the newIndex+1 is not out of bounds, then increment the newIdx further by 1
      let targetStep = currentUnsavedSimulationObject.simSteps[newIdx];
      if (
        (!targetStep.simStepLabel ||
          (targetStep.simStepLabel === targetStep.simStepId &&
            targetStep.isEdge)) &&
        !isEditingSimulations &&
        newIdx + 1 < currentUnsavedSimulationObject.simSteps.length
      ) {
        newIdx++;
      }
      setCurrentStep(currentUnsavedSimulationObject.simSteps[newIdx].simStepId);

      const simStepsList = document.getElementsByClassName("SimStepsList")[0];
      // them scroll the one that's found into view
      simStepsList.scrollTo({
        top: 36 * newIdx - simStepsList.clientHeight / 2,
        behavior: "smooth",
      });
    }
  };
  const handleBackward = () => {
    let flags = useStore.getState().flags;
    if (flags.isChangingPath || flags.isHandlingSelectionChange) return;
    if (currentStep) {
      const currentIdx = currentUnsavedSimulationObject.simSteps.findIndex(
        (step) => step.simStepId === currentStep
      );
      if (currentIdx === 0) {
        return;
      }
      let newIdx = currentIdx - 1;
      // if statement below is used to skip arrows without labels
      // if simStep at the new index satisfies the condition below, and the newIndex-1 is not out of bounds, then decrement the newIdx further by 1
      let targetStep = currentUnsavedSimulationObject.simSteps[newIdx];
      if (
        (!targetStep.simStepLabel ||
          (targetStep.simStepLabel === targetStep.simStepId &&
            targetStep.isEdge)) &&
        !isEditingSimulations &&
        newIdx - 1 > 0
      ) {
        newIdx--;
      }
      setCurrentStep(currentUnsavedSimulationObject.simSteps[newIdx].simStepId);

      const simStepsList = document.getElementsByClassName("SimStepsList")[0];
      // them scroll the one that's found into view
      simStepsList.scrollTo({
        top: 36 * newIdx - simStepsList.clientHeight / 2,
        behavior: "smooth",
      });
    }
  };

  const handleStop = () => {
    setIsRunning(false);
    setDisableForward(true);
    setDisableBackward(true);
    setCurrentStep("");
  };
  const handleStart = () => {
    setIsRunning(true);
    setDisableForward(false);
    setDisableBackward(false);
  };

  const handleEdit = async () => {
    await startRecordingActions("SIMULATION_MODIFIED");
    setIsRunning(true);
    setDisableForward(false);
    setDisableBackward(false);
    await setCurrentUnsavedSimulationObject(
      simulations[selectedSimulationKey] || INITIAL_MODIFIED_SIMULATION_STATE
    );
    if (!selectedSimulationKey) {
      await discardRecordedActions("CURRENT_UNSAVED_SIMULATION_OBJECT");
    }
    await setIsEditingSimulations("SimSteps");
  };

  const handleCancel = async () => {
    if (isEditingSimulations === "Description") {
      setSimulationDescription(
        currentUnsavedSimulationObject.description || ""
      );
    }
    await setIsEditingSimulations("");
    if (isNewSimulation) {
      // This creates a new object that includes all attributes from the read-only object except the new simulation
      const filteredObject = Object.fromEntries(
        Object.entries(simulations).filter(
          ([key]) => key !== selectedSimulationKey
        )
      );
      await setSelectedSimulationKey("");
      setWiki(repoData[currentPath]?.wiki || "");
      await setSimulations(filteredObject);
      setIsNewSimulation(false);
    }

    await setCurrentUnsavedSimulationObject(
      simulations[selectedSimulationKey] || INITIAL_MODIFIED_SIMULATION_STATE
    );
    if (!selectedSimulationKey) {
      await discardRecordedActions("CURRENT_UNSAVED_SIMULATION_OBJECT");
    }
    useStore.getState().setCellsWithUnsavedVisibilityFlag({});
    setSimulationDescription("");
    await discardRecordedActions("SIMULATION_MODIFIED");
  };

  const handleSave = async () => {
    if (!isModified) return;
    if (isEditingSimulations === "Description") {
      await setCurrentUnsavedSimulationObject({
        ...currentUnsavedSimulationObject,
        description: simulationDescription,
      });
      await setSimulations({
        ...simulations,
        [currentUnsavedSimulationObject.name]: {
          ...currentUnsavedSimulationObject,
          description: simulationDescription,
        },
      });
    }
    await setIsEditingSimulations("");
    if (isNewSimulation) {
      setIsNewSimulation(false);
    }
    // loop through the set of cellsWithUnsavedVisibilityFlag and set the visible flag in each of the cells in repoData
    Object.entries(cellsWithUnsavedVisibilityFlag).forEach(
      ([cellId, visible]) => {
        if (cellId in repoData) {
          useStore
            .getState()
            .setRepoObjectAttribute(cellId, "visible", visible);
        }
      }
    );

    await saveModifiedSimulation();
    useStore.getState().setCellsWithUnsavedVisibilityFlag({});
    setSimulationDescription("");
  };

  // Maximize SD when is editing mode or running mode
  useEffect(() => {
    if (isEditingSimulations || isRunning) {
      setIsMaxSD(true);
    }
  }, [setIsMaxSD, isEditingSimulations, isRunning]);

  // Disables forward or backward buttons when the current step is the first or last step
  useEffect(() => {
    if (!selectedSimulationKey || !isRunning) {
      setDisableBackward(true);
      setDisableForward(true);
      return;
    }
    if (currentStep) {
      const currentIdx = currentUnsavedSimulationObject.simSteps.findIndex(
        (step) => step.simStepId === currentStep
      );
      if (currentIdx === 0) {
        setDisableBackward(true);
      } else {
        // disable backward button for 300ms to prevent infinite loop
        setDisableBackward(true);
        setTimeout(() => {
          setDisableBackward(false);
        }, 300);
      }
      if (currentIdx === currentUnsavedSimulationObject.simSteps.length - 1) {
        setDisableForward(true);
      } else {
        // disable backward button for 300ms to prevent infinite loop
        setDisableForward(true);
        setTimeout(() => {
          setDisableForward(false);
        }, 300);
      }
    }
  }, [
    currentStep,
    currentUnsavedSimulationObject.simSteps,
    selectedSimulationKey,
    isRunning,
  ]);

  // Posts to drawio to select cell based on current step
  useEffect(() => {
    const setFlag = useStore.getState().setFlag;
    const cellId = currentUnsavedSimulationObject.simSteps.find(
      (step) => step.simStepId === currentStep
    )?.diagramNodeId;
    let flags = useStore.getState().flags;
    if (
      flags.isChangingPath ||
      flags.isHandlingSelectionChange ||
      flags.isAddingNewSimStep
    ) {
      return;
    }
    setFlag("isChangingPath", true);
    useStore
      .getState()
      .postToDrawioWaitForResponse({
        action: "CURRENT_PATH_CHANGE",
        data: {
          selectId: cellId,
          clearSelection: !isRunning && !isEditingSimulations,
          isLinked: false,
          isDrawioEventOrigin: false,
        },
      })
      .finally(() => {
        setFlag("isChangingPath", false);
        setFlag("isHandlingSelectionChange", false);
      });
  }, [
    currentStep,
    currentUnsavedSimulationObject.simSteps,
    isRunning,
    isEditingSimulations,
  ]);

  // toggles all cells visiblity when in edit mode
  useEffect(() => {
    if (isEditingSimulations === "SimSteps") {
      toggleCellsVisibility(true).catch((err) => logger.error(err));
    } else {
      // get all cells that are not in the current simulation
      const ignoreList = useStore
        .getState()
        .currentUnsavedSimulationObject.simSteps.map(
          (step) => step.diagramNodeId
        );
      toggleCellsVisibility(false, ignoreList).catch((err) =>
        logger.error(err)
      );
    }
  }, [isEditingSimulations]);

  // disable simulation save button when currentUnsavedSimulationObject is equal to the saved simulation object or when
  // there are no changes made to the simulation
  useEffect(() => {
    if (
      !selectedSimulationKey ||
      !currentUnsavedSimulationObject ||
      !simulations[selectedSimulationKey]
    ) {
      return;
    }

    const isModified =
      Object.keys(cellsWithUnsavedVisibilityFlag).length !== 0 ||
      isNewSimulation ||
      !areObjectsEqual(
        currentUnsavedSimulationObject,
        simulations[selectedSimulationKey]
      );

    setIsModified(isModified);
  }, [
    currentUnsavedSimulationObject,
    selectedSimulationKey,
    simulations,
    isNewSimulation,
    cellsWithUnsavedVisibilityFlag,
    setIsModified,
  ]);

  return (
    <div
      className="controls"
      style={isEditingSimulations ? { marginBottom: "20px" } : {}}
    >
      <div className="simulationsPlayerBar">
        <CircleDiv
          className={`simulationControlButton backward ${
            disableBackward && "disabledSimButtom"
          }`}
          style={{ cursor: disableBackward ? "not-allowed" : "pointer" }}
        >
          <NavigateBeforeRoundedIcon
            style={{
              width: "100%",
              height: "100%",
              fill: theme.custom.lighterPink,
            }}
            fontSize="large"
            onClick={handleBackward}
          />
        </CircleDiv>

        <CircleDiv
          className={`simulationControlButton stop ${
            (isEditingSimulations || !selectedSimulationKey) &&
            "disabledSimButtom"
          }`}
          style={{
            display: isEditingSimulations ? "none" : "flex",
          }}
        >
          {isRunning ? (
            <StopRoundedIcon
              data-testid="stopButton"
              style={{
                width: "70%",
                height: "70%",
                fill: theme.custom.lighterPink,
              }}
              onClick={handleStop}
            />
          ) : (
            <ArrowRightRoundedIcon
              style={{
                width: "140%",
                height: "140%",
                fill: theme.custom.lighterPink,
              }}
              fontSize="large"
              onClick={handleStart}
            />
          )}
        </CircleDiv>
        <CircleDiv
          className={`simulationControlButton forward ${
            disableForward && "disabledSimButtom"
          }`}
        >
          <NavigateNextRoundedIcon
            style={{
              width: "100%",
              height: "100%",
              fill: theme.custom.lighterPink,
            }}
            onClick={handleForward}
          />
        </CircleDiv>
      </div>
      {isEditingSimulations && (
        <ToggleButtonGroup
          color="primary"
          value={isEditingSimulations}
          exclusive
          style={{ height: "30px", borderRadius: "30px" }}
          // change border radio to 30px
          sx={{
            "& .MuiToggleButton-root": {
              borderRadius: "30px",
              border: `1px solid ${theme.custom.lighterPink}`,
              color: theme.custom.lighterPink,
            },
          }}
          onChange={async (event, newValue) => {
            return await setIsEditingSimulations(newValue);
          }}
          aria-label="Platform"
        >
          <ToggleButton value="SimSteps">SimSteps</ToggleButton>
          <ToggleButton value="Description">Description</ToggleButton>
        </ToggleButtonGroup>
      )}

      <div className="rightButtonsWrapper">
        {isEditingSimulations ? (
          <>
            <div
              className="EditWikiButtonWrapper"
              onClick={handleCancel}
              style={{ width: "50px" }}
            >
              <Typography fontSize="12px" color="primary">
                Cancel{" "}
              </Typography>
            </div>
            <div
              id="simulationSaveButton"
              className={
                "EditWikiButtonWrapper" + (!isModified ? " disabled" : "")
              }
              onClick={handleSave}
              style={{ marginLeft: "10px", width: "50px" }}
            >
              <Typography fontSize="12px">Save</Typography>
            </div>
          </>
        ) : (
          selectedSimulationKey && (
            <div
              className="EditWikiButtonWrapper"
              onClick={handleEdit}
              style={{ width: "50px" }}
            >
              <Typography fontSize="12px" color="primary">
                Edit
              </Typography>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SimulationsControls;
