import { Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useStore from "../../../../Store/Store";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import TextEditor from "../../../TextEditor/TextEditor";
import { CodeTab } from "../CodeTab/CodeTab";
import { BottomHandle, TopHandle } from "./Handles";
import { SimSteps } from "./SimSteps";
import { SimulationsContext } from "./SimulationsTab";

export const SimulationsEditPanel: React.FC = () => {
  let { currentUnsavedSimulationObject, isMaxSD, currentStep, isRunning } =
    useContext(SimulationsContext);

  const { isEditingSimulations, setCurrentUnsavedSimulationObject } = useStore(
    (state) => ({
      isEditingSimulations: state.simulationsState.isEditingSimulations,
      setCurrentUnsavedSimulationObject:
        state.setCurrentUnsavedSimulationObject,
    })
  );

  const [simStepDescription, setSimStepDescription] = useState("");

  // useRef instead
  const isDragging = useRef(false);
  const usingWikiAsSimStepDescription = useRef(false);

  const handleSimStepDescriptionChange = async (data) => {
    if (!isEditingSimulations) {
      return;
    }
    setSimStepDescription(data);
    await setCurrentUnsavedSimulationObject({
      ...currentUnsavedSimulationObject,
      simSteps: currentUnsavedSimulationObject.simSteps.map((step, index) => {
        if (step.simStepId === currentStep) {
          return { ...step, simStepDescription: data };
        }
        return step;
      }),
    });
  };

  // Sets the simStepDescription state to the current step's description
  useEffect(() => {
    const currentStepObject = currentUnsavedSimulationObject.simSteps.find(
      (step) => step.simStepId === currentStep
    );
    const currentStepDescription = currentStepObject?.simStepDescription;
    if (currentStepDescription) {
      setSimStepDescription(currentStepDescription);
      usingWikiAsSimStepDescription.current = false;
      return;
    }

    // check if there is a wiki stored in the path of the current step's diagram node
    const currentStepDiagramNodeId = currentStepObject?.diagramNodeId;
    // return if we are editing simulations cause
    if (currentStepDiagramNodeId && !isEditingSimulations) {
      const cellToPath = useStore.getState().cellToPath;
      const path =
        cellToPath[currentStepDiagramNodeId] || currentStepDiagramNodeId;
      const repoDataObj = useStore.getState().repoData[path];
      if (repoDataObj?.wiki) {
        setSimStepDescription(repoDataObj.wiki);
        usingWikiAsSimStepDescription.current = true;
        return;
      }
    }
    setSimStepDescription("");
    usingWikiAsSimStepDescription.current = false;
  }, [
    currentStep,
    currentUnsavedSimulationObject.simSteps,
    isEditingSimulations,
  ]);

  return (
    <>
      <div
        className="SimStepsContainer"
        style={{
          display: isEditingSimulations !== "Description" ? "flex" : "none",
        }}
      >
        {currentUnsavedSimulationObject.simSteps &&
        currentUnsavedSimulationObject.simSteps.length > 0 ? (
          <SimSteps isDragging={isDragging} />
        ) : (
          <div
            className="bigGreyButton"
            style={{
              height: "80%",
              cursor: "default",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" fontWeight={"thin"}>
              Right click on a diagram cell and select 'Add As Simulation Step'
            </Typography>
          </div>
        )}
      </div>

      <TopHandle isDragging={isDragging} isMaxSD={isMaxSD} />
      <div
        className="wikiAndCodeContainer"
        style={{
          display:
            isEditingSimulations === "SimSteps" || isRunning ? "flex" : "none",
        }}
      >
        {currentUnsavedSimulationObject && (
          <div className="wikiContainer">
            <Typography
              variant="subtitle1"
              color="primary"
              className="boxTitle"
              style={{ zIndex: 999 }}
            >
              Step Description
            </Typography>

            {usingWikiAsSimStepDescription.current && (
              <div className="wikiIndicator">
                <Tooltip title="Using wiki as step description, you can add custom SimStep description in edit mode">
                  <ArticleRoundedIcon fontSize="medium"></ArticleRoundedIcon>
                </Tooltip>
              </div>
            )}

            <div className="wikiDiv" style={{ height: "calc(100%)" }}>
              <div className="textEditorContainer">
                {currentStep && (
                  <TextEditor
                    content={simStepDescription}
                    disabled={!isEditingSimulations}
                    handleWikiChange={handleSimStepDescriptionChange}
                    isMaxSD={isMaxSD}
                    customStyle={{ position: "absolute", top: "0px" }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <BottomHandle simStepDescription={simStepDescription} />
        <div className="simulationCodeContainer">
          <Typography variant="subtitle1" color="primary" className="boxTitle">
            Source Code
          </Typography>
          <div className="codeDiv">
            <CodeTab
              isMaxSD={isMaxSD}
              className="simulationCodeInnerContainer"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SimulationsEditPanel;
