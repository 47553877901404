import { loadReferenceDiagramFile } from "../../Api/LoadDiagram";
import { sendShadowDataToDrawio } from "../../Components/Header/StatusBar/StatusBarUtils";
import { IRecentlyOpenedEntry } from "../../Components/LandingPage/RecentFilesDropdown";
import useStore from "../../Store/Store";
import {
  getOpenProjectsMetadataFromCodeCanvasIndexedDB,
  storeOpenProjectMetadataInCodeCanvasIndexedDB,
  updateRepoDataInCodeCanvasIndexedDB,
} from "../CodeCanvasIndexedDB";
import { mergeSavedDataAndRepoData } from "../RepoDataAction";
import { setURLParam } from "../URLUtils";
import {
  writeFile,
  setDiagramMetadataFromLocalFile,
  readFileHelper,
  getNewFileHandle,
  verifyPermission,
  getFileHandle,
  getDirectoryHandle,
  getDiagramData,
  getDirectoryMetadata,
  getDirectoryContent,
  getFileHandleFromParentHandle,
} from "./LocalFileHelpers";

/**
 * Saves a new file to disk.
 */
export const saveFileAs = async () => {
  const local = useStore.getState().session.local;
  const diagramData = await getDiagramData();
  const diagramDataString = JSON.stringify(diagramData, null, "\t");

  if (!local.hasFSAccess) {
    saveAsLegacy(local.diagramFile.fileName, diagramDataString);
    return;
  }
  let fileHandle;
  try {
    fileHandle = await getNewFileHandle();
  } catch (ex) {
    if (ex.name === "AbortError") {
      return;
    }
    const msg = "An error occured trying to open the file.";
    console.error(msg, ex);
    useStore.getState().setErrorNotification(msg);
    return;
  }
  try {
    await writeFile(fileHandle, diagramDataString);
    setDiagramMetadataFromLocalFile(fileHandle);
    if (useStore.getState().isNewDiagramFileCreated) {
      useStore.getState().setIsNewDiagramFileCreated(false);
    }
    loadReferenceDiagramFile();
    useStore.getState().setSuccessNotification("Successfully saved diagram!");
  } catch (ex) {
    const msg = "Unable to save file.";
    console.error(msg, ex);
    useStore.getState().setErrorNotification(msg);
    return;
  }
};

/**
 * Saves a file to disk.
 */
export const saveFile = async () => {
  const local = useStore.getState().session.local;
  try {
    if (!local.diagramFile.handle) {
      return await saveFileAs();
    }
    const diagramData = await getDiagramData();
    const diagramDataString = JSON.stringify(diagramData, null, "\t");
    await writeFile(local.diagramFile.handle, diagramDataString);
    if (useStore.getState().isNewDiagramFileCreated) {
      useStore.getState().setIsNewDiagramFileCreated(false);
    }
    loadReferenceDiagramFile();
    useStore.getState().setSuccessNotification("Successfully saved diagram!");
  } catch (ex) {
    const msg = "Unable to save file";
    console.error(msg, ex);
    useStore.getState().setErrorNotification(msg);
  }
};

/**
 * Saves a file by creating a downloadable instance, and clicking on the
 * download link.
 *
 * @param {string} filename Filename to save the file as.
 * @param {string} contents Contents of the file to save.
 */
const saveAsLegacy = (filename, contents) => {
  const aDownloadFile = document.getElementById("aDownloadFile");
  filename = filename || "Diagram1.CodeCanvas";
  const opts = { type: "text/plain" };
  const file = new File([contents], "", opts);
  (aDownloadFile as any).href = window.URL.createObjectURL(file);
  aDownloadFile.setAttribute("download", filename);
  aDownloadFile.click();
};

/**
 * Uses the <input type="file"> to open a new file
 *
 * @return {!Promise<File>} File selected by the user.
 */
const getFileLegacy = () => {
  const filePicker = document.getElementById("filePicker");
  return new Promise((resolve, reject) => {
    filePicker.onchange = (e) => {
      const file = (filePicker as any).files[0];
      if (file) {
        resolve(file);
        return;
      }
      reject(new Error("AbortError"));
    };
    filePicker.click();
  });
};

/**
 * Opens a file for reading.
 *
 * @param {string} path Path to the file.
 * @param {FileSystemFileHandle} fileHandle File handle to read from.
 */
export const openFile = async (
  path = "",
  fileHandle = null,
  isSetLoadingNotification = true
) => {
  try {
    const local = useStore.getState().session.local;

    // If the File System Access API is not supported, use the legacy file apis.
    if (!local.hasFSAccess) {
      const file = await getFileLegacy();
      if (file) {
        const fileContent = await readFileHelper(file);
        return { fileContent: fileContent, fileHandle: null };
      }
      throw new Error("Unable to open file.");
    }

    // If a fileHandle is provided, verify we have permission to read/write it,
    // otherwise, show the file open prompt and allow the user to select the file.
    if (fileHandle) {
      if ((await verifyPermission(fileHandle, true)) === false) {
        throw new Error(
          `User did not grant permission to '${fileHandle.fileName}'`
        );
      }
    } else if (path && local.directory.handle) {
      fileHandle = await getFileHandleFromParentHandle(
        path,
        local.directory.handle,
        false
      );
    } else {
      fileHandle = await getFileHandle();
    }

    if (!fileHandle) {
      throw new Error("Unable to open file.");
    }

    if (fileHandle.name.endsWith(".CodeCanvas") && isSetLoadingNotification) {
      useStore
        .getState()
        .setLoadingNotification(
          `Retrieving ${fileHandle.name} diagram content...`
        );
    }

    const file = await fileHandle.getFile();
    const fileContent = await readFileHelper(file);

    return { fileContent: fileContent, fileHandle: fileHandle };
  } catch (error) {
    if (error.name === "AbortError") {
      return { fileContent: null, fileHandle: null };
    }
    const msg = error.message
      ? error.message
      : "An error occured trying to open the file.";
    console.error(msg, error);
    useStore.getState().setErrorNotification(msg);
  }
};

/**
 * Checks if file exists in local repository
 *
 */
export const doesFileExist = async (path = "") => {
  try {
    const local = useStore.getState().session.local;

    // If the File System Access API is not supported, use the legacy file apis.
    if (!local.hasFSAccess) {
      const file = await getFileLegacy();
      if (file) {
        const fileContent = await readFileHelper(file);
        return fileContent ? true : false;
      }
      throw new Error("Unable to open file.");
    }
    let fileHandle = null;
    if (path && local.directory.handle) {
      fileHandle = await getFileHandleFromParentHandle(
        path,
        local.directory.handle,
        false
      );
      return fileHandle ? true : false;
    }
    return false;
  } catch (error) {
    return false;
  }
};

/**
 * Opens a directory.
 * retuns nested array representing repo tree. e.g. [file1, file2, [subdir1/file1,subdir1/file2]].
 */
export const openDirectory = async (
  throwErrorOnUserAbort = false,
  directoryHandle: FileSystemDirectoryHandle = null
) => {
  try {
    useStore.getState().setIsUserChoosingDirectory(true);
    // Prompt user to select a directory.
    if (!directoryHandle) {
      directoryHandle = await getDirectoryHandle();

      const setLocalDirectoryMetaData =
        useStore.getState().setLocalDirectoryMetaData;
      setLocalDirectoryMetaData({ handle: directoryHandle });

      useStore.getState().setIsUserChoosingDirectory(false);
    }

    // Get metadata from .git directory
    let directoryMetaData = await getDirectoryMetadata(directoryHandle);
    useStore.getState().setIsRetrievingRepoData(true);
    const repoData = {};
    // Get the directory contents.
    await getDirectoryContent(directoryHandle, repoData);

    const setSessionMode = useStore.getState().setSessionMode;
    setSessionMode("local");
    setURLParam(window, "session", "local");

    // store directory handle in indexedDB
    await storeOpenProjectMetadataInCodeCanvasIndexedDB();

    const setReposList = useStore.getState().setReposList;
    setReposList([
      { name: directoryMetaData.repo, owner: directoryMetaData.username },
    ]);

    let indexedDBRecentlyOpened =
      await getOpenProjectsMetadataFromCodeCanvasIndexedDB();

    let repos;
    if (
      indexedDBRecentlyOpened === null ||
      indexedDBRecentlyOpened.length === 0
    ) {
      repos = [{ name: directoryMetaData.repo }];
    } else {
      repos = indexedDBRecentlyOpened
        .filter((recentlyOpenedEntry: [string, IRecentlyOpenedEntry]) => {
          const recentlyOpenedEntryAttributes = recentlyOpenedEntry[1];
          return recentlyOpenedEntryAttributes?.local !== null;
        })
        .map((recentlyOpenedEntry: [string, IRecentlyOpenedEntry]) => {
          const recentlyOpenedEntryRepoName = recentlyOpenedEntry[0];
          return { name: recentlyOpenedEntryRepoName };
        });
    }
    setReposList(repos);

    setURLParam(window, "repo", directoryMetaData.repo);
    const setCurrentRepo = useStore.getState().setCurrentRepo;
    setCurrentRepo(directoryMetaData.repo, {
      owner: directoryMetaData.username,
    });

    const setBranchesList = useStore.getState().setBranchesList;
    setBranchesList([{ name: directoryMetaData.branch }]);

    setURLParam(window, "branch", directoryMetaData.branch);
    const setCurrentBranch = useStore.getState().setCurrentBranch;
    setCurrentBranch(directoryMetaData.branch);

    setURLParam(window, "owner", directoryMetaData.username);
    const setUsername = useStore.getState().setUsername;
    setUsername(directoryMetaData.username);

    const setRepoData = useStore.getState().setRepoData;
    setRepoData(repoData, true);

    return true;
  } catch (error) {
    if (error.name === "AbortError") {
      if (!throwErrorOnUserAbort) {
        return;
      } else {
        throw error;
      }
    }
    const setErrorNotification = useStore.getState().setErrorNotification;
    setErrorNotification(
      "An error occured trying to open the directory, please make sure to select the root directory of a valid repo."
    );
    throw error;
  }
};

/**
 * Loads diagram data from a file.
 */
export async function loadDiagramFileFromLocal() {
  let fileName;
  try {
    const currentRepo = useStore.getState().currentRepo;
    useStore.getState().setModifiedWikis("CLEAR", {});

    if (!(await doesFileExist(`${currentRepo}.CodeCanvas`))) {
      useStore.getState().setIsNewDiagramFileCreated(true);
      sendShadowDataToDrawio(true);
      return;
    }
    useStore.getState().setIsNewDiagramFileCreated(false);
    const { fileContent, fileHandle } = await openFile(
      `${currentRepo}.CodeCanvas`
    );
    fileName = fileHandle?.fileName;
    setDiagramMetadataFromLocalFile(fileHandle || fileHandle.name);
    await loadReferenceDiagramFile();
    await loadDiagramFileToStore(fileContent);
  } catch (ex) {
    const msg = `An error occured loading from local file: ${fileName}`;
    console.error(msg, ex);
    useStore.getState().setErrorNotification(msg);
  }
}

/**
 * loads a .CodeCanvas file content to Zustand store
 *
 *  @param {string} fileContent the content of the .CodeCanvas file
 *
 */
export async function loadDiagramFileToStore(fileContent) {
  const fileName = useStore.getState().session.local.diagramFile.fileName;
  const setDiagramData = useStore.getState().setDiagramData;
  const setDiagramFileMetadata = useStore.getState().setDiagramFileMetadata;

  const content = await JSON.parse(fileContent);
  try {
    if (!content.drawioXML || !content.fileURL) {
      throw new Error(`Incomplete diagram data`);
    }
    setDiagramData({ fileURL: content.fileURL });
    setDiagramFileMetadata({ fileXML: content.drawioXML });
    // set combined repo data objects as repoData
    let mergedRepoData = mergeSavedDataAndRepoData(
      content.repoData,
      useStore.getState().repoData
    );
    useStore.getState().setSimulations(content?.simulations || {});
    useStore.getState().setCellToPath(content.cellToPath);
    useStore.getState().setRepoData(mergedRepoData, false);

    updateRepoDataInCodeCanvasIndexedDB();
    useStore.getState().setLoadingNotification("");
    useStore.getState().setDoLoadDiagram(false);
    await useStore.getState().postToDrawioWaitForResponse({
      action: "UPDATE_DIAGRAM",
      options: { loadNewDiagram: true },
      data: { xml: content.drawioXML },
    });
  } catch (ex) {
    const msg = `An error occured parsing ${fileName}`;
    console.error(msg, ex);
    useStore.getState().setErrorNotification(msg);
  }
}
