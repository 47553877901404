export function areObjectsEqual(obj1, obj2) {
  // Check if both arguments are non-null objects
  if (
    obj1 != null &&
    typeof obj1 === "object" &&
    obj2 != null &&
    typeof obj2 === "object"
  ) {
    // Check if both arguments are arrays
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      // If both arguments are arrays, compare their lengths
      if (obj1.length !== obj2.length) {
        return false;
      }
      // If both arrays are the same length, recursively compare each element
      for (let i = 0; i < obj1.length; i++) {
        if (!areObjectsEqual(obj1[i], obj2[i])) {
          return false;
        }
      }
      return true;
    } else {
      // If both arguments are not arrays, compare their keys
      const obj1Keys = Object.keys(obj1);
      const obj2Keys = Object.keys(obj2);
      // If the objects don't have the same number of keys, they're not equal
      if (obj1Keys.length !== obj2Keys.length) {
        return false;
      }
      // If the objects have the same number of keys, recursively compare their values
      for (const key of obj1Keys) {
        if (!areObjectsEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
      return true;
    }
  } else {
    // If either argument is not an object, compare their values
    return obj1 === obj2;
  }
}

// Similar to the above, but returns an array of differences
export function getDiffs(obj1, obj2, path = ""): StateChange[] {
  let diffs = [];

  // Check if both arguments are objects
  if (
    typeof obj1 === "object" &&
    obj1 !== null &&
    typeof obj2 === "object" &&
    obj2 !== null
  ) {
    // Get keys from both objects
    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

    for (const key of keys) {
      const newPath = path ? `${path}/${key}` : key;
      if (!(key in obj1)) {
        diffs.push({
          path: newPath,
          previous: "KEY_DOES_NOT_EXIST",
          current: obj2[key],
        });
      } else if (!(key in obj2)) {
        diffs.push({
          path: newPath,
          previous: obj1[key],
          current: "KEY_DOES_NOT_EXIST",
        });
      } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        if (obj1[key].length !== obj2[key].length) {
          diffs.push({
            path: newPath,
            previous: obj1[key],
            current: obj2[key],
          });
        } else {
          for (let i = 0; i < obj1[key].length; i++) {
            diffs = diffs.concat(
              getDiffs(obj1[key][i], obj2[key][i], `${newPath}[${i}]`)
            );
          }
        }
      } else if (
        typeof obj1[key] === "object" &&
        typeof obj2[key] === "object"
      ) {
        // Both are objects, recurse.
        diffs = diffs.concat(getDiffs(obj1[key], obj2[key], newPath));
      } else if (obj1[key] !== obj2[key]) {
        // Values are different, save.
        diffs.push({ path: newPath, previous: obj1[key], current: obj2[key] });
      }
    }
  } else if (obj1 !== obj2) {
    // If the values are not objects and are not the same, save the difference.
    diffs.push({ path: path, previous: obj1, current: obj2 });
  }

  return diffs;
}
