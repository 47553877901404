import BackToLandingPageButtonSVG from "../../Media/BackToLandingPageButton";
import styled from "styled-components";
import useStore from "../../Store/Store";
import { useEffect, useState } from "react";
import { logger } from "../../utils/Logger";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Tooltip from "@mui/material/Tooltip";

const BackToLandingPageButtonWrapper = styled.div<{ $pulse?: boolean; }>`
  position: fixed;
  top: 33.7vh;
  left: -0.7vw;
  height: 5vw;
  width: 5vw;
  cursor: pointer;
  animation: ${props => props.$pulse ? "pulsate 2s infinite": ""};

  /* Hover states */
  &:hover .folderIcon {
    fill: #ffaea6;
    color: #ffaea6;
  }
  &:hover .halfCircle {
    fill: white;
  }
  &:hover .pencil {
    fill: #ffb6a6;
  }

  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const BackToLandingPageButton: React.FC<any> = () => {
  const { currentRepo } = useStore((state) => ({
    currentRepo: state.currentRepo,
  }));

  const [showPulse, setShowPulse] =
    useState(false);

  useEffect(() => {
    // if it's react-ecommerce, it's a demo diagram
    setShowPulse(currentRepo === "react-ecommerce");
  }, [currentRepo]);

  const handleClick = async () => {
    try {
      // set show landing page to true
      useStore.getState().setShowLandingComponent(true);
    } catch (err) {
      logger.error("Error in BackToLandingPageButton handleClick", err);
    }
  };

  return (
    <Tooltip title="Load your repository in one click and start building your diagram!">
      <BackToLandingPageButtonWrapper
        className="BackToLandingPageButtonWrapper"
        onClick={handleClick}
        $pulse={showPulse}
      >
        <BackToLandingPageButtonSVG />
        <AddRoundedIcon
          htmlColor="#ffffff"
          style={{
            position: "absolute",
            top: "30%",
            left: "18%",
            width: "30px",
            height: "30px",
          }}
          className="folderIcon"
        />
      </BackToLandingPageButtonWrapper>
    </Tooltip>

  );
};
export default BackToLandingPageButton;
