import { Dialog as MUIDialog, DialogContent, Typography } from "@mui/material";
import useStore from "../../Store/Store";
import { theme } from "../../Themes/Themes";
import MessageDisplay from "../../utils/MessageDisplay";
import "./Dialog.css";
import { logger } from "../../utils/Logger";

export default function Dialog() {
  const { dialog, setDialog, isPerformingUndoRedo } = useStore((state) => ({
    dialog: state.dialog,
    setDialog: state.setDialog,
    currentRepo: state.currentRepo,
    setCurrentPath: state.setCurrentPath,
    isPerformingUndoRedo: state.isPerformingUndoRedo,
  }));

  async function button1Function() {
    try {
      await dialog.data.button1Function();
    } catch (error) {
      console.error(error);
    }
    setDialog("");
  }

  async function button2Function() {
    try {
      await dialog.data.button2Function();
    } catch (error) {
      logger.error(error);
    }
    setDialog("");
  }

  return (
    !isPerformingUndoRedo && (
      <MUIDialog
        open={dialog.type === "DIALOG" && dialog.data !== null}
        sx={{
          "& .MuiPaper-root": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            borderRadius: "30px",
            width: "50vw",
          },
        }}
        onClose={button2Function}
      >
        <DialogContent className="DialogContent">
          <br />
          <Typography
            textAlign={"center"}
            mx={3}
            my={0.8}
            fontSize="18px"
            fontWeight="Light"
            color={theme.custom.darkerGrey}
            sx={{ paddingBottom: "10px" }}
          >
            {typeof dialog?.data?.message === "string" ? (
              <MessageDisplay message={dialog?.data?.message} />
            ) : (
              dialog?.data?.message
            )}
          </Typography>
          <div
            className="DialogButtonGroupHorizontal"
            style={{ paddingBottom: "10px" }}
          >
            <div
              className="Button DialogButton"
              style={{ marginRight: "30px" }}
              onClick={button2Function}
            >
              <Typography
                mx={0.2}
                my={0.8}
                fontSize="15px"
                fontWeight="Normal"
                color="#FFAEA6"
              >
                {dialog.data?.button2Label}
              </Typography>
            </div>
            {dialog?.data?.button1Function && (
              <div
                className="Button DialogButton"
                style={{ marginLeft: "30px" }}
                onClick={button1Function}
              >
                <Typography
                  mx={0.2}
                  my={0.8}
                  fontSize="15px"
                  fontWeight="Normal"
                  color="#FFAEA6"
                >
                  {dialog.data?.button1Label}
                </Typography>
              </div>
            )}
          </div>
        </DialogContent>
      </MUIDialog>
    )
  );
}
