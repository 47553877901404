import { useState, useEffect } from "react";

import { CheckRunCellIdElement } from "./CheckRunCellIdElement/CheckRunCellIdElement";
import useStore from "../../../../Store/Store";
import "./CheckRunTab.css";

export const CheckRunTab: React.FC = () => {
  const [checkRunTabContent, setCheckRunTabContent] = useState([]);

  const { openFolderOnDoubleClickFlag, checkRunImpactedCells } = useStore(
    (state) => ({
      openFolderOnDoubleClickFlag: state.openFolderOnDoubleClickFlag,
      checkRunImpactedCells: state.checkRunImpactedCells,
    })
  );

  // checkRunTab content is only dependent on the current Path
  useEffect(() => {
    if (!checkRunImpactedCells || checkRunImpactedCells.length === 0) {
      return;
    }
    setCheckRunTabContent(
      checkRunImpactedCells?.map(
        (checkRunImpactedCell: CheckRunImpactedCell, index: number) => {
          return (
            <CheckRunCellIdElement
              key={index}
              path={checkRunImpactedCell.path}
              cellId={checkRunImpactedCell.cellId}
            />
          );
        }
      )
    );
  }, [openFolderOnDoubleClickFlag]);

  return (
    <div className="checkRunContainer" style={{ height: "85%" }}>
      {checkRunTabContent}
    </div>
  );
};
