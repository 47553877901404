import useStore from "../../../../Store/Store";
import { logger } from "../../../../utils/Logger";

export const toggleCellsVisibility = (
  visibilityFlag: boolean,
  ignoreList: string[] = []
) => {
  // hide cells with visible flag set to false
  const repoData = useStore.getState().repoData;
  const cellsWithTheGivenVisibilityFlag = [];
  Object.entries(repoData).forEach(([_, cellData]) => {
    if (visibilityFlag) {
      if (cellData.cellId && !ignoreList.includes(cellData.cellId)) {
        cellsWithTheGivenVisibilityFlag.push(cellData.cellId);
      }
    } else if (
      cellData.visible === false &&
      cellData.cellId &&
      !ignoreList.includes(cellData.cellId)
    ) {
      cellsWithTheGivenVisibilityFlag.push(cellData.cellId);
      // remove cell selection if selected
      const currentRepo = useStore.getState().currentRepo;
      const selectedCell = useStore.getState().selectedCell;
      if (selectedCell === cellData.cellId) {
        useStore.getState().setCurrentPath(`${currentRepo}`);
        useStore.getState().setSelectedCell("");
      }
    }
  });
  if (cellsWithTheGivenVisibilityFlag.length === 0) {
    return Promise.resolve({ status: "SUCCESS" });
  }

  return useStore
    .getState()
    .postToDrawioWaitForResponse({
      action: "CHANGE_CELLS_STYLE",
      data: {
        cellIds: cellsWithTheGivenVisibilityFlag,
        style: {
          visible: visibilityFlag,
        },
        options: {
          temporaryStyleChange: true,
        },
      },
    })
    .catch((error) => {
      logger.error("Error hiding cells: ", error);
    });
};

export const checkDiagramNodeIdMatch = (repoDataObject) => {
  const simulationsState = useStore.getState().simulationsState;

  // need to check un-saved sim if in editing mode
  let simulation = null;
  if (simulationsState.isEditingSimulations) {
    simulation = useStore.getState().currentUnsavedSimulationObject;
  } else {
    simulation =
      simulationsState?.simulations[simulationsState.selectedSimulationKey];
  }

  for (const simStepKey in simulation?.simSteps) {
    const simStep = simulation?.simSteps[simStepKey];
    if (simStep?.diagramNodeId === repoDataObject?.cellId) {
      return true; // Match found
    }
  }

  return false; // No match found
};
