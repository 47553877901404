import { useState, useEffect } from "react";
import { ReposListResponse } from "../../Api/Responses";
import { getRepos } from "../../Api/ServerInterface";
import useStore from "../../Store/Store";
import { getDirectoryHandleFromCodeCanvasIndexedDB } from "../../utils/CodeCanvasIndexedDB";
import { openDirectory } from "../../utils/LocalFileController/LocalFileController";
import { verifyPermission } from "../../utils/LocalFileController/LocalFileHelpers";
import { setURLParam, removeURLParam, getURLParam } from "../../utils/URLUtils";
import { IRecentlyOpenedEntry } from "../LandingPage/RecentFilesDropdown";
import { Dropdown } from "./Dropdown/Dropdown";

export function RepoDropdown() {
  const [repoOptions, setRepoOptions] = useState(null);
  const {
    currentRepo,
    reposList,
    isLoggedIn,
    currentRepoMetadata,
    sessionMode,
    setCurrentRepo,
    setCurrentBranch,
    setErrorNotification,
    setReposList,
  } = useStore((state) => ({
    currentRepo: state.currentRepo,
    reposList: state.reposList,
    isLoggedIn: state.session.isLoggedIn,
    currentRepoMetadata: state.currentRepoMetadata,
    sessionMode: state.session.mode,
    setCurrentRepo: state.setCurrentRepo,
    setCurrentBranch: state.setCurrentBranch,
    setErrorNotification: state.setErrorNotification,
    setReposList: state.setReposList,
  }));

  const handleRepoChange = (event: any) => {
    const repoName = event.target.value.split("/")[1];
    if (sessionMode === "github") {
      const repoInfo = reposList.find((repo) => repoName === repo.name);
      const repoOwner = repoInfo ? repoInfo.owner?.login : null;
      setCurrentRepo(repoName, { owner: repoOwner });
      removeURLParam(window, "branch");
      setCurrentBranch("");
    } else if (sessionMode === "local") {
      const selectFolder = async (event: any) => {
        let recentlyOpenedEntry: IRecentlyOpenedEntry =
          await getDirectoryHandleFromCodeCanvasIndexedDB(repoName);
        let directoryHandle =
          recentlyOpenedEntry?.local as FileSystemDirectoryHandle;
        verifyPermission(directoryHandle, true)
          .then(async (permission) => {
            if (permission === false) {
              console.error("No permission to access local directory");
              setErrorNotification("No permission to access local directory");
              return;
            }

            // set directory handle for local file
            const setLocalDirectoryMetaData =
              useStore.getState().setLocalDirectoryMetaData;
            setLocalDirectoryMetaData({
              handle: directoryHandle,
            });
            await openDirectory(false, directoryHandle);
          })
          .catch((err) => console.error(err));
      };
      selectFolder(event);
    }
  };

  const renderRepos = () => {
    if (reposList.length === 0) {
      return <option value="">Login to see repositories!</option>;
    }

    let reposComponents = [];

    reposComponents.push(
      <option key={"selectRepo"} value={""}>
        Repository
      </option>
    );
    for (let i = 0; i < reposList.length; i++) {
      let name = `${reposList[i].owner?.login}/${reposList[i].name}`;
      reposComponents.push(
        <option key={i} value={name}>
          {name}
        </option>
      );
    }

    return reposComponents;
  };

  // STEP 2: When user is logged in, get their repos
  useEffect(() => {
    if (isLoggedIn) {
      getRepos()
        .then((userRepos: ReposListResponse) => {
          setReposList(userRepos.data);
        })
        .catch((e) => console.error(e));
    } else if (sessionMode !== "local") {
      // if sessionMode != local that means user has to be logged in to view repositories
      // So if they are not, clear currentRepo
      setCurrentRepo("", { owner: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  // STEP 3: When repos are retrieved, render repos, and choose default repo if exists in URL Params
  useEffect(() => {
    setRepoOptions(renderRepos());
    const urlParamRepo = getURLParam(window, "repo");
    const urlParamOwner = getURLParam(window, "owner");

    if (urlParamRepo) {
      setCurrentRepo(urlParamRepo, { owner: urlParamOwner });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reposList]);

  // STEP 4.A: When currentRepo is set, set repo URL param, clear branch param, and clear currentBranch
  useEffect(() => {
    //TODO: maybe set a landing complete flag so that branch isn't reset on landing
    if (currentRepo) {
      setURLParam(window, "repo", currentRepo);
      setURLParam(window, "owner", currentRepoMetadata.owner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRepo, currentRepoMetadata]);

  // If current repo not in repoOptions, add currentRepo to repoOptions
  useEffect(() => {
    if (currentRepo && repoOptions && !repoOptions.type) {
      const repoNames = repoOptions.map((option) => option.props.value);
      if (!repoNames.includes(currentRepo)) {
        setRepoOptions([
          ...repoOptions,
          <option key={repoOptions.length} value={currentRepo}>
            {currentRepo}
          </option>,
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRepo, repoOptions]);

  return (
    <Dropdown
      options={repoOptions}
      onChange={handleRepoChange}
      className="Repo HeaderDropdownWrapper"
      highlighted={!currentRepo && sessionMode !== "local"}
      placeholder={"Choose repository"}
      dataTestId="repoDropdown"
      value={currentRepo}
    />
  );
}
