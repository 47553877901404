import ChooseRepoBranchDialog from "./ChooseRepoBranchDialog";
import OverwriteDialog from "./OverwriteDialog";
import WarningDialog from "./WarningDialog";
import DeleteDialog from "./DeleteDialog";
import UnlinkDialog from "./UnlinkDialog";
import Dialog from "./Dialog";
import EmailDialog from "./EmailDialog/EmailDialog";
export default function Dialogs() {
  return (
    <>
      <ChooseRepoBranchDialog />
      <OverwriteDialog />
      <WarningDialog />
      <DeleteDialog />
      <UnlinkDialog />
      <EmailDialog /> 
      <Dialog />
    </>
  );
}
