import produce from "immer";
import useStore from "../../../Store/Store";
import { areObjectsEqual } from "../../../utils/DeepObjectCompare";

// Compares the new wiki entry to the saved wiki and updates the modifiedWikis state
export function compareNewWikiToSavedWiki(path: string, newWiki: string) {
  const referenceRepoData = useStore.getState().referenceDiagramFile.repoData;
  let newWikiObj = {};
  newWikiObj[path] = newWiki;

  // If there is no saved wiki
  if (
    !referenceRepoData ||
    !referenceRepoData[path] ||
    !referenceRepoData[path]?.wiki
  ) {
    // if the new wiki entry is empty, then delete from modifiedWikis state
    if (newWiki === "" || newWiki === undefined) {
      useStore.getState().setModifiedWikis("DELETE", newWikiObj);
    } else {
      useStore.getState().setModifiedWikis("UPDATE", newWikiObj);
    }
  } else {
    let modifiedWikis = useStore.getState().fileStatus.modifiedWikis;

    // If there is saved wiki, then the compare the new wiki to the saved wiki and update the modifiedWikis state
    if (referenceRepoData[path].wiki !== newWiki) {
      useStore.getState().setModifiedWikis("UPDATE", newWikiObj);
    } else {
      // If the new wiki is the same as the saved wiki, then remove the wiki from the modifiedWikis state
      if (path in modifiedWikis) {
        useStore.getState().setModifiedWikis("DELETE", newWikiObj);
      }
    }
  }
}

// Compares wikis of new repoData to saved repoData and updates the modifiedWikis state
export function compareRepoDataWikis(newRepoData) {
  const referenceRepoData = useStore.getState().referenceDiagramFile.repoData;

  if (Object.keys(newRepoData).length === 0) {
    // Handle case if original file didn't have any wikis, and all changes were discarded (i.e. newRepoData also doesn't have any wikis)
    if (Object.keys(referenceRepoData).length === 0) {
      useStore.getState().setModifiedWikis("CLEAR", {});
    } else {
      // Handle case if original file had wikis, but user removed all of them (i.e. newRepoData doesn't have any wikis)
      for (let path in referenceRepoData) {
        const newWiki = "";
        compareNewWikiToSavedWiki(path, newWiki);
      }
      return;
    }
  }

  for (let path in newRepoData) {
    const newWiki = newRepoData[path]?.wiki;
    compareNewWikiToSavedWiki(path, newWiki);
  }
}

export function handleModifiedWikis(set, action: string, modifiedWiki: Object) {
  if (action === "UPDATE") {
    set(
      produce((state: Store) => {
        state.fileStatus.modifiedWikis = {
          ...state.fileStatus.modifiedWikis,
          ...modifiedWiki,
        };
        state.fileStatus.isWikiModified = true;
      })
    );
  } else if (action === "DELETE") {
    const key = Object.keys(modifiedWiki)[0];
    const modifiedWikis = useStore.getState().fileStatus.modifiedWikis;
    if (!modifiedWikis.hasOwnProperty(key)) {
      return;
    }

    const newState = Object.keys(modifiedWikis)
      .filter((k) => k !== key)
      .reduce((acc, k) => {
        acc[k] = modifiedWikis[k];
        return acc;
      }, {});
    set(
      produce((state: Store) => {
        state.fileStatus.modifiedWikis = newState;
        if (Object.keys(newState).length === 0) {
          state.fileStatus.isWikiModified = false;
        }
      })
    );
  } else if (action === "CLEAR") {
    set(
      produce((state: Store) => {
        state.fileStatus.modifiedWikis = {};
        state.fileStatus.isWikiModified = false;
      })
    );
  }
}

export async function sendShadowDataToDrawio(emptyCanvas = false) {
  const referenceDiagramFile = useStore.getState().referenceDiagramFile;
  const drawioResponse = await useStore.getState().postToDrawioWaitForResponse({
    action: "UPDATE_SHADOW_DATA",
    data: { xml: emptyCanvas ? "" : referenceDiagramFile.drawioXML },
  });
  if (drawioResponse.status === "ERROR") {
    console.error("Error updating shadow data", drawioResponse);
  }
}

export function isSimulationsInSyncWithSaved(
  modifiedSimulations: ModifiedSimulations
) {
  let newIsSimulationsModified = false;
  // if there are any removed, added, or modified simulations, set isSimulationsModified to true
  if (
    Object.keys(modifiedSimulations.removed).length > 0 ||
    Object.keys(modifiedSimulations.added).length > 0 ||
    Object.keys(modifiedSimulations.modified).length > 0
  ) {
    newIsSimulationsModified = true;
  }
  return newIsSimulationsModified;
}

// Compares simulations of new simulations object to saved simulations and updates the modifiedSimulations state
export function compareRefSimulationsToCached(newSimulations) {
  const refSimulations = useStore.getState().referenceDiagramFile.simulations;
  /**
   *
   * 4 possible cases:
   * 1. Added: simulationName exists in newSimulations but not in refSimulations
   * 2. removed: simulationName does not exist in newSimulations but exists in refSimulations
   * 3. modified: simulationName exists in both newSimulations and refSimulations, but the objects are not equal
   * 4. simulationName exists in both newSimulations and refSimulations, and the objects are equal
   */

  let modifiedSimulations = {
    added: {},
    removed: {},
    modified: {},
  };

  for (let simulationName in newSimulations) {
    // case 1: added
    if (!(simulationName in refSimulations)) {
      modifiedSimulations.added[simulationName] =
        newSimulations[simulationName];
    }
    // case 3: modified
    else if (
      !areObjectsEqual(
        newSimulations[simulationName],
        refSimulations[simulationName]
      )
    ) {
      modifiedSimulations.modified[simulationName] =
        newSimulations[simulationName];
    }
  }
  // case 2: removed
  for (let simulationName in refSimulations) {
    if (!(simulationName in newSimulations)) {
      modifiedSimulations.removed[simulationName] =
        refSimulations[simulationName];
    }
  }
  let isSimulationsModified = isSimulationsInSyncWithSaved(modifiedSimulations);

  // Update modifiedSimulations state
  useStore
    .getState()
    .setModifiedSimulations(modifiedSimulations, isSimulationsModified);
}

export function isCellToPathInSyncWithSaved(
  modifiedCellToPath: ModifiedCellToPath
) {
  let newIsCellToPathModified = false;
  // if there are any removed, added, or modified cellToPath, set isCellToPathModified to true
  if (
    Object.keys(modifiedCellToPath.removed).length > 0 ||
    Object.keys(modifiedCellToPath.added).length > 0 ||
    Object.keys(modifiedCellToPath.modified).length > 0
  ) {
    newIsCellToPathModified = true;
  }
  return newIsCellToPathModified;
}

// Compares cellToPaths of new cellToPaths object to saved cellToPaths and updates the modifiedCellToPath state
export function compareRefCellToPathToCached(newCellToPath) {
  const refCellToPath = useStore.getState().referenceDiagramFile.cellToPath;
  /**
   *
   * 4 possible cases:
   * 1. Added: cellId exists in newCellToPath but not in refCellToPath
   * 2. removed: cellId does not exist in newCellToPath but exists in refCellToPath
   * 3. modified: cellId exists in both newCellToPath and refCellToPath, but the path is different
   * 4. cellId exists in both newCellToPath and refCellToPath, and the path is the same
   */

  let modifiedCellToPath = {
    added: {},
    removed: {},
    modified: {},
  };

  for (let cellId in newCellToPath) {
    // case 1: added
    if (!(cellId in refCellToPath)) {
      modifiedCellToPath.added[cellId] = newCellToPath[cellId];
    }
    // case 3: modified
    else if (newCellToPath[cellId] !== refCellToPath[cellId]) {
      modifiedCellToPath.modified[cellId] = newCellToPath[cellId];
    }
  }
  // case 2: removed
  for (let cellId in refCellToPath) {
    if (!(cellId in newCellToPath)) {
      modifiedCellToPath.removed[cellId] = refCellToPath[cellId];
    }
  }
  let isCellToPathModified = isCellToPathInSyncWithSaved(modifiedCellToPath);

  // Update modifiedCellToPath state
  useStore
    .getState()
    .setModifiedCellToPath(modifiedCellToPath, isCellToPathModified);
}
