import EditButtonSVG from "../../Media/EditButton";
import styled from "styled-components";
import useStore from "../../Store/Store";
import { useEffect } from "react";

const EditButtonWrapper = styled.div`
  position: fixed;
  top: 43vh;
  left: -0.7vw;
  height: 5vw;
  width: 5vw;
  cursor: pointer;
`;

const EditButton: React.FC<any> = () => {
  const { showEditButton, setToolbar, postToDrawioWaitForResponse } = useStore(
    (state) => ({
      showEditButton: state.toolbar.showEditButton,
      setToolbar: state.setToolbar,
      postToDrawioWaitForResponse: state.postToDrawioWaitForResponse,
    })
  );

  useEffect(() => {}, [showEditButton]);
  const handleClick = async () => {
    try {
      const drawioResponse = await postToDrawioWaitForResponse({
        action: "OPEN_FORMAT_PANEL",
      });
      if (drawioResponse.status === "SUCCESS") {
        setToolbar({ showEditButton: false });
      }
    } catch (err) {
      // Don't hide edit button if drawio did not show format panel
      setToolbar({ showEditButton: true });
    }
  };
  return (
    showEditButton && (
      <EditButtonWrapper onClick={handleClick}>
        <EditButtonSVG />
      </EditButtonWrapper>
    )
  );
};

export default EditButton;
