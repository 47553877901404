import { CustomizedDrawioClient } from "./CustomizedDrawioClient";
import { DrawioEditor, DrawioEditorService } from "./DrawioEditorService";

export class DrawioEditorProviderText {
  public editor: DrawioEditor | undefined;
  public client: CustomizedDrawioClient | undefined;
  public iFrameRef: any;
  constructor(
    private readonly drawioEditorService: DrawioEditorService,
    iFrameRef: any
  ) {
    this.iFrameRef = iFrameRef;
    this.resolveCustomTextEditor();
  }

  public async resolveCustomTextEditor(): Promise<void> {
    try {
      this.editor = await this.drawioEditorService.createDrawioEditorInWebview(
        this.iFrameRef
      );
      const drawioClient = this.editor.drawioClient;

      drawioClient.onInit.sub(async () => {
        drawioClient.loadXmlLike("", "");
      });
    } catch (e) {
      window.console.error(`Failed to open diagram: ${e}`);
      throw e;
    }
  }
}
