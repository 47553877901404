// import { Config } from "./Config";
import { CustomizedDrawioClient } from "./CustomizedDrawioClient";
import { iframeContent } from "./iframeContent";
import { formatObject, formatValue } from "../utils/formatValue";
import { simpleDrawioLibrary } from "./simpleDrawioLibrary";
import { Config } from "./Config";

const REACT_APP_DRAWIO_URL = process.env.REACT_APP_DRAWIO_URL;

export class DrawioClientFactory {
  constructor(private readonly config: Config) {}
  public getHtml() {
    const vsuri =
      "https://file%2B.vscode-resource.vscode-cdn.net/Users/naser/Desktop/Projects/CodeCanvas/drawio";

    const patchedHtml = iframeContent.replace(/\$\$literal-vsuri\$\$/g, vsuri);
    return patchedHtml;
  }

  public async createDrawioClientInWebview(
    iFrameRef: any
  ): Promise<CustomizedDrawioClient> {
    const config = this.config.getDiagramConfig();
    const drawioClient = new CustomizedDrawioClient(
      {
        sendMessage: (msg) => {
          // console.log(
          //   "%cCodeCanvas -> drawio: " + prettify(msg),
          //   "color: green;"
          // );
          iFrameRef.current.contentWindow.postMessage(
            msg,
            REACT_APP_DRAWIO_URL
          );
        },
        registerMessageHandler: (handler) => {
          return window.addEventListener("message", function (e) {
            if (e.origin !== REACT_APP_DRAWIO_URL) return;
            handler(e.data);
          });
        },
      },
      async () => {
        const libs = await config.customLibraries;
        return {
          compressXml: false,
          defaultLibraries: "general",
          libraries: simpleDrawioLibrary(libs),
        };
      }
    );

    drawioClient.onUnknownMessage.sub(({ message }) => {
      if (message.event === "updateLocalStorage") {
        const newLocalStorage = message.newLocalStorage;
        // SET LOCAL STORAGE HERE
        // config.setLocalStorage(newLocalStorage);
      }
    });

    return drawioClient;
  }
}

export function prettify(msg: unknown): string {
  try {
    if (typeof msg === "string") {
      const obj = JSON.parse(msg as string);
      return formatObject(obj, process.env.DEV === "1" ? 300 : 300);
    }
    return formatValue(msg, process.env.DEV === "1" ? 300 : 300);
  } catch {}
  return "" + msg;
}
