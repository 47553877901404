import { getFileContentFromGithub } from "../../Api/ServerInterface";
import useStore from "../../Store/Store";
import { openFile } from "../../utils/LocalFileController/LocalFileController";

export async function getFileContent(path = "") {
  const sessionMode = useStore.getState().session.mode;
  let currentPath = path || useStore.getState().currentPath;
  const currentBranch = useStore.getState().currentBranch;
  const repoData = useStore.getState().repoData;
  const setFileContent = useStore.getState().setFileContent;
  let currentRepoData = repoData[currentPath];
  if (currentRepoData?.parentPath) {
    currentPath = currentRepoData.parentPath;
  }
  let content;
  if (sessionMode === "github" || sessionMode === "unauthenticatedGithub") {
    content = await getFileContentFromGithub(
      repoData[currentPath].url,
      currentPath,
      currentBranch
    );
  } else if (sessionMode === "local") {
    const { fileContent } = await openFile(currentPath);
    content = fileContent;
  }
  // handle corner case where code gets parsed if it was in json format
  if (typeof content !== "string") {
    content = JSON.stringify(content, null, 2);
  }
  setFileContent(currentPath, content);
  return content;
}
