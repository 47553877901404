import { wait } from "@hediet/std/timer";
import {
  DrawioEditorService,
  DrawioEditor,
} from "../DrawioClient/DrawioEditorService";
import { autorun, action } from "mobx";
import { Config } from "../DrawioClient/Config";

export class LinkCodeWithSelectedNodeService {
  constructor(
    private readonly editorManager: DrawioEditorService,
    private readonly config: Config
  ) {}

  public linkCodeWithSelectedNode(): void {
    const DrawIOEditor = this.editorManager.editor;
    if (!DrawIOEditor) {
      window.console.log("No active drawio instance.");
      return;
    }
  }
}
