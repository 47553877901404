import React, { useEffect, useRef, useState } from 'react';
import "./EmailDialog.css";
import { logger } from '../../../utils/Logger';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { theme } from '../../../Themes/Themes';
import useStore from "../../../Store/Store";


const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function isValidEmail(email: string): boolean {
    return emailRegex.test(email);
}

const EmailDialog = () => {
    const [email, setEmail] = useState("");
    const { dialog, setDialog, currentRepo } = useStore((state) => ({ dialog: state.dialog, setDialog: state.setDialog, currentRepo: state.currentRepo }));
    const OnSubmit = async () => {
        if (!email.length || !isValidEmail(email))
            return alert(
                "Invalid email was provided! Please enter a valid email and try again."
            );
        logger.info(`Saving email: ${email} to sheets DB...`);
        try {
            const res = await fetch(
                "https://api.apispreadsheets.com/data/0fZMDWK0g77XZOUl/",
                {
                    method: "POST",
                    body: JSON.stringify({ data: { Email: email } }),
                }
            );
            if (res.status === 201) {
                localStorage.setItem("emailEntered", "true");
                logger.info("Email saved successful!");
                closeDialog();
            } else
                throw new Error(
                    `Request to sheets failed with status: ${res.status} and msg: ${res.statusText}`
                );
        } catch (e) {
            logger.error(`Email Save failed with error: ${e.message}`);
        }
    }

    const closeDialog = () => {
        setDialog("")
    }

    // Check to only trigger this once per session
    const popupTimerTriggered = useRef(false);

    useEffect(() => {
        if(popupTimerTriggered.current) return;
        // Start the popup timer if they're past the landing screen and they haven't entered their email before
        if (currentRepo !== "" && !localStorage.getItem("emailEntered")) {
            setTimeout(() => {
                setDialog("EMAIL_DIALOG");
            }, 90 * 1000)
            popupTimerTriggered.current = true;
        }
    }, [currentRepo])


    return <Dialog
        open={dialog.type === "EMAIL_DIALOG"}
        sx={{
            "& .MuiPaper-root": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                borderRadius: "30px",
                width: "50vw",
            },
        }}>
        <DialogContent className="DialogContent">
            <Typography
                textAlign={"center"}
                mx={3}
                my={0.8}
                fontSize="18px"
                fontWeight="Light"
                color={theme.custom.darkerGrey}
                sx={{ paddingBottom: "10px" }}
            >
                <h2>Enjoying CodeCanvas?<br />Secure an early adopter spot!</h2>
                <div style={{ textAlign: "left" }}>
                    Signup to secure your early adopter spot and get two years of CodeCanvas Pro free once its available.
                    <br /><br />
                    <b>Planned features include:</b>
                    <ul>
                        <li>Automated AI diagram generation and maintenance</li>
                        <li>Live diagram debugging</li>
                        <li>
                            View health status of your deployed system directly
                            on diagram components
                        </li>
                        <li>Identify bottlenecks (e.g. traffic heat map on diagrams)</li>
                        <li>Premium support</li>
                    </ul>
                </div>
            </Typography>
            <div className="EmailDialogInputButtonWrapper">
                <input
                    className="EmailDialogInput"
                    value={email}
                    placeholder='example@domain.com'
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    type="email"
                />
                <div
                    className="DialogButtonGroupHorizontal"
                    style={{ paddingBottom: "10px" }}
                >

                    <div
                        className="Button DialogButton"
                        style={{ marginRight: "30px" }}
                        onClick={closeDialog}
                    >
                        <Typography
                            mx={0.2}
                            my={0.8}
                            fontSize="15px"
                            fontWeight="Normal"
                            color="#FFAEA6"
                        >
                            Back
                        </Typography>
                    </div>
                    <div
                        className="Button DialogButton"
                        style={{ marginLeft: "30px" }}
                        onClick={OnSubmit}
                    >
                        <Typography
                            mx={0.2}
                            my={0.8}
                            fontSize="15px"
                            fontWeight="Normal"
                            color="#FFAEA6"
                        >
                            Submit
                        </Typography>
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>

};

export default EmailDialog