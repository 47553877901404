import { Config, DiagramConfig } from "./Config";
import { CustomizedDrawioClient } from "./CustomizedDrawioClient";
import { DrawioClientFactory } from "./DrawioClientFactory";
import useStore from "../Store/Store";

export class DrawioEditorService {
  editor: DrawioEditor | undefined;
  constructor(
    private readonly config: Config,
    private readonly drawioClientFactory: DrawioClientFactory
  ) {}

  public async createDrawioEditorInWebview(
    iFrameRef: any
  ): Promise<DrawioEditor> {
    const instance = await this.drawioClientFactory.createDrawioClientInWebview(
      iFrameRef
    );
    const config = this.config.getDiagramConfig();

    this.editor = new DrawioEditor(PrivateSymbol, instance, config);
    const setDrawioClient = useStore.getState().setDrawioClient;
    setDrawioClient(instance);
    return this.editor;
  }
}

const PrivateSymbol = Symbol();

/**
 * Represents a drawio editor in VS Code.
 * Wraps a `CustomizedDrawioClient` and a webview.
 */
export class DrawioEditor {
  constructor(
    _constructorGuard: typeof PrivateSymbol,
    public readonly drawioClient: CustomizedDrawioClient,
    public readonly config: DiagramConfig
  ) {}
}
