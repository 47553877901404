import * as Sentry from "@sentry/react";

function beforeSendHandler(event: any, hint = null) {
  const userInitiatedFeedback =
    event.exception?.values[0]?.value === "[USER FEEDBACK]";

  function onDialogLoad() {
    // find the ptag inside the dialog with the text "Our team has been notified" and remove it
    const dialog = document.querySelector(".sentry-error-embed-wrapper");
    const pTags = dialog.querySelectorAll("p");
    for (let i = 0; i < pTags.length; i++) {
      if (pTags[i].innerHTML.includes("has been notified")) {
        const text = pTags[i].childNodes[0].textContent;
        if (text.includes("notified")) {
          // remove the text
          pTags[i].removeChild(pTags[i].childNodes[0]);
        }
      }
    }

    // get the button with text "Submit" and change its color to #FFAEA7
    const submitButton = dialog.querySelector("button");
    submitButton.style.backgroundColor = "#FFAEA7";
    // remove border and increase radius
    submitButton.style.border = "none";
    submitButton.style.borderRadius = "5px";
  }

  const subtitle2 = `Experiencing bugs? After submitting the form below, save your diagram from the file menu at the top right corner, then click on the 3 dots next to the file menu to reset the cache.`;
  const userInitiatedLabelComment = userInitiatedFeedback
    ? "Feedback"
    : "Describe the bug";
  const crashLabelCommend =
    "Do you have additional details you want to share? (e.g. what you were doing when the crash happened)";
  const labelComments = userInitiatedFeedback
    ? userInitiatedLabelComment
    : crashLabelCommend;
  Sentry.showReportDialog({
    eventId: event.event_id,
    title: "Feedback Form",
    labelSubmit: "Submit",
    subtitle2: subtitle2,
    labelComments: labelComments,
    onLoad: onDialogLoad,
    onClose: () => {
      // reload the page after 1 second
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  });

  return event;
}

export const sentryConfig = {
  dsn: "https://41fd3f849f2dd777c81694b921cb4942@o4506458289405952.ingest.sentry.io/4506458292158464",
  integrations: [
    // Keep the Replay integration as before
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),

    // The following is all you need to enable canvas recording with Replay
    Sentry.replayCanvasIntegration(),

    Sentry.browserTracingIntegration(),
  ],
  tracePropagationTargets: [
    "localhost",
    "code-canvas.com",
    "api.code-canvas.com",
    "dev.code-canvas.com",
    "https://pie-crepe-38f.notion.site",
    "https://prod.d3ifjc3falrtv0.amplifyapp.com",
    "https://dev.d3ifjc3falrtv0.amplifyapp.com",
  ],
  // stop all communication with sentry server if user has disabled telemetry
  enabled: localStorage.getItem("telemetryData") !== "false",
  beforeSend(event) {
    return beforeSendHandler(event);
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: process.env.REACT_APP_AWS_COMMIT_ID,
};
