import useStore from "../Store/Store";

export function handleKeyDown(event) {
  // Check if the event was fired inside an input element
  if (event.target.tagName === "INPUT") {
    // If so, ignore this event
    return;
  }
  // Check if the event was fired inside a contenteditable element
  if (isContentEditable(event.target)) {
    // If so, ignore this event
    return;
  }

  // Propagate the event to the iframe
  // propagate if ctrl/cmd + z or ctrl/cmd + shift + z or ctrl/cmd + y or ctrl/cmd + shift + y
  if (event.ctrlKey || event.metaKey) {
    if (
      event.key === "z" ||
      event.key === "Z" ||
      event.key === "y" ||
      event.key === "Y" ||
      event.key === "h" ||
      event.key === "H"
    ) {
      const eventData = {
        key: event.key,
        metaKey: event.metaKey,
        ctrlKey: event.ctrlKey,
        shiftKey: event.shiftKey,
        altKey: event.altKey,
        type: event.type,
        timeStamp: event.timeStamp,
        keyCode: event.keyCode,
      };
      useStore
        .getState()
        .postToDrawioWaitForResponse({ action: "KEYDOWN", data: eventData });
    } // handle ctrl/cmd + f or ctrl/cmd + shift + f
    else if (event.key === "f" || event.key === "F") {
      // // prevent default browser search from opening
      // event.preventDefault();
      // // select the search bar
      // const searchInput: HTMLInputElement =
      //   document.querySelector(".search-input");
      // if (searchInput) {
      //   searchInput.focus();
      // }
    }
  }
}

// Helper function to check if an element or any of its ancestors is contenteditable
function isContentEditable(element) {
  if (element.getAttribute("contenteditable") === "true") {
    return true;
  } else if (element.parentElement) {
    return isContentEditable(element.parentElement);
  } else {
    return false;
  }
}
