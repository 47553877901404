import styled from "styled-components";

const DiscordButton = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const DiscordButtonSVG = () => {
  return (
    <DiscordButton className="DiscordButton">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 800 620"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="DiscordLogoPath"
          id="Vector"
          d="M677.676 51.8644C625.891 27.6353 570.519 10.0266 512.63 0C505.52 12.8537 497.214 30.1422 491.488 43.8951C429.95 34.6406 368.978 34.6406 308.572 43.8951C302.846 30.1422 294.352 12.8537 287.179 0C229.227 10.0266 173.792 27.7 122.006 51.9927C17.5548 209.833 -10.7604 363.753 3.39722 515.487C72.6751 567.222 139.814 598.649 205.819 619.214C222.116 596.785 236.651 572.942 249.173 547.813C225.325 538.752 202.484 527.569 180.902 514.587C186.628 510.346 192.229 505.911 197.639 501.348C329.273 562.916 472.297 562.916 602.358 501.348C607.832 505.911 613.432 510.346 619.095 514.587C597.449 527.633 574.546 538.815 550.699 547.878C563.22 572.942 577.692 596.849 594.052 619.278C660.121 598.713 727.322 567.286 796.6 515.487C813.212 339.588 768.222 187.082 677.676 51.8644ZM267.106 422.172C227.591 422.172 195.185 385.282 195.185 340.359C195.185 295.436 226.899 258.483 267.106 258.483C307.314 258.483 339.719 295.372 339.027 340.359C339.089 385.282 307.314 422.172 267.106 422.172ZM532.891 422.172C493.376 422.172 460.971 385.282 460.971 340.359C460.971 295.436 492.683 258.483 532.891 258.483C573.098 258.483 605.504 295.372 604.812 340.359C604.812 385.282 573.098 422.172 532.891 422.172Z"
          fill="#FFAEA6"
        />
      </svg>
    </DiscordButton>
  );
};

export default DiscordButtonSVG;
