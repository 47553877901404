import { FormControl, Box } from "@mui/material";
import { IDropdown } from "./interface";

import "./Dropdown.css";

export const Dropdown: React.FC<IDropdown> = (props: IDropdown) => {
  let highlight = props.highlighted ? "HeaderDropdownHighlight" : "";
  let disabled = props.disabled ? "HeaderDropdownDisabled" : "";
  let status = disabled ? disabled : highlight;
  let className = props.className ? props.className : "HeaderDropdownWrapper";
  return (
    <Box className={className}>
      <FormControl fullWidth variant="outlined" disabled={props.disabled}>
        <select
          className={`HeaderDropdown ${status}`}
          onChange={(e) => props.onChange(e)}
          value={props.value}
          disabled={props.disabled}
          data-testid={props?.dataTestId ? props.dataTestId : ""}
        >
          <option value="" disabled selected>
            {props.placeholder}
          </option>
          {props.options}
        </select>
      </FormControl>
    </Box>
  );
};
