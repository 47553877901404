import { datadogRum } from "@datadog/browser-rum";

export const initDataDog = () =>
  datadogRum.init({
    applicationId: "99cee801-25ed-4f75-a178-c2e974158ccc",
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    site: "us5.datadoghq.com",
    service: "codecanvas",
    env: process.env.REACT_APP_STAGE,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    startSessionReplayRecordingManually: false,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    usePartitionedCrossSiteSessionCookie: true,
    trackSessionAcrossSubdomains: true,
    allowFallbackToLocalStorage: true,
    defaultPrivacyLevel: "allow",
  });
