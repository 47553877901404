import { SetStoreState } from "../DrawioClient/DrawioTypes";
import { logger } from "../utils/Logger";
import useStore from "./Store";

export interface StateChangeRequest {
  path: string;
  state: any;
}

export function handleIsPerformingUndoRedo(drawioEvt) {
  const isPerformingUndoRedo = drawioEvt.data?.isPerformingUndoRedo;
  const trackedAction = drawioEvt.data?.trackedAction;
  const operationType = drawioEvt.data?.type;
  logger.info("performing undo redo", isPerformingUndoRedo);
  if (useStore.getState().isPerformingUndoRedo === isPerformingUndoRedo) {
    logger.error(
      "latency issue: isPerformingUndoRedo is already set to",
      isPerformingUndoRedo
    );
  }
  if (!isPerformingUndoRedo && trackedAction && operationType) {
    useStore
      .getState()
      .setSuccessNotification(
        `${operationType.toUpperCase()}: ${trackedAction}`
      );
  }
  useStore.getState().setIsPerformingUndoRedo(isPerformingUndoRedo);
  useStore.getState().postToDrawio({
    drawioRequestId: drawioEvt.drawioRequestId,
    action: "PERFORMING_UNDO_REDO_RESPONSE",
    status: "SUCCESS",
  });
}

export async function handleRecordStateChange(
  recordStateChange: RecordStateChange,
  trackedAction: TrackedActions,
  set,
  produce
) {
  const immutableOngoingUndoRedoActionsRecordings: TrackedActions[] =
    useStore.getState().ongoingUndoRedoActionsRecordings;

  console.log(typeof immutableOngoingUndoRedoActionsRecordings);
  console.log(Array.isArray(immutableOngoingUndoRedoActionsRecordings));

  const ongoingUndoRedoActionsRecordings = Array.isArray(
    immutableOngoingUndoRedoActionsRecordings
  )
    ? [...immutableOngoingUndoRedoActionsRecordings]
    : [];

  if (recordStateChange === "START") {
    ongoingUndoRedoActionsRecordings.push(trackedAction);
  } else {
    const mostRecentOngoingRecording = ongoingUndoRedoActionsRecordings.pop();

    if (trackedAction === "*" && recordStateChange === "STOP_AND_DISCARD") {
      // "*" disacrds the most recent ongoing recording without checking
      logger.info(
        "Discarding undo/redo action",
        ongoingUndoRedoActionsRecordings
      );
    }
    if (
      trackedAction !== "*" &&
      (!mostRecentOngoingRecording ||
        mostRecentOngoingRecording !== trackedAction)
    ) {
      logger.error(
        `Error: trackedAction does not match most recent ongoing recording. trackedAction: ${trackedAction}, mostRecentOngoingRecording: ${mostRecentOngoingRecording}. currentOngoingUndoRedoActionsRecordings: ${ongoingUndoRedoActionsRecordings}`
      );
    }
  }

  logger.debug.info(
    "current ongoing recordings stack:",
    ongoingUndoRedoActionsRecordings
  );

  await useStore
    .getState()
    .setOngoingUndoRedoActionsRecordings(ongoingUndoRedoActionsRecordings);

  try {
    if (trackedAction === "SIMULATION_MODIFIED") {
      // handle corner case for simulations
      await handleSimulationModified(recordStateChange, set, produce);
      return;
    }
    await useStore.getState().postToDrawioWaitForResponse({
      action: "CODECANVAS_RECORD_STATE_CHANGE",
      data: {
        recordStateChange: recordStateChange,
        trackedAction: trackedAction,
      },
    });

    await set(
      produce((state: Store) => {
        state.recordStateChange = recordStateChange;
      })
    );
  } catch (e) {
    logger.error("Error in CODECANVAS_RECORD_STATE_CHANGE: ", e);
  }
}

/**
 *
 * if recordStateChange is START, then we don't dispatch the action to drawio in the simulation modified case
 * instead, we wait for the simulation to be saved, and then we dispatch the diffs to drawio
 * if recordStateChange is STOP_AND_SAVE, then we dispatch the action to drawio and reset the simulation unsaved changes
 * if recordStateChange is STOP_AND_DISCARD, then we don't dispatch the action to drawio and reset the simulation unsaved changes
 */
export async function handleSimulationModified(
  recordStateChange: RecordStateChange,
  set,
  produce
) {
  if (recordStateChange === "STOP_AND_SAVE") {
    const unsavedSimulationsStateChanges =
      useStore.getState().unsavedSimulationsStateChanges;
    if (unsavedSimulationsStateChanges.length === 0) {
      return;
    }
    // post to recordStateChange = START to drawio first to startUpdate()
    await useStore.getState().postToDrawioWaitForResponse({
      action: "CODECANVAS_RECORD_STATE_CHANGE",
      data: {
        recordStateChange: "START",
        trackedAction: "SIMULATION_MODIFIED",
      },
    });
    // then post the diffs to drawio
    await useStore.getState().postToDrawioWaitForResponse({
      action: "CODECANVAS_STATE_CHANGE",
      data: unsavedSimulationsStateChanges,
    });
    // then post to recordStateChange = STOP_AND_SAVE to drawio to endUpdate()
    await useStore.getState().postToDrawioWaitForResponse({
      action: "CODECANVAS_RECORD_STATE_CHANGE",
      data: {
        recordStateChange: recordStateChange,
        trackedAction: "SIMULATION_MODIFIED",
      },
    });
  }
  // in all cases, clear the array of unsavedSimulationsStateChanges
  // this also handles the case where recordStateChange is STOP_AND_DISCARD
  await set(
    produce((state: Store) => {
      state.unsavedSimulationsStateChanges = [];
    })
  );
}

export function handleSetStoreState(drawioEvt: SetStoreState) {
  const stateChangeRequest: StateChangeRequest = drawioEvt.data;
  const path = stateChangeRequest.path;
  const state = stateChangeRequest.state;
  const pathArray = path.split("/");

  // Use a function to update the state
  useStore.setState((oldState) => {
    let newState = { ...oldState }; // Create a copy of the old state

    let currentLevel = newState; // Start at the top level of the state
    for (let i = 0; i < pathArray.length; i++) {
      const key = pathArray[i];

      if (i === pathArray.length - 1) {
        // If we're at the last key in the path, set the new value
        if (state === "KEY_DOES_NOT_EXIST") {
          delete currentLevel[key];
        } else {
          // Handle special case for "isPerformingUndoRedo" key, we don't want to restore this
          if (key === "isPerformingUndoRedo") continue;
          currentLevel[key] = state;
          // Handle special case if isEditingSimulation is set to false, we want to remove ongoingUndoRedoActionsRecordings that are relevant to simulation
          if (
            key === "ongoingUndoRedoActionsRecordings" &&
            newState?.simulationsState?.isEditingSimulations === ""
          ) {
            currentLevel["ongoingUndoRedoActionsRecordings"] = state.filter(
              (action) => !action.includes("SIMULATION")
            );
          }
        }
      } else {
        // Otherwise, move down one level in the state
        currentLevel[key] = { ...currentLevel[key] };
        currentLevel = currentLevel[key];
      }
    }

    return newState; // Return the new state
  });

  useStore.getState().postToDrawio({
    drawioRequestId: drawioEvt.drawioRequestId,
    action: "SET_STORE_STATE_RESPONSE",
    status: "SUCCESS",
  });
}

interface RecordingOptions {
  supressWarning?: boolean;
  preventDuplicates?: boolean;
}

export async function startRecordingActions(
  trackedAction: TrackedActions,
  options: RecordingOptions = {
    supressWarning: false,
    preventDuplicates: false,
  }
) {
  logger.debug.coloredLog("green", "START recording action: ", trackedAction);
  if (!trackedAction) logger.error("No tracked action name provided");

  if (options?.preventDuplicates) {
    const ongoingUndoRedoActionsRecordings = await useStore.getState()
      .ongoingUndoRedoActionsRecordings;
    if (ongoingUndoRedoActionsRecordings.includes(trackedAction)) {
      if (!options?.supressWarning) {
        logger.error("Duplicate tracked action, cannot start recording again");
      }
      return;
    }
  }
  return await useStore.getState().setRecordStateChange("START", trackedAction);
}

export async function saveRecordedActions(trackedAction: TrackedActions) {
  const currentRecordStateChangeFlag = await useStore.getState()
    .recordStateChange;

  logger.debug.coloredLog(
    "blue",
    "attempting to STOP_AND_SAVE:",
    trackedAction,
    "currentRecordStateChangeFlag: ",
    currentRecordStateChangeFlag
  );
  if (!trackedAction) logger.error("No tracked action name provided");

  return await useStore
    .getState()
    .setRecordStateChange("STOP_AND_SAVE", trackedAction);
}

export async function discardRecordedActions(trackedAction: TrackedActions) {
  const currentRecordStateChangeFlag = await useStore.getState()
    .recordStateChange;

  logger.debug.coloredLog(
    "orange",
    "attempting to STOP_AND_DISCARD:",
    trackedAction,
    "currentRecordStateChangeFlag: ",
    currentRecordStateChangeFlag
  );

  if (useStore.getState().ongoingUndoRedoActionsRecordings.length === 0) {
    return;
  }

  if (!trackedAction) logger.error("No tracked action name provided");

  return await useStore
    .getState()
    .setRecordStateChange("STOP_AND_DISCARD", trackedAction);
}
