import axios from "axios";
import { perform } from "./ApiClient";
import { downloadURLResponse } from "./Responses";
import useStore from "../Store/Store";

export async function getUser(user) {
  return await perform("get", `/auth/login/success`, null, user);
}

export async function getRepos() {
  const data = {};
  return await perform("post", `/getReposList`, data);
}

export async function getDefaultBranch(repo, owner) {
  const data = {
    repo: repo,
    owner: owner,
  };
  return await perform("post", `/getdefaultbranch`, data);
}

export async function getBranches(repo, owner) {
  const data = {
    repo: repo,
    owner: owner,
  };
  return await perform("post", `/getbranches`, data);
}

export async function saveFileToGithub(repo, branch, content, owner, path) {
  const data = {
    repo: repo,
    branch: branch,
    content: content,
    owner: owner,
    path: path,
  };

  return await perform("put", `/save`, data);
}

export async function deleteDiagramFile(repo, branch, owner) {
  const data = {
    repo: repo,
    branch: branch,
    owner: owner,
  };

  return await perform("put", `/deletediagramfile`, data);
}

export async function getRepoData(repo, branch, owner = "sameUser") {
  const data = {
    owner: owner && owner !== "sameUser" ? owner : "sameUser",
    repo: repo,
    branch: branch,
    isLoggedIn: useStore.getState().session.isLoggedIn,
  };
  return await perform("post", `/getrepodata`, data);
}

export async function invalidateToken() {
  const result = await perform("get", `/auth/logout`, null);
  return result;
}

export async function getFileDownloadURL(url, path, branch) {
  const data = {
    url: url,
    path: path,
    branch: branch,
  };
  return await perform("post", `/getFileDownloadURL`, data);
}

export async function getCheckRunResult(repo, owner, checkRunId) {
  const data = {
    repo: repo,
    owner: owner,
    checkRunId: checkRunId,
  };
  return await perform("post", `/getCheckRunResult`, data);
}

export async function getFileContentFromGithub(url, path, branch) {
  const data = {
    url: url,
    path: path,
    branch: branch,
  };
  let fileDownloadURLResponse: downloadURLResponse = await perform(
    "post",
    `/getFileDownloadURL`,
    data
  );
  const downloadURL = fileDownloadURLResponse.download_url;
  return await axios
    .get(downloadURL)
    .then(function (response) {
      let fileContent = response?.data;
      if (!fileContent || fileContent.length === 0) {
        throw new Error("Could not retrieve file content!");
      }
      return fileContent;
    })
    .catch(function (error) {
      const setErrorNotification = useStore.getState().setErrorNotification;
      setErrorNotification(`Error loading file at ${path} `);
      console.error("Unable to load file content. Error: ", error);
    });
}

async function handleBadCredentialsResponse() {
  // redirect to login page if the token is invalid
  if (sessionStorage.getItem("loginAlreadyReAttempted") === "true") {
    useStore.getState().setLoggedIn(false);
    useStore.getState().setSessionMode("unauthenticatedGithub");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("username");
    useStore
      .getState()
      .setErrorNotification(
        "Unable to use access token to authenticate. Please login again."
      );
    sessionStorage.removeItem("loginAlreadyReAttempted");
    throw new Error("Bad credentials");
  }
  localStorage.removeItem("accessToken");
  localStorage.removeItem("username");
  sessionStorage.setItem("redirectUrl", window.location.href);
  sessionStorage.setItem("loginAlreadyReAttempted", "true");
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  window.location.assign(`${REACT_APP_BACKEND_URL}/auth/github`);
  throw new Error("Bad credentials");
}

async function handleRepoNotFound() {
  const serverErrorMessage =
  "Unable to find the repo, check repo/branch name and if you have permission to access this repo.";
  useStore.getState().setErrorNotification(serverErrorMessage);
}

export async function handleServerResponseError(res: any) {
  res = await res.json();
  if (res.errorCode === "GITHUB_BAD_CREDENTIALS") {
    await handleBadCredentialsResponse();
  } 
  
  if (res.errorCode === "REPO_NOT_FOUND") {
    await handleRepoNotFound();
  } 

  else {
    const serverErrorMessage =
      "Server returned an error: It could be that your Github authentication failed! Try signing in to Github or load from local.";
    useStore.getState().setErrorNotification(serverErrorMessage);
    throw new Error(res);
  }
}
