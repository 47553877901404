import { useState, useEffect } from "react";

import { TabPanel } from "../../TabPanel";
import { RepoFile } from "./RepoFile/RepoFile";
import useStore from "../../../../Store/Store";
import { getParentPath } from "../../../../utils/stringHelper";
import "./RepoTab.css";
import { getFileContent } from "../../SourceDocHandlers";

interface IRepoTab {
  isMaxSD: boolean;
}

export const RepoTab: React.FC<IRepoTab> = (props: IRepoTab) => {
  const { isMaxSD } = props;
  const [repoTabContent, setRepoTabContent] = useState([]);

  const {
    repoData,
    currentPath,
    currentRepo,
    currentBranch,
    setFileContent,
    selectedTab,
    openFolderOnDoubleClickFlag,
    setOpenFolderOnDoubleClickFlag,
    showCellToFolderParentPath,
    setShowCellToFolderParentPath,
    searchResults,
  } = useStore((state) => ({
    repoData: state.repoData,
    currentPath: state.currentPath,
    currentRepo: state.currentRepo,
    currentBranch: state.currentBranch,
    setFileContent: state.setFileContent,
    selectedTab: state.selectedTab,
    openFolderOnDoubleClickFlag: state.openFolderOnDoubleClickFlag,
    setOpenFolderOnDoubleClickFlag: state.setOpenFolderOnDoubleClickFlag,
    showCellToFolderParentPath: state.showCellToFolderParentPath,
    setShowCellToFolderParentPath: state.setShowCellToFolderParentPath,
    searchResults: state.searchResults,
  }));

  // repoTab content is only dependent on the current Path
  useEffect(() => {
    if (searchResults) {
      let content = searchResults;
      let currentRepoData = repoData[currentPath];
      if (
        (!("fileContent" in currentRepoData) ||
          !currentRepoData?.fileContent) &&
        (currentRepoData.startLine || currentRepoData.type === "blob")
      ) {
        getFileContent();
      }
      setRepoTabContent(
        content.map((result: any, index: number) => {
          return (
            <RepoFile key={index} path={result?.path} isSearchResult={true} />
          );
        })
      );
    } else if (currentPath) {
      var content = null;
      let currentRepoData = repoData[currentPath];
      if (currentRepoData?.parentPath) {
        currentRepoData = repoData[currentRepoData.parentPath];
      }
      if (currentRepoData?.type === "tree") {
        if (
          openFolderOnDoubleClickFlag ||
          currentRepoData?.path === currentRepo
        ) {
          content = currentRepoData?.contents;
          if (content) {
            setRepoTabContent(
              content.map((path: string, index: number) => {
                return <RepoFile key={index} path={path} />;
              })
            );
          }
          setOpenFolderOnDoubleClickFlag(false);
        }
        // this will highlight files instead of opening their content
        // when their cell is selected
        else if (showCellToFolderParentPath) {
          let parentPath = getParentPath(currentRepoData.path) || currentRepo;
          content = repoData[parentPath]?.contents;
          if (content) {
            setRepoTabContent(
              content.map((path: string, index: number) => {
                return <RepoFile key={index} path={path} />;
              })
            );
          }
          setShowCellToFolderParentPath(false);
        }
      } else if (currentRepoData?.type === "blob") {
        let parentPath = getParentPath(currentRepoData.path) || currentRepo;
        content = repoData[parentPath]?.contents;
        if (content) {
          setRepoTabContent(
            content.map((path: string, index: number) => {
              return <RepoFile key={index} path={path} />;
            })
          );
        }

        if (
          !("fileContent" in currentRepoData) ||
          !currentRepoData?.fileContent
        ) {
          getFileContent();
        }
      }
    }
  }, [
    openFolderOnDoubleClickFlag,
    searchResults,
    currentRepo,
    repoData,
    currentPath,
    currentBranch,
    showCellToFolderParentPath,
    setShowCellToFolderParentPath,
    setFileContent,
    setOpenFolderOnDoubleClickFlag,
    setFileContent,
  ]);

  return (
    <TabPanel value={selectedTab} index={0}>
      <div
        className="repoContainer"
        style={{ height: isMaxSD ? "83%" : "87%" }}
      >
        {repoTabContent}
      </div>
    </TabPanel>
  );
};
