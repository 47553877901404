import { DrawioEditorProviderText } from "./DrawioEditorProviderText";
import { DrawioEditorService } from "./DrawioEditorService";
import { DrawioClientFactory } from "./DrawioClientFactory";
import { Config } from "./Config";
import { LinkCodeWithSelectedNodeService } from "../Features/CodeLinkFeature";

export class DrawioInterface {
  private readonly config = new Config();
  private readonly drawioClientFactory = new DrawioClientFactory(this.config);
  public editorService = new DrawioEditorService(
    this.config,
    this.drawioClientFactory
  );
  editorProviderText;

  constructor(iFrameRef: any) {
    this.editorProviderText = new DrawioEditorProviderText(
      this.editorService,
      iFrameRef
    );
  }
  public linkCodeWithSelectedNodeService = new LinkCodeWithSelectedNodeService(
    this.editorService,
    this.config
  );
}
