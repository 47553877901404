import { useCallback, useEffect, useState } from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DatasetLinkedOutlinedIcon from "@mui/icons-material/DatasetLinkedOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LinkIcon from "@mui/icons-material/Link";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CodeIcon from "@mui/icons-material/Code";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FolderIcon from "@mui/icons-material/Folder";
import useStore from "../../../../../Store/Store";
import {
  linkFileToNodeHandler,
  newCellToDrawioHandler,
  toggleSingleCellVisibility,
  unlinkCell,
} from "../../../../../Store/StoreHandlers";
import { Tooltip } from "@mui/material";

import "./RepoFile.css";
import {
  saveRecordedActions,
  startRecordingActions,
} from "../../../../../Store/UndoManager";

interface IRepoFile {
  path: string;
  isSearchResult?: boolean;
}

export const RepoFile: React.FC<IRepoFile> = (props: IRepoFile) => {
  const {
    currentPath,
    setCurrentPath,
    setSelectedTab,
    repoData,
    selectedCell,
    cellToPath,
    setDialog,
    setOpenFolderOnDoubleClickFlag,
    setSearchResults,
    currentRepo,
    searchResults,
  } = useStore((state) => ({
    currentPath: state.currentPath,
    cellToPath: state.cellToPath,
    setCurrentPath: state.setCurrentPath,
    setSelectedTab: state.setSelectedTab,
    repoData: state.repoData,
    selectedCell: state.selectedCell,
    setDialog: state.setDialog,
    setOpenFolderOnDoubleClickFlag: state.setOpenFolderOnDoubleClickFlag,
    setSearchResults: state.setSearchResults,
    currentRepo: state.currentRepo,
    searchResults: state.searchResults,
  }));

  const [file, setFile] = useState({} as any);
  const [cellId, setCellId] = useState("");

  const addCellHandler = useCallback(
    async (file) => {
      let repoItemData = repoData[file.path];

      // selectedCell either key to cellData or
      // must get linked path from cellToPath
      let cellItemData =
        repoData[selectedCell] || repoData[cellToPath[selectedCell]];

      // TODO: currently allowing parent file to be added in retro,
      // later will add parent file and child at same time so
      // child cell cannot exist without parent already existing
      if (repoData[selectedCell]?.parentPath === file.path) {
        await newCellToDrawioHandler(file.fileName);
      }

      // if cell is linked
      else if (
        selectedCell in cellToPath ||
        (repoData[selectedCell]?.cellId && repoData[selectedCell]?.parentPath)
      ) {
        const dialogMessage = `The selected cell: "${
          cellItemData.cellId
        }" is already 
          linked to the file: "${
            cellItemData.fileName || "Unititled Cell"
          }", please unselect the cell or select 
          an un-linked cell to create a cell linked to file: "${
            repoItemData.fileName
          }"`;
        setDialog("WARNING_DIALOG", {
          message: dialogMessage,
        });
      }

      // if file is linked
      else if (file.cellId) {
        const dialogMessage = `The selected file: "${file.fileName}" is already 
          linked to the cell: "${file.cellId}", please select a new file 
          to link to cell: "${cellItemData.fileName}"`;
        setDialog("WARNING_DIALOG", {
          message: dialogMessage,
        });
      }

      // both items exist in repoData so must conglomerate data
      // but cell is not linked to a file
      // ask to over write cell with repoItem data
      else if (selectedCell && !(selectedCell in cellToPath)) {
        if (repoItemData?.wiki?.length && cellItemData?.wiki?.length) {
          const dialogMessage = `There is wiki data in both artifacts!
             Choose which artifact to overwrite.`;
          setDialog("OVERWRITE_DIALOG", {
            message: dialogMessage,
          });
        }
        // overwrite repoData item if cell has data automatically
        else if (cellItemData?.wiki) {
          linkFileToNodeHandler(file.path);
        }
        // if both do not have wiki, then overwrite cell item.
        else {
          linkFileToNodeHandler(selectedCell);
        }
      }

      // if no selection, spawn new cell to canvas
      else if (!selectedCell) {
        await newCellToDrawioHandler(file.fileName);
      }
    },
    [selectedCell, cellToPath, repoData, setDialog]
  );

  const addButtonClickHandler = async () => {
    await startRecordingActions("ADD_FILE_NODE", {
      supressWarning: true,
    });
    await setCurrentPath(file.path, true);
    await addCellHandler(file);
    // If there are no open dialogs, save as an undoable event
    if (!useStore.getState().dialog.type) {
      if (useStore.getState().pausePathChangeToDrawio) {
        await useStore.getState().setPausePathChangeToDrawio(false);
      }
      await saveRecordedActions("ADD_FILE_NODE");
    }
  };

  const docsIconClickHandler = (file) => {
    let destPath = "";
    if (file.wiki) {
      destPath = file.path;
    } else {
      destPath = file?.children.find((child) => repoData[child].wiki);
    }
    setCurrentPath(destPath);
    setSelectedTab(2);
  };

  useEffect(() => {
    setFile(repoData[props.path]);
    setCellId(repoData[props.path]?.cellId ? repoData[props.path].cellId : "");
  }, [repoData, props.path]);
  // if path is invalid in repoData or
  // path is for root level, dont return anything
  if (!file || file.path === currentRepo) return;
  var fileName = file.fileName;
  var displayClass = "";
  var fileIcon = null;

  // left-aligned icon selection logic
  if (file.cellId === file.path && !file.startLine) {
    fileIcon = <DashboardIcon fontSize="small" />;
  } else if (file.type !== "tree") {
    fileIcon = <CodeIcon fontSize="small" />;
  } else {
    fileName = "/" + fileName;
    fileIcon = <FolderIcon fontSize="small" />;
  }

  // shade selector - is cell connected to repo data?
  if (!file.children && !(file.cellId in cellToPath) && !file.startLine) {
    displayClass = "unlinked";
  } else {
    displayClass = "linked";
  }

  // openArtifact must exist to match names
  var selected =
    currentPath === file.path ||
    (!searchResults && repoData[currentPath]?.parentPath === file.path)
      ? "selectedFile"
      : "";

  function fileClickHandler(e, file) {
    // want this to register if click on anything
    // except the plus button

    // e is null on inside clickHandler so its children
    // can be clicked for same affect
    if (!e || e.target === e.currentTarget) {
      if (searchResults) {
        toggleSingleCellVisibility(file.cellId, true);
      }
      setCurrentPath(file.path);
    }
  }

  function fileDoubleClickHandler(file) {
    if (file.visible) {
      if (searchResults) {
        toggleSingleCellVisibility(file.cellId, true);
      }
      setOpenFolderOnDoubleClickFlag(true);
      setCurrentPath(file.path);
      setSearchResults(null);
      if (file.type === "blob" || file.startLine) {
        setSelectedTab(1);
      }
    } else {
      if (file.type === "blob" || file.startLine) {
        setSelectedTab(1);
      }
    }
  }

  const hasWiki = () => {
    const hasChildWiki = file.children?.some((child) => repoData[child]?.wiki);
    const hasFileWiki = !!file.wiki;

    return hasChildWiki || hasFileWiki;
  };

  const linkedIconClickHandler = async () => {
    await startRecordingActions("UNLINK", {
      supressWarning: true,
    });
    if (file?.wiki) {
      let message = `There is a wiki in this cell-code connection. Please select to save the wiki in the linked file or in the cell!`;
      setDialog("UNLINK_DIALOG", {
        message: message,
        data: {
          cellIdentifier: file?.cellId,
        },
      });
    } else {
      await unlinkCell(file.path, null);
    }
  };

  return file ? (
    <div className={`SourceDocFile ${displayClass} ${selected}`}>
      <div
        onClick={(e) => fileClickHandler(e, file)}
        onDoubleClick={() => fileDoubleClickHandler(file)}
        className="SourceDocFileInternalWrapper"
      >
        <div
          className="SourceDocFileInternalContent"
          onClick={() => fileClickHandler(null, file)}
        >
          <div className="iconWrapper">{fileIcon}</div>
          <span className="SourceDocFileInternalText">{fileName}</span>
          {props?.isSearchResult && (
            <span className="SourceDocFileInternalTextSub">
              {file.cellName || file.cellId}
            </span>
          )}
        </div>
      </div>

      {!file?.visible && (
        <Tooltip title="Cell hidden outside simulation">
          <div className="linkedIconWrapper">
            <VisibilityOffIcon style={{ width: "100%" }} fontSize="small" />
          </div>
        </Tooltip>
      )}

      {(file?.children || file?.startline) && (
        <Tooltip title="Code is linked to this cell or child cell!">
          <div className="linkedIconWrapper">
            <DatasetLinkedOutlinedIcon style={{ width: "80%" }} />
          </div>
        </Tooltip>
      )}

      {hasWiki() && (
        <Tooltip
          title="Contains atleast one wiki! Click to open."
          onClick={() => {
            docsIconClickHandler(file);
          }}
        >
          <div
            className="linkedIconWrapper hoverEffect"
            onDoubleClick={() => fileDoubleClickHandler(file)}
          >
            <ArticleOutlinedIcon style={{ width: "80%" }} />
          </div>
        </Tooltip>
      )}

      {cellId && (
        <Tooltip title="Linked to diagram node! Click to unlink.">
          <div
            className="linkedIconWrapper hoverEffect"
            onClick={() => linkedIconClickHandler()}
          >
            <LinkIcon style={{ width: "80%" }} />
          </div>
        </Tooltip>
      )}

      {!cellId && (
        <div className="iconWrapper hoverEffect">
          <Tooltip title="Add cell linked to this file!">
            <AddCircleRoundedIcon
              fontSize="small"
              onClick={async () => {
                addButtonClickHandler();
              }}
            />
          </Tooltip>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
