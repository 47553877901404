import { Dialog as MUIDialog, DialogContent, Typography } from "@mui/material";
import useStore from "../../Store/Store";
import { theme } from "../../Themes/Themes";
import "./Dialog.css";
import { unlinkCell } from "../../Store/StoreHandlers";
import { discardRecordedActions } from "../../Store/UndoManager";

export default function UnlinkDialog() {
  const { dialog, setDialog } = useStore((state) => ({
    dialog: state.dialog,
    setDialog: state.setDialog,
    currentRepo: state.currentRepo,
    setCurrentPath: state.setCurrentPath,
    setDeleteMode: state.setDeleteMode,
  }));

  async function handleUnlinkCells(wikiDestination: "cell" | "file") {
    try {
      await unlinkCell(dialog?.data?.data?.cellIdentifier, wikiDestination);
    } catch (error) {
      console.error(error);
      await discardRecordedActions("UNLINK");
    }
    closeDialog();
  }

  async function closeDialog() {
    if (dialog.data?.button2Function) {
      await dialog.data.button2Function();
    }
    setDialog("");
  }

  async function handleCancel() {
    closeDialog();
    await discardRecordedActions("UNLINK");
  }

  return (
    <MUIDialog
      open={dialog.type === "UNLINK_DIALOG" && dialog.data !== null}
      sx={{
        "& .MuiPaper-root": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          borderRadius: "30px",
          width: "50vw",
        },
      }}
      onClose={closeDialog}
    >
      <DialogContent className="DialogContent">
        <br />
        <Typography
          textAlign={"center"}
          mx={3}
          my={0.8}
          fontSize="18px"
          fontWeight="Light"
          color={theme.custom.darkerGrey}
          sx={{ paddingBottom: "10px" }}
        >
          {dialog?.data?.message}
        </Typography>
        <div
          className="DialogButtonGroupVertical"
          style={{ paddingBottom: "10px" }}
        >
          <div
            className="Button FullDialogButton"
            onClick={() => handleUnlinkCells("file")}
          >
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontWeight="Normal"
              color="#FFAEA6"
            >
              Keep wiki in linked file!
            </Typography>
          </div>

          <div
            className="Button FullDialogButton"
            onClick={() => handleUnlinkCells("cell")}
          >
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontWeight="Normal"
              color="#FFAEA6"
            >
              Keep wiki in cell data!
            </Typography>
          </div>

          <div className="Button FullDialogButton" onClick={handleCancel}>
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontWeight="Normal"
              color="#FFAEA6"
            >
              Cancel
            </Typography>
          </div>
        </div>
      </DialogContent>
    </MUIDialog>
  );
}
