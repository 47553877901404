import { getRepoData } from "../Api/ServerInterface";
import useStore from "../Store/Store";
/**
 * ON SAVE:
 *
 * Filters data required for save out of repoData
 *
 * returns lite RepoData object for frugal saving {path: wiki}
 */
export async function buildRepoDataSnapshot() {
  const repoData = useStore.getState().repoData;
  let liteRepoData = {};
  for (let path in repoData) {
    let item = repoData[path];
    if (item?.wiki || item?.cellId || item?.children) {
      liteRepoData[path] = {
        path: item?.path,
      };

      if (item?.fileName) {
        liteRepoData[path].fileName = item?.fileName;
      }

      if (item?.wiki) {
        liteRepoData[path].wiki = item?.wiki;
      }
      if (item?.cellName) {
        liteRepoData[path].cellName = item?.cellName;
      }

      if (item?.cellId) {
        liteRepoData[path].cellId = item?.cellId;
      }

      if ("visible" in item) {
        liteRepoData[path].visible = item.visible;
      }
      if (item?.startLine) {
        liteRepoData[path].startLine = item?.startLine;
      }

      if (item?.endLine) {
        liteRepoData[path].endLine = item?.endLine;
      }

      if (item?.parentCellId) {
        liteRepoData[path].parentCellId = item?.parentCellId;
      }

      if (item?.children) {
        liteRepoData[path].children = item?.children;
      }

      if (item?.parentPath) {
        liteRepoData[path].parentPath = item?.parentPath;
      }
    }
  }
  return liteRepoData;
}

/**
 * ON Load:
 *
 * takes data from savedContent and pushes into repoData
 *
 * returns lite RepoData object for frugal saving {path: wiki}
 */

export function mergeSavedDataAndRepoData(savedData, repoData) {
  let mergedRepoData = { ...repoData };
  for (let path in savedData) {
    let item = savedData[path];
    mergedRepoData[path] = {
      ...repoData[path],
      path: item?.path,
    };

    if (item?.fileName) {
      mergedRepoData[path].fileName = item?.fileName;
    }

    if (item?.wiki) {
      mergedRepoData[path].wiki = item?.wiki;
    }

    if (item?.cellName) {
      mergedRepoData[path].cellName = item?.cellName;
    }

    if (item?.cellId) {
      mergedRepoData[path].cellId = item?.cellId;
    }

    if ("visible" in item) {
      mergedRepoData[path].visible = item.visible;
    }
    if (item?.startLine) {
      mergedRepoData[path].startLine = item?.startLine;
    }

    if (item?.endLine) {
      mergedRepoData[path].endLine = item?.endLine;
    }

    if (item?.children) {
      mergedRepoData[path].children = item?.children;
    }

    if (item?.parentCellId) {
      mergedRepoData[path].parentCellId = item?.parentCellId;
    }

    if (item?.parentPath) {
      mergedRepoData[path].parentPath = item?.parentPath;
    }
  }
  return mergedRepoData;
}

export function getRepoDataFromGitHub() {
  const currentRepo = useStore.getState().currentRepo;
  const currentBranch = useStore.getState().currentBranch;
  const currentRepoMetadata = useStore.getState().currentRepoMetadata;
  const setRepoData = useStore.getState().setRepoData;
  const setErrorNotification = useStore.getState().setErrorNotification;
  const setIsRetrievingRepoData = useStore.getState().setIsRetrievingRepoData;
  const setSessionMode = useStore.getState().setSessionMode;
  setIsRetrievingRepoData(true, false);
  getRepoData(currentRepo, currentBranch, currentRepoMetadata.owner)
    .then((response) => {
      setRepoData(response);
      if (!useStore.getState().session.isLoggedIn) {
        setSessionMode("unauthenticatedGithub");
        useStore.getState().setShowLandingComponent(false);
      }
    })
    .catch((e) => {
      const notificationMessage = `Error loading ${currentRepo} repository content`;
      setErrorNotification(notificationMessage);
      console.error(e);
    })
    .finally(() => setIsRetrievingRepoData(false));
}

export function sortRepoDataContent(repoData) {
  // deep copy repoData
  let sortedRepoData = JSON.parse(JSON.stringify(repoData));
  for (let path in sortedRepoData) {
    let content = sortedRepoData[path].contents;
    if (!content) {
      continue;
    }
    // sort first based on type (i.e. put all files with type tree first), then alphabetically
    content.sort((a, b) => {
      if (sortedRepoData[a].type === sortedRepoData[b].type) {
        return a.localeCompare(b);
      } else if (sortedRepoData[a].type === "tree") {
        return -1;
      } else {
        return 1;
      }
    });
  }
  return sortedRepoData;
}

// Backfill repoData with missing attributes, like visible flag in repoDataObject, this function is used to keep legacy .CodeCanvas files working
export function backfillRepoDataContent(repoData) {
  // deep copy repoData
  let backfilledRepoData = JSON.parse(JSON.stringify(repoData));
  for (let path in backfilledRepoData) {
    let repoDataEntryObject = backfilledRepoData[path];
    if (!("visible" in repoDataEntryObject)) {
      repoDataEntryObject["visible"] = true;
    }
    backfilledRepoData[path] = repoDataEntryObject;
  }
  return backfilledRepoData;
}
