import useStore from "../Store/Store";
import { getURLParam } from "./URLUtils";

export const setupTestMode = (window) => {
  const localStorageTestMode = localStorage.getItem("testMode");
  const localStorageAccessToken = localStorage.getItem("accessToken");
  const localStorageUsername = localStorage.getItem("username");
  console.log("localStorageTestMode", localStorageTestMode);
  console.log("localStorageAccessToken", localStorageAccessToken);
  console.log("localStorageUsername", localStorageUsername);
  if (
    localStorageAccessToken &&
    localStorageTestMode === "1" &&
    localStorageUsername
  ) {
    useStore.getState().setUsername(localStorageUsername);
    useStore.getState().setLoggedIn(true);
    return true;
  }
  return false;
};

export const simulateTestMode = (window) => {
  const testModeFlag = "1"; // Define the flag as a variable with a key name
  const username = "CodeCanvasCanaries";
  const accessToken = getURLParam(window, "testToken");
  // Inject accessToken and testMode into local storage before login
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("testMode", testModeFlag);
  localStorage.setItem("username", username);
};
