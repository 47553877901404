import "./StatusBar.css";
import { Tooltip } from "@mui/material";
import PublishedWithChangesRoundedIcon from "@mui/icons-material/PublishedWithChangesRounded";
import SyncProblemRoundedIcon from "@mui/icons-material/SyncProblemRounded";
import useStore from "../../../Store/Store";
import { useEffect, useState } from "react";

export default function StatusBar() {
  const {
    sessionMode,
    currentBranch,
    isDiagramModified,
    isWikiModified,
    isNewDiagramFileCreated,
    isSimulationsModified,
    isCellToPathModified,
  } = useStore((state) => ({
    sessionMode: state.session.mode,
    currentBranch: state.currentBranch,
    isDiagramModified: state.fileStatus.isDiagramModified,
    isWikiModified: state.fileStatus.isWikiModified,
    isNewDiagramFileCreated: state.isNewDiagramFileCreated,
    isSimulationsModified: state.fileStatus.isSimulationsModified,
    isCellToPathModified: state.fileStatus.isCellToPathModified,
  }));
  const [tooltipText, setTooltipText] = useState(<div />);

  // Update tooltip message based on whether the diagram or wiki are modified or not
  useEffect(() => {
    let diagramStatusMessage = "";
    if (isDiagramModified || isCellToPathModified) {
      if (isNewDiagramFileCreated) {
        diagramStatusMessage = `🔴    Diagram Status: Your new CodeCanvas file isn't saved yet`;
      } else {
        diagramStatusMessage = `🔴    Diagram Status: Modified `;
      }
    } else {
      const repoLocation =
        sessionMode === "github" || sessionMode === "unauthenticatedGithub"
          ? "remote"
          : "local";
      diagramStatusMessage = `🟢    Diagram Status: In-Sync With ${repoLocation} Repo`;
    }

    let wikiStatusMessage = "";
    if (isNewDiagramFileCreated) {
      wikiStatusMessage = `🔴    Wiki Status: Your new CodeCanvas file isn't saved yet`;
    } else {
      if (isWikiModified) {
        wikiStatusMessage = `🔴    Wiki Status: Modified`;
      } else {
        const repoLocation =
          sessionMode === "github" || sessionMode === "unauthenticatedGithub"
            ? "remote"
            : "local";
        wikiStatusMessage = `🟢    Wiki Status: In-Sync With ${repoLocation} Repo`;
      }
    }

    let simulationsStatusMessage = "";
    if (isNewDiagramFileCreated) {
      simulationsStatusMessage = `🔴    Simulations Status: Your new CodeCanvas file isn't saved yet`;
    } else if (isSimulationsModified) {
      simulationsStatusMessage = `🔴    Simulations Status: Modified `;
    } else {
      const repoLocation =
        sessionMode === "github" || sessionMode === "unauthenticatedGithub"
          ? "remote"
          : "local";
      simulationsStatusMessage = `🟢    Simulations Status: In-Sync With ${repoLocation} Repo`;
    }

    let DownloadFileToCheckDiffMessage: React.FC = () => {
      if (
        isCellToPathModified ||
        isDiagramModified ||
        isWikiModified ||
        isSimulationsModified
      ) {
        return (
          <div>
            Save changes from File menu.
            <br />
            {!isNewDiagramFileCreated &&
              `To view changes: Save file locally from the file menu to check diff in IDE.
`}
            <br /> <br />
          </div>
        );
      } else {
        return <></>;
      }
    };

    setTooltipText(
      <div>
        <br />
        {diagramStatusMessage}
        <br />
        <br />
        {wikiStatusMessage}
        <br />
        <br />
        {simulationsStatusMessage}
        <br />
        <br />
        <DownloadFileToCheckDiffMessage />
      </div>
    );
  }, [
    sessionMode,
    isDiagramModified,
    isWikiModified,
    isSimulationsModified,
    isCellToPathModified,
    isNewDiagramFileCreated,
  ]);

  return (
    currentBranch && (
      <Tooltip title={tooltipText} id={"StatusBarIcon"}>
        <div>
          {isCellToPathModified ||
          isDiagramModified ||
          isWikiModified ||
          isSimulationsModified ? (
            <SyncProblemRoundedIcon
              id="SyncProblemRoundedIcon"
              sx={{ m: 1, width: "22px" }}
              color="primary"
            />
          ) : (
            <PublishedWithChangesRoundedIcon
              id="PublishedWithChangesRoundedIcon"
              sx={{ m: 1, width: "22px" }}
              color="primary"
            />
          )}
        </div>
      </Tooltip>
    )
  );
}
