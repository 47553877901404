import { theme } from "../../Themes/Themes";
import searchIcon from "../../Media/SearchBarIcon.svg";
import "./SearchBar.css";
import useStore from "../../Store/Store";
import "./SearchBar.css";
import { useCallback, useEffect, useState } from "react";
import Clear from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

export function handleSearchBarFocus(drawioEvt) {
  // select the search bar
  // const searchInput: HTMLInputElement = document.querySelector(".search-input");
  // if (searchInput) {
  //   searchInput.focus();
  // }
  useStore.getState().postToDrawio({
    drawioRequestId: drawioEvt.drawioRequestId,
    action: "SEARCH_BAR_FOCUS_RESPONSE",
    status: "SUCCESS",
  });
}

export const SearchBar: React.FC = () => {
  const {
    fuse,
    setSearchResults,
    setCurrentPath,
    currentRepo,
    setSelectedTab,
    searchResults,
  } = useStore((state) => ({
    fuse: state.fuse,
    setSearchResults: state.setSearchResults,
    setCurrentPath: state.setCurrentPath,
    currentRepo: state.currentRepo,
    setSelectedTab: state.setSelectedTab,
    currentPath: state.currentPath,
    searchResults: state.searchResults,
  }));

  const [searchValue, setSearchValue] = useState("");

  const searchHandler = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  function handleClearSearch() {
    setSearchValue("");
  }

  const renderSearchResults = useCallback(() => {
    setCurrentPath(currentRepo);
    var results = fuse.search(searchValue, { limit: 15 });
    var newResults = results.map((result) => result.item);
    setSearchResults(newResults);
    setSelectedTab(0);
  }, [
    searchValue,
    setSearchResults,
    fuse,
    setSelectedTab,
    currentRepo,
    setCurrentPath,
  ]);

  useEffect(() => {
    if (!searchResults) {
      setSearchValue("");
    }
  }, [searchResults]);

  useEffect(() => {
    if (!searchValue?.length) {
      setSearchResults(null);
    } else if (fuse && searchValue) {
      renderSearchResults();
    }
  }, [
    searchValue,
    setSelectedTab,
    setCurrentPath,
    setSearchResults,
    currentRepo,
    fuse,
    renderSearchResults,
  ]);

  return (
    <div className="search-bar-wrapper">
      <div className="search-bar">
        <img alt="Search Icon" className="search-icon" src={searchIcon} />
        <input
          className="search-input"
          placeholder="Search Repo Content"
          onChange={(e) => searchHandler(e.target.value)}
          value={searchValue}
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              renderSearchResults();
            }
          }}
          style={{
            fontSize: "14px",
            outline: "none",
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.custom.darkestGrey,
          }}
        ></input>
      </div>
      {searchValue && (
        <div className="clearSearchIconWrapper">
          <IconButton
            edge="end"
            onClick={handleClearSearch}
            aria-label="clear search"
          >
            <Clear fontSize="small" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
