import SourceDocButtonSVG from "../../Media/SourceDocButton";

const SourceDocButton: React.FC <any> = ({setIsOpenSD}) => {
	return (
		<div
		  className="SourceDocButtonWrapper"
		  onClick={() => {setIsOpenSD((prevIsOpenSD) => !prevIsOpenSD); }}
		>
		  <SourceDocButtonSVG />
		</div>
	)
}

export default SourceDocButton;

