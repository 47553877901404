import produce from "immer";
import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { DrawioInterface } from "../DrawioClient/DrawioInterface";
import { CustomizedDrawioClient } from "../DrawioClient/CustomizedDrawioClient";
import {
  currentPathChangeHandler,
  setCellTags,
  toggleSingleCellVisibility,
} from "./StoreHandlers";
import { updateRepoDataInCodeCanvasIndexedDB } from "../utils/CodeCanvasIndexedDB";
import Fuse from "fuse.js";
import {
  compareNewWikiToSavedWiki,
  compareRefSimulationsToCached,
  compareRefCellToPathToCached,
  handleModifiedWikis,
} from "../Components/Header/StatusBar/StatusBarUtils";
import FuseOptions from "../Components/SearchBar/FuseOptions";
import {
  backfillRepoDataContent,
  sortRepoDataContent,
} from "../utils/RepoDataAction";
import { INITIAL_MODIFIED_SIMULATION_STATE } from "../Components/SourceDoc/TabViews/SimulationsTab/SimulationsTab";
import { SnackbarKey } from "notistack";
import { toggleCellsVisibility } from "../Components/SourceDoc/TabViews/SimulationsTab/SimulationUtils";
import sendGTMStateChangeEvent, {
  manageGoogleAnalyticsConsent,
} from "../utils/GTMHelper";
import { anonymize } from "../utils/AnonymizationHelper";
import { IRecentlyOpenedEntry } from "../Components/LandingPage/RecentFilesDropdown";
import {
  handleRecordStateChange,
  saveRecordedActions,
  startRecordingActions,
} from "./UndoManager";

const initialState: Store = {
  anonymizedMetadata: {
    username: "",
    currentRepo: "",
    currentBranch: "",
  },
  session: {
    mode: "unauthenticatedGithub",
    user: {
      username: "",
    },
    isLoggedIn: false,
    local: {
      diagramFile: {
        handle: null,
        fileName: null,
        fileXML: "",
      },
      directory: {
        handle: null,
      },
      hasFSAccess:
        "chooseFileSystemEntries" in window || "showOpenFilePicker" in window,
      isMac: navigator.userAgent.includes("Mac OS X"),
    },
  },
  debugMode: false,
  startDemo: false,
  drawioInitCompleted: false,
  URLParamParsingDone: false,
  showLandingComponent: false,
  lastDrawioEventName: "",
  landingPageLoaded: false,
  doLoadDiagram: false,
  isNewDiagramFileCreated: false,
  currentRepo: "",
  currentBranch: "",
  currentRepoMetadata: { owner: "" },
  currentPath: null,
  repoData: {},
  isRetrievingRepoData: false,
  isUserChoosingDirectory: false,
  reposList: [],
  selectedTab: 0,
  selectedCell: "",
  multiSelect: false,
  cellToPath: {}, // cellId: path
  branchesList: [],
  simulationsState: {
    simulations: {},
    isEditingSimulations: "",
    currentStep: "",
    selectedSimulationKey: "",
    cellsSetToCorrespondingVisiblityState: false,
    isRunningSimulationNotifId: null,
    cellsWithUnsavedVisibilityFlag: {},
  },
  currentUnsavedSimulationObject: INITIAL_MODIFIED_SIMULATION_STATE,
  unsavedSimulationsStateChanges: [],
  fileStatus: {
    isDiagramModified: false,
    isWikiModified: false,
    modifiedWikis: {},
    modifiedSimulations: { added: {}, removed: {}, modified: {} },
    isSimulationsModified: false,
    modifiedCellToPath: { added: {}, removed: {}, modified: {} },
    isCellToPathModified: false,
  },
  diagramData: {
    drawioXML: "",
    fileName: "",
    fileURL: "",
  },
  referenceDiagramFile: {
    drawioXML: "",
    fileName: "",
    fileURL: "",
    repoData: {},
    repo: "",
    branch: "",
    owner: "",
    wiki: {},
    simulations: {},
    cellToPath: {},
  },
  drawioInterface: null,
  drawioClient: null,
  notification: { loading: "", error: "", success: "" },
  dialog: { type: "", data: null },
  toolbar: { showEditButton: true },
  deleteMode: null,
  pausePathChangeToDrawio: false,
  openFolderOnDoubleClickFlag: false,
  showCellToFolderParentPath: false,
  fuse: null,
  searchResults: null,
  selectedText: "",
  recentlyOpenedList: [],
  flags: {
    isChangingPath: false,
    isHandlingSelectionChange: false,
    isAddingNewSimStep: false,
  },
  recordStateChange: "STOP_AND_DISCARD",
  isPerformingUndoRedo: false,
  ongoingUndoRedoActionsRecordings: [],
  undoRedoState: {
    undo: false,
    redo: false,
  },
  checkRunImpactedCells: [],
  videoTutorialSection: "",
};

const store = (set) => ({
  ...initialState,
  setLoggedIn: (isLoggedIn: boolean) =>
    set(
      produce((state: Store) => {
        state.session.isLoggedIn = isLoggedIn;
      })
    ),
  setDrawioInitCompleted: (initCompleted: boolean) =>
    set(
      produce((state: Store) => {
        state.drawioInitCompleted = initCompleted;
      })
    ),
  setLandingPageLoaded: (landingPageLoaded: boolean) =>
    set(
      produce((state: Store) => {
        state.landingPageLoaded = landingPageLoaded;
      })
    ),

  setDoLoadDiagram: (doLoadDiagram: boolean) =>
    set(
      produce((state: Store) => {
        state.doLoadDiagram = doLoadDiagram;
      })
    ),
  setUsername: (username: string) => {
    useStore
      .getState()
      .setAnonymizedMetadata({ username: anonymize(username) });
    return set(
      produce((state: Store) => {
        state.session.user.username = username;
      })
    );
  },
  setCurrentRepo: (repo: string, repoMetadata: RepoMetadata = null) => {
    useStore.getState().setAnonymizedMetadata({ currentRepo: anonymize(repo) });
    return set(
      produce((state: Store) => {
        state.currentRepo = repo;
        state.currentRepoMetadata = repoMetadata;
      })
    );
  },
  setCurrentBranch: (branch: string) => {
    useStore
      .getState()
      .setAnonymizedMetadata({ currentBranch: anonymize(branch) });
    return set(
      produce((state: Store) => {
        state.currentBranch = branch;
      })
    );
  },
  setRepoData: (repo: Object, doLoadDiagram = true) => {
    let sortedContent = sortRepoDataContent(repo);
    let backFilledContent = backfillRepoDataContent(sortedContent);
    useStore.getState().setIsRetrievingRepoData(false);
    const myFuse = new Fuse(
      Object.values(repo).filter(
        (item) => item.path !== useStore.getState().currentRepo
      ),
      FuseOptions
    );
    useStore.getState().setFuse(myFuse);

    const currentRepoData = useStore.getState().repoData;
    const newRepoData = backFilledContent;
    setCellTags(currentRepoData, newRepoData);
    set(
      produce((state: Store) => {
        state.repoData = backFilledContent;
        state.currentPath = `${state.currentRepo}`;
      })
    );
    if (doLoadDiagram) {
      useStore.getState().setDoLoadDiagram(true);
    }
  },
  setCellToPath: (cellToPath: ICellToPath) => {
    compareRefCellToPathToCached(cellToPath);
    set(
      produce((state: Store) => {
        state.cellToPath = cellToPath;
      })
    );
  },
  setIsRetrievingRepoData: (
    isRetrievingRepoData: boolean,
    showNotification: boolean = true
  ) => {
    useStore
      .getState()
      .setLoadingNotification(
        isRetrievingRepoData && showNotification
          ? "Retrieving repository content"
          : ""
      );
    set(
      produce((state: Store) => {
        state.isRetrievingRepoData = isRetrievingRepoData;
      })
    );
  },
  setSelectedTab: (newTab: number) => {
    return set(
      produce((state: Store) => {
        state.selectedTab = newTab;
      })
    );
  },
  setRepoObjectEntry: (path, data) => {
    const currentRepoData = useStore.getState().repoData[path]
      ? { [path]: useStore.getState().repoData[path] }
      : {};
    const newRepoData = { [path]: data };
    setCellTags(currentRepoData, newRepoData);
    set(
      produce((state: Store) => {
        state.repoData = {
          ...state.repoData,
          [path]: {
            ...state.repoData[path],
            ...data,
          },
        };
      })
    );
  },
  setNewRepoObject: (newPath, newObject) => {
    const currentRepoData = {};
    const newRepoData = { [newPath]: newObject };
    setCellTags(currentRepoData, newRepoData);
    set(
      produce((state: Store) => {
        state.repoData[newPath] = newObject;
      })
    );
  },
  deleteRepoObject: (path) => {
    const currentRepoData = { path: useStore.getState().repoData[path] };
    const newRepoData = {};
    setCellTags(currentRepoData, newRepoData);
    set(
      produce((state: Store) => {
        delete state.repoData[path];
      })
    );
  },
  setDeleteMode: (deleteMode: "saveWiki" | "deleteWiki") => {
    set(
      produce((state: Store) => {
        state.deleteMode = deleteMode;
      })
    );
  },
  setPausePathChangeToDrawio: (pausePath: boolean) => {
    set(
      produce((state: Store) => {
        state.pausePathChangeToDrawio = pausePath;
      })
    );
  },
  setRepoObjectAttribute: (path, attr, value) => {
    const currentRepoObject = { ...useStore.getState().repoData[path] };
    const currentRepoData = { [path]: currentRepoObject };
    const newRepoObject = { ...currentRepoObject, [attr]: value };
    const newRepoData = { [path]: newRepoObject };
    setCellTags(currentRepoData, newRepoData);
    set(
      produce((state: Store) => {
        state.repoData[path][attr] = value;
      })
    );
  },
  setRepoObjectAttributes: (path, repoObjectAttributes) => {
    const currentRepoObject = { ...useStore.getState().repoData[path] };
    const currentRepoData = { [path]: currentRepoObject };
    const newRepoObject = { ...currentRepoObject, ...repoObjectAttributes };
    const newRepoData = { [path]: newRepoObject };
    setCellTags(currentRepoData, newRepoData);
    set(
      produce((state: Store) => {
        state.repoData[path] = {
          ...state.repoData[path],
          ...repoObjectAttributes,
        };
      })
    );
  },

  deleteRepoObjectAttribute: (path, attr) => {
    const currentRepoObject = { ...useStore.getState().repoData[path] };
    const currentRepoData = { [path]: currentRepoObject };
    let newRepoObject = { ...currentRepoObject };
    delete newRepoObject[attr];
    const newRepoData = { [path]: newRepoObject };
    setCellTags(currentRepoData, newRepoData);
    set(
      produce((state: Store) => {
        delete state.repoData[path][attr];
      })
    );
  },
  deleteRepoObjectAttributes: (path, attrs) => {
    let currentRepoObject = { ...useStore.getState().repoData[path] };
    let newRepoObject = { ...currentRepoObject };

    attrs.forEach((attr) => {
      if (attr in newRepoObject) {
        delete newRepoObject[attr];
      }
    });
    const currentRepoData = { [path]: currentRepoObject };
    const newRepoData = { [path]: newRepoObject };
    setCellTags(currentRepoData, newRepoData);
    set(
      produce((state: Store) => {
        attrs.forEach((attr) => {
          delete state.repoData[path][attr];
        });
      })
    );
  },
  deleteChild: (deletionPath, childId) => {
    let deletionChildren = useStore.getState().repoData[deletionPath]?.children;
    if (deletionChildren) {
      let children = [...deletionChildren];
      let deleteField = false;
      let filteredChildren = [];
      if (Array.isArray(children)) {
        filteredChildren = children.filter((id) => id !== childId);
        if (filteredChildren?.length === 0) {
          deleteField = true;
        }
      }
      set(
        produce((state: Store) => {
          if (deleteField) delete state.repoData[deletionPath]?.children;
          else state.repoData[deletionPath].children = filteredChildren;
        })
      );
    }
  },
  setNewCellToPath: (cellId, path) => {
    const cellToPath = useStore.getState().cellToPath;
    compareRefCellToPathToCached({ ...cellToPath, [cellId]: path });
    set(
      produce((state: Store) => {
        state.cellToPath[cellId] = path;
      })
    );
  },
  deleteCellToPath: (path) => {
    set(
      produce((state: Store) => {
        delete state.cellToPath[path];
      })
    );
    let cellToPath = useStore.getState().cellToPath;
    compareRefCellToPathToCached(cellToPath);
  },
  setSelectedCell: (newCellId: string) => {
    set(
      produce((state: Store) => {
        state.selectedCell = newCellId;
      })
    );
  },
  setMultiSelect: (multiSelect: boolean) => {
    set(
      produce((state: Store) => {
        state.multiSelect = multiSelect;
      })
    );
  },
  setFileContent: (path: string, content: Object) =>
    set(
      produce((state: Store) => {
        state.repoData[path].fileContent = content;
      })
    ),
  setIsUserChoosingDirectory: (isUserChoosingDirectory: boolean) =>
    set(
      produce((state: Store) => {
        state.isUserChoosingDirectory = isUserChoosingDirectory;
      })
    ),
  setWikiContent: async (path: string, content: string) => {
    const currentWiki = useStore.getState().repoData[path]?.wiki;
    // if wiki hasn't changed, don't do anything
    if (
      (!currentWiki && !content) ||
      useStore.getState().repoData[path]?.wiki === content
    )
      return;
    await startRecordingActions("SET_WIKI");
    const currentRepoObject = { ...useStore.getState().repoData[path] };
    const currentRepoData = { [path]: currentRepoObject };
    const newRepoObject = { ...currentRepoObject, wiki: content };
    const newRepoData = { [path]: newRepoObject };
    compareNewWikiToSavedWiki(path, content);
    await setCellTags(currentRepoData, newRepoData);
    set(
      produce((state: Store) => {
        if (content === "") {
          delete state.repoData[path].wiki;
        } else {
          state.repoData[path].wiki = content;
        }
      })
    );
    await updateRepoDataInCodeCanvasIndexedDB();
    await saveRecordedActions("SET_WIKI");
  },
  setCurrentPath: (
    path: string,
    silentChange: boolean = false,
    isDrawioEventOrigin = false
  ) => {
    if (useStore.getState().pausePathChangeToDrawio) silentChange = true;
    if (
      useStore.getState()?.repoData[useStore.getState().currentPath]
        ?.visible === false &&
      path !== useStore.getState().currentPath
    )
      toggleSingleCellVisibility(
        useStore.getState().repoData[useStore.getState().currentPath].cellId,
        false
      );
    set(
      produce((state: Store) => {
        state.currentPath = path;
      })
    );
    currentPathChangeHandler(silentChange, isDrawioEventOrigin);
  },
  setReposList: (reposList: Repository[]) =>
    set(
      produce((state: Store) => {
        state.reposList = reposList;
      })
    ),
  setBranchesList: (branchesList: Branch[]) =>
    set(
      produce((state: Store) => {
        state.branchesList = branchesList;
      })
    ),
  setDiagramModified: (isDiagramModified: boolean) =>
    set(
      produce((state: Store) => {
        state.fileStatus.isDiagramModified = isDiagramModified;
      })
    ),
  setModifiedWikis: (action: string, modifiedWiki: Object) =>
    handleModifiedWikis(set, action, modifiedWiki),
  setModifiedCellToPath: (
    modifiedCellToPath: ModifiedCellToPath,
    isCellToPathModified: boolean
  ) => {
    set(
      produce((state: Store) => {
        state.fileStatus.modifiedCellToPath = modifiedCellToPath;
        state.fileStatus.isCellToPathModified = isCellToPathModified;
      })
    );
  },
  setDrawioXML: (drawioXML: string) =>
    set(
      produce((state: Store) => {
        state.diagramData.drawioXML = drawioXML;
      })
    ),
  setDrawioInterface: (drawioInterface: DrawioInterface) =>
    set(
      produce((state: Store) => {
        state.drawioInterface = drawioInterface;
      })
    ),
  setDrawioClient: (drawioClient: CustomizedDrawioClient) =>
    set(
      produce((state: Store) => {
        state.drawioClient = drawioClient;
      })
    ),
  setLoadingNotification: (loadingMessage: string) =>
    set(
      produce((state: Store) => {
        state.notification.loading = loadingMessage;
      })
    ),
  setErrorNotification: (errorMessage: string) => {
    sendGTMStateChangeEvent("errorNotification", errorMessage);
    return set(
      produce((state: Store) => {
        state.notification.error = errorMessage;
      })
    );
  },
  setSuccessNotification: (successMessage: string) => {
    sendGTMStateChangeEvent("successNotification", successMessage);
    return set(
      produce((state: Store) => {
        state.notification.success = successMessage;
      })
    );
  },
  postToDrawio: (action: Action) => {
    const drawioClient = useStore.getState().drawioClient;
    if (!drawioClient) {
      return;
    }
    drawioClient.sendAction(action);
  },
  postToDrawioWaitForResponse: async (action: Action) => {
    const drawioClient = useStore.getState().drawioClient;
    if (!drawioClient) {
      return;
    }
    const drawioResponse = await drawioClient.sendActionWaitForResponse(action);
    if (drawioResponse.status === "ERROR") {
      throw new Error(drawioResponse);
    } else {
      return drawioResponse;
    }
  },
  setDialog: (dialogType: string, dialogData: DialogDataObj = null) => {
    set(
      produce((state: Store) => {
        state.dialog.type = dialogType;
        state.dialog.data = dialogData ? dialogData : null;
      })
    );
  },
  setDiagramData: (newData: DiagramData) =>
    set(
      produce((state: Store) => {
        state.diagramData = { ...state.diagramData, ...newData };
      })
    ),
  setDiagramFileMetadata: (newData: FileMetadataObj) =>
    set(
      produce((state: Store) => {
        state.session.local.diagramFile = {
          ...state.session.local.diagramFile,
          ...newData,
        };
      })
    ),
  setLocalDirectoryMetaData: (newData: DirectoryMetadataObj) =>
    set(
      produce((state: Store) => {
        state.session.local.directory = {
          ...state.session.local.directory,
          ...newData,
        };
      })
    ),
  setToolbar: (newData: Toolbar) =>
    set(
      produce((state: Store) => {
        state.toolbar = { ...state.toolbar, ...newData };
      })
    ),
  setSessionMode: (mode: SessionMode) => {
    manageGoogleAnalyticsConsent(mode);
    sendGTMStateChangeEvent("sessionMode", mode);
    return set(
      produce((state: Store) => {
        state.session.mode = mode;
      })
    );
  },
  setReferenceDiagramFile: (fileContent: DiagramFile) =>
    set(
      produce((state: Store) => {
        state.referenceDiagramFile = fileContent;
      })
    ),
  setIsNewDiagramFileCreated: (isNewDiagramFileCreated: boolean) =>
    set(
      produce((state: Store) => {
        state.isNewDiagramFileCreated = isNewDiagramFileCreated;
      })
    ),
  setSimulations: async (simulations: Simulations) => {
    await set(
      produce((state: Store) => {
        state.simulationsState.simulations = simulations;
      })
    );
    await compareRefSimulationsToCached(simulations);
    await updateRepoDataInCodeCanvasIndexedDB();
  },
  setCurrentUnsavedSimulationObject: async (
    currentUnsavedSimulationObject: Simulation
  ) => {
    set(
      produce((state: Store) => {
        state.currentUnsavedSimulationObject = currentUnsavedSimulationObject;
      })
    );
  },
  setModifiedSimulations: (
    modifiedSimulations: ModifiedSimulations,
    isSimulationsModified: boolean
  ) => {
    set(
      produce((state: Store) => {
        state.fileStatus.modifiedSimulations = modifiedSimulations;
        state.fileStatus.isSimulationsModified = isSimulationsModified;
      })
    );
  },
  setIsEditingSimulations: async (isEditingSimulations: string) => {
    return await set(
      produce((state: Store) => {
        state.simulationsState.isEditingSimulations = isEditingSimulations;
      })
    );
  },
  setSimulationsState: (simulationsState: SimulationsState) =>
    set(
      produce((state: Store) => {
        state.simulationsState = simulationsState;
      })
    ),
  setSelectedSimulationKey: (selectedSimulationKey: string) =>
    set(
      produce((state: Store) => {
        state.simulationsState.selectedSimulationKey = selectedSimulationKey;
      })
    ),
  setCellsSetToCorrespondingVisiblityState: (
    cellsSetToCorrespondingVisiblityState: boolean
  ) =>
    set(
      produce((state: Store) => {
        state.simulationsState.cellsSetToCorrespondingVisiblityState =
          cellsSetToCorrespondingVisiblityState;
      })
    ),
  setOpenFolderOnDoubleClickFlag: (flag: boolean) =>
    set(
      produce((state: Store) => {
        state.openFolderOnDoubleClickFlag = flag;
      })
    ),
  setShowCellToFolderParentPath: (flag: boolean) =>
    set(
      produce((state: Store) => {
        state.showCellToFolderParentPath = flag;
      })
    ),
  setFuse: (fuse: any) =>
    set(
      produce((state: Store) => {
        state.fuse = fuse;
      })
    ),
  addToFuse: (paths: any) => {
    paths.forEach((path) => {
      let repoDataToAdd = useStore.getState().repoData[path];
      useStore.getState().fuse.add(repoDataToAdd);
    });
  },
  removeFromFuse: (paths: any) => {
    useStore.getState().fuse.remove((doc) => {
      return paths.includes(doc.path);
    });
  },
  setSearchResults: (searchResults: any) => {
    if (
      searchResults === null &&
      !useStore.getState().simulationsState.selectedSimulationKey
    ) {
      toggleCellsVisibility(false);
    }
    set(
      produce((state: Store) => {
        state.searchResults = searchResults;
      })
    );
  },
  setSelectedText: (selectedText: string) =>
    set(
      produce((state: Store) => {
        state.selectedText = selectedText;
      })
    ),
  setDebugMode: (debugMode: "true" | "verbose") =>
    set(
      produce((state: Store) => {
        state.debugMode = debugMode;
      })
    ),
  setStartDemo: (demoState: boolean) =>
    set(
      produce((state: Store) => {
        state.startDemo = demoState;
      })
    ),
  // used to avoid infinit loops like sell selection flickering
  setlastDrawioEventName: (drawioEventName: string) =>
    set(
      produce((state: Store) => {
        state.lastDrawioEventName = drawioEventName;
      })
    ),

  setIsRunningSimulationNotifId: (isRunningSimulationNotifId: SnackbarKey) =>
    set(
      produce((state: Store) => {
        state.simulationsState.isRunningSimulationNotifId =
          isRunningSimulationNotifId;
      })
    ),
  setCellsWithUnsavedVisibilityFlag: (
    cellsWithUnsavedVisibilityFlag: CellsWithUnsavedVisibilityFlag
  ) =>
    set(
      produce((state: Store) => {
        state.simulationsState.cellsWithUnsavedVisibilityFlag =
          cellsWithUnsavedVisibilityFlag;
      })
    ),
  setFlag: (flagName: FlagNames, flagValue: boolean) =>
    set(
      produce((state: Store) => {
        state.flags[flagName] = flagValue;
      })
    ),
  setRecentlyOpenedList: (
    recentlyOpenedList: [string, IRecentlyOpenedEntry][]
  ) =>
    set(
      produce((state: Store) => {
        state.recentlyOpenedList = recentlyOpenedList;
      })
    ),
  setShowLandingComponent: (showLandingComponent: boolean) =>
    set(
      produce((state: Store) => {
        state.showLandingComponent = showLandingComponent;
      })
    ),
  setAnonymizedMetadata: (anonymizedMetadata: AnonymizedMetadata) =>
    set(
      produce((state: Store) => {
        state.anonymizedMetadata = {
          ...state.anonymizedMetadata,
          ...anonymizedMetadata,
        };
      })
    ),
  setRecordStateChange: async (
    recordStateChange: RecordStateChange,
    trackedAction: TrackedActions
  ) => {
    return await handleRecordStateChange(
      recordStateChange,
      trackedAction,
      set,
      produce
    );
  },
  setIsPerformingUndoRedo: (isPerformingUndoRedo: boolean) =>
    set(
      produce((state: Store) => {
        state.isPerformingUndoRedo = isPerformingUndoRedo;
      })
    ),
  setOngoingUndoRedoActionsRecordings: (
    ongoingUndoRedoActionsRecordings: TrackedActions[]
  ) =>
    set(
      produce((state: Store) => {
        state.ongoingUndoRedoActionsRecordings =
          ongoingUndoRedoActionsRecordings;
      })
    ),
  setUndoRedoState: (undoRedoState: UndoRedoState) =>
    set(
      produce((state: Store) => {
        state.undoRedoState = undoRedoState;
      })
    ),
  setUnsavedSimulationsStateChanges: (
    unsavedSimulationsStateChanges: StateChange[]
  ) => {
    set(
      produce((state: Store) => {
        state.unsavedSimulationsStateChanges = unsavedSimulationsStateChanges;
      })
    );
  },
  setCheckRunImpactedCells: (checkRunImpactedCells: CheckRunImpactedCell[]) =>
    set(
      produce((state: Store) => {
        state.checkRunImpactedCells = checkRunImpactedCells;
      })
    ),
  setURLParamParsingDone: (URLParamParsingDone: boolean) =>
    set(
      produce((state: Store) => {
        state.URLParamParsingDone = URLParamParsingDone;
      })
    ),
  setVideoTutorialSection: (videoTutorialSection: VideoTutorialSection) =>
    set(
      produce((state: Store) => {
        state.videoTutorialSection = videoTutorialSection;
      })
    ),
});

const useStore = create(store);

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Store", useStore);
}

export default useStore;
