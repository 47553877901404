import styled from "styled-components";
import "./VideoTutorial.css";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import { Typography } from "@mui/material";
import useStore from "../../Store/Store";
import { useEffect, useState } from "react";

const GreyBackground = styled.div`
  position: absolute;
  width: 103.1vw;
  height: 103.1vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(15 0 2 / 80%);
  z-index: 999;
`;

const sectionToButtonMap = {
  GETTING_STARTED: {
    buttonLabel: "Why CodeCanvas (in one page)",
    link: `https://pie-crepe-38f.notion.site/Documentation-cf20cbdf134247cb93bf6366d9055076`,
  },
};

const VideoTutorial = () => {
  const { videoTutorialSection, setVideoTutorialSection } = useStore(
    (state) => ({
      videoTutorialSection: state.videoTutorialSection,
      setVideoTutorialSection: state.setVideoTutorialSection,
    })
  );

  const [buttonLabel, setButtonLabel] = useState(
    sectionToButtonMap[videoTutorialSection]?.buttonLabel
  );
  const [buttonURL, setButtonURL] = useState(
    sectionToButtonMap[videoTutorialSection]?.link
  );

  const goNotion = () => {
    window.location.assign(buttonURL);
  };

  // Sets the button label and URL based on the videoTutorialSection
  useEffect(() => {
    if (!videoTutorialSection) {
      return;
    }
    if (!(videoTutorialSection in sectionToButtonMap)) {
      console.error(`Invalid videoTutorialSection: ${videoTutorialSection}`);
      return;
    }
    setButtonLabel(sectionToButtonMap[videoTutorialSection]?.buttonLabel);
    setButtonURL(sectionToButtonMap[videoTutorialSection]?.link);
  }, [videoTutorialSection]);

  return (
    videoTutorialSection && (
      <GreyBackground>
        <iframe
          width="953"
          height="536"
          src={`https://www.youtube.com/embed/ecwcncfHuEo`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          style={{ borderRadius: 30, border: "1.5px solid #ff8b8b" }}
        />
        <div
          className="videoTutorialCloseButton"
          onClick={() => setVideoTutorialSection("")}
        >
          <ArrowCircleLeftRoundedIcon
            className="videoTutorialCloseButtonIcon"
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              left: "10vw",
              top: "5vh",
            }}
          />
        </div>
        <div className="learnMoreVideoButtonWrapper" onClick={goNotion}>
          <Typography fontSize="17px" fontWeight="Normal" color="primary">
            {buttonLabel}
          </Typography>
        </div>
      </GreyBackground>
    )
  );
};

export default VideoTutorial;
