import SimulationsNode from "./SimulationsNode";
import { SimulationsContext } from "./SimulationsTab";
import dragula from "dragula";
import { useContext, useEffect, useRef } from "react";
import { logger } from "../../../../utils/Logger";
import useStore from "../../../../Store/Store";
import {
  saveRecordedActions,
  startRecordingActions,
} from "../../../../Store/UndoManager";

interface ISimStepsProps {
  isDragging: React.MutableRefObject<boolean>;
}
export const SimSteps: React.FC<ISimStepsProps> = (props: ISimStepsProps) => {
  const { isDragging } = props;
  const { currentUnsavedSimulationObject } = useContext(SimulationsContext);

  const { isEditingSimulations, setCurrentUnsavedSimulationObject } = useStore(
    (state) => ({
      isEditingSimulations: state.simulationsState.isEditingSimulations,
      setCurrentUnsavedSimulationObject:
        state.setCurrentUnsavedSimulationObject,
    })
  );

  const simStepsRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isEditingSimulations) {
      return;
    }
    let currentDragging: HTMLElement | null = null;

    let raf: number;
    const onMouseMove = (e: MouseEvent) => {
      if (currentDragging) {
        const y = e.movementY;
        cancelAnimationFrame(raf);
        raf = requestAnimationFrame(() => {
          if (!currentDragging) return;
          currentDragging.style.transform = `translateY(${y}px)`;
        });
      }
    };

    const onMouseUp = () => {
      if (currentDragging) {
        currentDragging.style.transform = "";
        currentDragging.style.transition = "";
        currentDragging = null;
        document.removeEventListener("mouseup", onMouseUp);
      }
    };

    const drake = dragula([simStepsRef.current], {
      moves: (el, container, handle) => el.classList.contains("drag-handle"),
      slideYFactor: 0,
    });

    drake.on("drag", (el) => {
      logger.debug.info("dragging");
      isDragging.current = true;

      document.addEventListener("mouseup", onMouseUp);
      currentDragging = el;
    });

    drake.on("drop", async (el, target, source, sibling) => {
      await startRecordingActions("CURRENT_UNSAVED_SIMULATION_OBJECT");
      logger.debug.info("dropping");
      const newSimSteps = [...currentUnsavedSimulationObject.simSteps];
      const startIndex = Number(await el.getAttribute("data-index"));
      const endIndex = sibling
        ? Number(await sibling.getAttribute("data-index"))
        : newSimSteps.length - 1;

      const [removed] = newSimSteps.splice(startIndex, 1);
      if (startIndex < endIndex) {
        // corner case if it is the last element
        if (endIndex === newSimSteps.length) {
          newSimSteps.push(removed);
        } else {
          newSimSteps.splice(endIndex - 1, 0, removed);
        }
      } else {
        newSimSteps.splice(endIndex, 0, removed);
      }
      await setCurrentUnsavedSimulationObject({
        ...currentUnsavedSimulationObject,
        simSteps: newSimSteps,
      });

      await saveRecordedActions("CURRENT_UNSAVED_SIMULATION_OBJECT");

      el.style.transform = "";
      el.style.transition = "";

      currentDragging = null;
      isDragging.current = false;
    });

    document.addEventListener("mousemove", onMouseMove);

    logger.debug.info("creating dragula");
    if (!isEditingSimulations) {
      logger.debug.info("destroying dragula");
      drake?.destroy();
      document.removeEventListener("mousemove", onMouseMove);
    }
    return () => {
      logger.debug.info("destroying dragula");
      drake?.destroy();
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [
    currentUnsavedSimulationObject,
    currentUnsavedSimulationObject.simSteps,
    setCurrentUnsavedSimulationObject,
    isDragging,
    isEditingSimulations,
  ]);

  return (
    <div ref={simStepsRef} className="SimStepsList">
      {currentUnsavedSimulationObject.simSteps.map((step, index) => {
        return (
          <div
            key={step.simStepId}
            data-index={index}
            data-simstepid={step.simStepId}
            className="SimStepsListItem drag-handle"
            style={step.isEdge ? { minHeight: "30px", height: "30px" } : {}}
          >
            <SimulationsNode
              simStep={step}
              index={index}
              key={step.simStepId}
            />
          </div>
        );
      })}
    </div>
  );
};
