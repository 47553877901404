import "./SnackBars.css";
import { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import useStore from "../../Store/Store";
import { theme } from "../../Themes/Themes";
import { Tooltip } from "@mui/material";
import { discardRecordedActions } from "../../Store/UndoManager";

interface SnackBarProps extends CustomContentProps {
  snackBarType?: string;
}

const SnackBar = forwardRef<HTMLDivElement, SnackBarProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const { selectedSimulationKey } = useStore((state) => {
      return {
        selectedSimulationKey: state.simulationsState.selectedSimulationKey,
      };
    });

    const handleDismiss = useCallback(async () => {
      closeSnackbar(id);
      if (props.snackBarType === "codeLinkingMode") {
        useStore.getState().setPausePathChangeToDrawio(false);
      }
      await discardRecordedActions("*");
    }, [id, closeSnackbar, props.snackBarType]);

    return (
      <SnackbarContent
        ref={ref}
        className="snackBar"
        style={{ top: selectedSimulationKey ? "calc(7vh + 28px)" : "7vh" }}
      >
        <Card
          className="snackBarCard"
          style={{
            position: "relative",
            borderRadius: "30px",
            backgroundColor: "transparent",
            color: "#fffffffc",
            boxShadow: "none",
            height: "30px",
            paddingRight: "30px",
            background: `${theme.custom.lighterPink}`,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CardActions
            style={{
              position: "relative",
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              style={{
                position: "relative",
                left: "30px",
                height: "100%",
                verticalAlign: "middle",
              }}
            >
              {props.message}
            </Typography>
            <div style={{ position: "relative", left: "15px" }}>
              <SnackBarButton
                onClick={handleDismiss}
                snackBarType={props.snackBarType}
              />
            </div>
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  }
);

SnackBar.displayName = "SnackBar";

const SnackBarButton = (props: any) => {
  if (props.snackBarType === "codeLinkingMode") {
    return (
      <Tooltip title="Exit code linking mode">
        <IconButton
          color="primary"
          size="small"
          onClick={props.onClick}
          style={{
            color: "#fffffffc",
            position: "relative",
            left: "20px",
          }}
        >
          <HighlightOffRoundedIcon fontSize="small" style={{ width: "20px" }} />
        </IconButton>
      </Tooltip>
    );
  } else
    return (
      <Tooltip title="Close notification">
        <IconButton
          color="primary"
          size="small"
          onClick={props.onClick}
          style={{
            color: "#fffffffc",
            position: "relative",
            left: "20px",
          }}
        >
          <HighlightOffRoundedIcon fontSize="small" style={{ width: "20px" }} />
        </IconButton>
      </Tooltip>
    );
};

export default SnackBar;
