import { useContext, useEffect, useRef } from "react";
import useStore from "../../../../Store/Store";
import { SimulationsContext } from "./SimulationsTab";

interface ITopHandle {
  isDragging: React.MutableRefObject<boolean>;
  isMaxSD: boolean;
}
export const TopHandle: React.FC<ITopHandle> = (props: ITopHandle) => {
  const { isDragging, isMaxSD } = props;
  let isDraggingTopHandle = useRef(false);
  const lastSimNumber = useRef(9999);
  const {
    currentUnsavedSimulationObject,
    isEditingSimulations,
    selectedSimulationKey,
  } = useStore((state) => ({
    currentUnsavedSimulationObject: state.currentUnsavedSimulationObject,
    isEditingSimulations: state.simulationsState.isEditingSimulations,
    selectedSimulationKey: state.simulationsState.selectedSimulationKey,
  }));

  const { isRunning } = useContext(SimulationsContext);

  useEffect(() => {
    const div2ClassName =
      isEditingSimulations || isRunning
        ? ".wikiAndCodeContainer"
        : ".simulationDescriptionPreviewContainer";
    const handle = document.querySelector(
      ".SimStepsAndEditorResizeWrapper"
    ) as HTMLDivElement;
    const div1 = document.querySelector(".SimStepsContainer") as HTMLDivElement;
    const div2 = document.querySelector(div2ClassName) as HTMLDivElement;
    const container = document.querySelector(
      ".simulationBody"
    ) as HTMLDivElement;
    const minHeight = 15;
    const maxHeight = isMaxSD ? 77 : 77;

    function onHandleResize(e) {
      if (isDraggingTopHandle.current) {
        const containerRect = container.getBoundingClientRect();
        const mouseY = e.clientY - containerRect.top;
        const containerHeight = containerRect.height;
        const handleHeight = handle.getBoundingClientRect().height;
        const handleBottomPadding = 10;
        const handleTopPadding = 0;
        const offsetToBottomOfSD = isRunning || isEditingSimulations ? 60 : 40;

        let newDiv1Height = (mouseY / containerHeight) * 100;
        let newDiv2Height = 100 - newDiv1Height;

        // Apply the boundary constraint to both divs
        if (newDiv1Height < minHeight) {
          newDiv1Height = minHeight;
          newDiv2Height = maxHeight;
        } else if (newDiv2Height < minHeight) {
          newDiv1Height = maxHeight;
          newDiv2Height = minHeight;
        } else if (newDiv1Height > maxHeight) {
          newDiv1Height = maxHeight;
          newDiv2Height = minHeight;
        } else if (newDiv2Height > maxHeight) {
          newDiv1Height = minHeight;
          newDiv2Height = maxHeight;
        }
        div1.style.height = `${newDiv1Height}%`;
        div2.style.height = `max(calc(${newDiv2Height}% - ${handleHeight}px - ${handleTopPadding}px - ${handleBottomPadding}px - ${offsetToBottomOfSD}px), calc(${minHeight}%))`;
        handle.style.top = `calc(${newDiv1Height}% + ${handleTopPadding}px)`;
        div2.style.top = `calc(${newDiv1Height}% + ${handleHeight}px + ${handleTopPadding}px + ${handleBottomPadding}px)`;
      }
    }

    handle.addEventListener("mousedown", (e) => {
      isDraggingTopHandle.current = true;
    });

    document.addEventListener("resizeWikiCodeEditor", () => {
      isDraggingTopHandle.current = true;
      const numberOfSimSteps =
        useStore.getState().currentUnsavedSimulationObject.simSteps.length;

      const isNoDescription =
        !useStore.getState().currentUnsavedSimulationObject.description;
      const maxNumberOfSimSteps =
        isRunning || isEditingSimulations ? 3 : isNoDescription ? 12 : 7;

      let numberOfSimStepsToUse = Math.min(
        numberOfSimSteps,
        maxNumberOfSimSteps
      );

      // Corner case when there are no sim steps
      if (numberOfSimStepsToUse === 0) {
        numberOfSimStepsToUse = 3;
      }
      let stepHeight = 41;
      let offset = 0;

      let newSimStepsHeight = stepHeight * numberOfSimStepsToUse + offset;
      let simStepsListScrollHeight =
        document.querySelector(".SimStepsList")?.scrollHeight;
      if (
        simStepsListScrollHeight &&
        simStepsListScrollHeight < newSimStepsHeight
      ) {
        newSimStepsHeight = simStepsListScrollHeight;
      }
      onHandleResize({
        clientY: div1.getBoundingClientRect().top + newSimStepsHeight,
      });
      isDraggingTopHandle.current = false;
    });

    document.addEventListener("mousemove", onHandleResize);

    document.addEventListener("mouseup", () => {
      isDraggingTopHandle.current = false;
    });
    return () => {
      handle.removeEventListener("mousedown", () => {
        isDraggingTopHandle.current = true;
      });
      document.removeEventListener("mousemove", onHandleResize);
      document.removeEventListener("mouseup", () => {
        isDraggingTopHandle.current = false;
      });
    };
  }, [isRunning, isEditingSimulations, selectedSimulationKey, isMaxSD]);

  // reset lastSimNumber when the user starts editing the simulation or when the simulation is running
  // This is to trigger initial handle resize in the useEffect below this one
  useEffect(() => {
    // give it random number between 99 and 9999
    lastSimNumber.current = Math.floor(Math.random() * 9900) + 99;
  }, [isEditingSimulations, isRunning, selectedSimulationKey, isMaxSD]);

  // Fires a custom document event called 'resizeWikiCodoEditor' to resize the wiki and code editor when
  // the number of simulation steps changes
  useEffect(() => {
    if (
      !isDragging.current &&
      currentUnsavedSimulationObject.simSteps.length !== lastSimNumber.current
    ) {
      const event = new Event("resizeWikiCodeEditor");
      document.dispatchEvent(event);
      lastSimNumber.current = currentUnsavedSimulationObject.simSteps.length;
    }
  }, [
    currentUnsavedSimulationObject.simSteps,
    lastSimNumber,
    isDragging,
    isEditingSimulations,
    isRunning,
    selectedSimulationKey,
    isMaxSD,
  ]);

  return (
    <div
      className="SimStepsAndEditorResizeWrapper"
      style={{
        display: isEditingSimulations !== "Description" ? "flex" : "none",
      }}
    >
      <div className="verticalResizeHandle"></div>
    </div>
  );
};

interface IBottomHandle {
  simStepDescription: string;
}

export const BottomHandle: React.FC<IBottomHandle> = ({
  simStepDescription,
}) => {
  const { isRunning } = useContext(SimulationsContext);

  const { isEditingSimulations, selectedSimulationKey, currentPath } = useStore(
    (state) => ({
      isEditingSimulations: state.simulationsState.isEditingSimulations,
      selectedSimulationKey: state.simulationsState.selectedSimulationKey,
      currentPath: state.currentPath,
    })
  );
  let isDraggingBottomHandle = useRef(false);

  useEffect(() => {
    const handle = document.querySelector(
      ".codeWikiResizeWrapper"
    ) as HTMLDivElement;

    const container = document.querySelector(
      ".wikiAndCodeContainer"
    ) as HTMLDivElement;

    const div1 = container.querySelector(".wikiContainer") as HTMLDivElement;
    const div2 = container.querySelector(
      ".simulationCodeContainer"
    ) as HTMLDivElement;

    const minHeight = 3;
    const maxHeight = 97;

    handle.addEventListener("mousedown", (e) => {
      isDraggingBottomHandle.current = true;
    });

    document.addEventListener("resizeWikiCode-bottomHandle", () => {
      if (isEditingSimulations) {
        return;
      }
      isDraggingBottomHandle.current = true;
      const textEditorChildren = div1.querySelector(".text-editor")?.children;

      let wikiInnerScrollHeightPx = null;
      let wikiLines =
        textEditorChildren?.length > 0 ? textEditorChildren[0]?.children : null;
      if (textEditorChildren?.length > 0 && wikiLines?.length > 0) {
        for (let i = 0; i < wikiLines.length; i++) {
          // the ptags have margins top and bottom and height, we need to sum them up and convert to px
          let pTag = wikiLines[i] as HTMLParagraphElement;
          let pTagMarginTop = parseInt(window.getComputedStyle(pTag).marginTop);
          let pTagMarginBottom = parseInt(
            window.getComputedStyle(pTag).marginBottom
          );
          let pTagHeight = parseInt(window.getComputedStyle(pTag).height);
          wikiInnerScrollHeightPx +=
            pTagMarginTop + pTagMarginBottom + pTagHeight;
        }
      }

      let wikiInnerScrollHeight = 40;
      let bottomOffset = 20;
      // In percentage of containers height
      if (wikiInnerScrollHeightPx) {
        wikiInnerScrollHeight =
          ((wikiInnerScrollHeightPx + bottomOffset) * 100) /
          container?.clientHeight;
      }

      let simStepId = useStore.getState().simulationsState.currentStep;
      let simSteps =
        useStore.getState().currentUnsavedSimulationObject?.simSteps;
      let simStep: SimStep = simSteps.find(
        (step) => step.simStepId === simStepId
      );
      let simStepDescription = simStep?.simStepDescription;
      const repoData = useStore.getState().repoData;
      const currentPath = useStore.getState().currentPath;

      let isNoDescription =
        !textEditorChildren ||
        textEditorChildren?.length === 0 ||
        (textEditorChildren[0]?.children?.length < 2 && !simStepDescription);
      let isNoLinkedCode =
        !repoData[currentPath]?.fileContent &&
        !repoData[currentPath]?.startLine;

      let autoSizeMaxHeight = 97;
      let autoSizeMinHeight = 3;

      if (isNoDescription) {
        wikiInnerScrollHeight = 0;
      }
      if (!isNoLinkedCode) {
        autoSizeMaxHeight = 47;
      }
      if (isNoDescription && isNoLinkedCode) {
        wikiInnerScrollHeight = 40;
      }

      let resizeHeight = Math.min(wikiInnerScrollHeight, autoSizeMaxHeight);
      resizeHeight = Math.max(resizeHeight, autoSizeMinHeight);

      onHandleResize({
        clientY:
          div1.getBoundingClientRect().top +
          container?.clientHeight * (resizeHeight / 100),
      });
      isDraggingBottomHandle.current = false;
    });

    document.addEventListener("resizeBottomHandleOnEnteringEditMode", () => {
      isDraggingBottomHandle.current = true;
      let resizeHeight = 50;

      onHandleResize({
        clientY:
          div1.getBoundingClientRect().top +
          container?.clientHeight * (resizeHeight / 100),
      });
      isDraggingBottomHandle.current = false;
    });

    function onHandleResize(e) {
      if (isDraggingBottomHandle.current) {
        const containerRect = container.getBoundingClientRect();
        const mouseY = e.clientY - containerRect.top;
        const containerHeight = containerRect.height;
        const handleVerticalOffset = 8;
        const handleHeight = handle.getBoundingClientRect().height;

        let newDiv1Height = (mouseY / containerHeight) * 100;
        let newDiv2Height = 100 - newDiv1Height;

        // Apply the boundary constraint to both divs
        if (newDiv1Height < minHeight) {
          newDiv1Height = minHeight;
          newDiv2Height = maxHeight;
        } else if (newDiv2Height < minHeight) {
          newDiv1Height = maxHeight;
          newDiv2Height = minHeight;
        } else if (newDiv1Height > maxHeight) {
          newDiv1Height = maxHeight;
          newDiv2Height = minHeight;
        } else if (newDiv2Height > maxHeight) {
          newDiv1Height = minHeight;
          newDiv2Height = maxHeight;
        }

        div1.style.height = `${newDiv1Height}%`;
        div2.style.height = `max(calc(${newDiv2Height}% - ${handleHeight}px - ${handleVerticalOffset}px - ${handleVerticalOffset}px), calc(${minHeight}%))`;
        handle.style.top = `calc(${newDiv1Height}% + ${handleVerticalOffset}px)`;
        div2.style.top = `calc(${newDiv1Height}% + ${handleHeight}px + ${handleVerticalOffset}px + ${handleVerticalOffset}px)`;
      }
    }
    document.addEventListener("mousemove", onHandleResize);

    document.addEventListener("mouseup", (e) => {
      isDraggingBottomHandle.current = false;
    });
    return () => {
      handle.removeEventListener("mousedown", () => {
        isDraggingBottomHandle.current = true;
      });
      document.removeEventListener("mousemove", onHandleResize);
      document.removeEventListener("mouseup", () => {
        isDraggingBottomHandle.current = false;
      });
    };
  }, [isEditingSimulations, isRunning, selectedSimulationKey]);

  // Fires a custom document event called 'resizeWikiCodoEditor' to resize the wiki and code editor
  useEffect(() => {
    if (!isEditingSimulations) {
      const event = new Event("resizeWikiCode-bottomHandle");
      document.dispatchEvent(event);
    }
  }, [
    simStepDescription,
    isEditingSimulations,
    isRunning,
    currentPath,
    selectedSimulationKey,
  ]);

  // Fires an event to resize the wiki and code editor when entering edit mode only once
  useEffect(() => {
    if (isEditingSimulations) {
      const event = new CustomEvent("resizeBottomHandleOnEnteringEditMode");
      document.dispatchEvent(event);
    }
  }, [isEditingSimulations, selectedSimulationKey]);

  return (
    <div className="codeWikiResizeWrapper">
      <div className="verticalResizeHandle"></div>
    </div>
  );
};
