import { useState, useEffect, useCallback } from "react";
import useStore from "../../../Store/Store";
import "./Path.css";

interface IPathPart {
  key: number;
  repoDataPath?: string;
}

const PathPart: React.FC<IPathPart> = (props: IPathPart) => {
  const { repoDataPath } = props;
  const {
    selectedTab,
    setSelectedTab,
    currentPath,
    repoData,
    currentRepo,
    setCurrentPath,
    setOpenFolderOnDoubleClickFlag,
    setSearchResults,
  } = useStore((state) => ({
    selectedTab: state.selectedTab,
    setSelectedTab: state.setSelectedTab,
    currentPath: state.currentPath,
    repoData: state.repoData,
    currentRepo: state.currentRepo,
    setCurrentPath: state.setCurrentPath,
    setOpenFolderOnDoubleClickFlag: state.setOpenFolderOnDoubleClickFlag,
    setSearchResults: state.setSearchResults,
  }));
  const [pathPart, setPathPart] = useState(repoData[repoDataPath]);

  useEffect(() => {
    setPathPart(repoData[repoDataPath]);
  }, [repoDataPath, repoData]);

  const pathClickHandler = useCallback(() => {
    if (repoDataPath) {
      setSearchResults(null);
      setOpenFolderOnDoubleClickFlag(true);
      setCurrentPath(repoDataPath);
    } else {
      setCurrentPath(currentRepo);
      setSearchResults(null);
    }
    if (selectedTab === 1) setSelectedTab(0);
  }, [
    setOpenFolderOnDoubleClickFlag,
    setCurrentPath,
    currentRepo,
    setSelectedTab,
    selectedTab,
    repoDataPath,
    setSearchResults,
  ]);

  const pathRenderHandler = () => {
    let pathPartString = "";
    if (pathPart) {
      if (pathPart.startLine && pathPart.endLine) {
        if (pathPart.startLine === pathPart.endLine) {
          pathPartString += ` > L${pathPart.startLine} `;
        } else {
          pathPartString += ` > L${pathPart.startLine}:L${pathPart.endLine} `;
        }
      } else {
        pathPartString = ` > ${
          pathPart.fileName || pathPart.cellName || "Untitled Cell"
        } `;
      }
      return pathPartString;
    } else {
      return currentRepo;
    }
  };

  return (
    <p
      onClick={() => {
        pathClickHandler();
      }}
      style={{
        marginRight: 4,
        fontWeight:
          pathPart?.fileName &&
          repoData[currentPath]?.fileName &&
          pathPart?.fileName === repoData[currentPath]?.fileName
            ? "bold"
            : "",
        userSelect: "none",
      }}
    >
      {pathRenderHandler()}
    </p>
  );
};

export const Path: React.FC = () => {
  const [path, setPath] = useState(null);
  const {
    currentPath,
    currentRepo,
    repoData,
    cellToPath,
    setCurrentPath,
    searchResults,
  } = useStore((state) => ({
    currentPath: state.currentPath,
    currentRepo: state.currentRepo,
    repoData: state.repoData,
    cellToPath: state.cellToPath,
    setCurrentPath: state.setCurrentPath,
    searchResults: state.searchResults,
  }));
  // render method to loop through path and create elements
  const renderPath = useCallback(
    (pathToRender?: any, isChildCell: boolean = false) => {
      let renderedPath = [<PathPart key={0} />];
      if (!pathToRender) {
        return [renderedPath, <p key={"multi"}> {"> Multiple selected! "} </p>];
      } else if (searchResults) {
        renderedPath = [
          <p key={0} style={{ marginRight: 5 }}>
            {" "}
            {"Search results > "}{" "}
          </p>,
        ];
        if (searchResults.length === 0)
          renderedPath.push(
            <p
              style={{
                fontWeight: "bold",
              }}
              key={1}
            >
              None!
            </p>
          );
      }

      let splitPathToRender = [pathToRender];
      if (
        repoData[pathToRender]?.type === "blob" ||
        repoData[pathToRender]?.type === "tree" ||
        repoData[pathToRender]?.parentPath
      ) {
        splitPathToRender = pathToRender.split("/");
      }
      if (
        splitPathToRender[0] === currentRepo &&
        splitPathToRender?.length === 1 &&
        !searchResults
      ) {
        return [<PathPart key={0} />];
      } else {
        renderedPath =
          searchResults && currentPath !== currentRepo
            ? [...renderedPath, <PathPart key={1} />]
            : renderedPath;

        for (var i = 1; i <= splitPathToRender.length; i++) {
          var repoDataPath = splitPathToRender.slice(0, i).join("/");
          if (!searchResults || (searchResults && currentPath !== currentRepo))
            renderedPath.push(
              <PathPart key={i + 1} repoDataPath={repoDataPath} />
            );
        }

        if (isChildCell) {
          renderedPath.push(
            <PathPart key={i + 1} repoDataPath={currentPath} />
          );
        }

        return renderedPath;
      }
    },
    [currentRepo, currentPath, repoData, searchResults]
  );

  useEffect(() => {
    if (currentPath in repoData) {
      let parentCellId = repoData[currentPath]?.parentCellId;
      let parentPath =
        cellToPath[parentCellId] || repoData[currentPath]?.parentPath;
      if (parentPath) {
        setPath(renderPath(parentPath, true));
      } else {
        setPath(renderPath(currentPath));
      }
    } else if (currentPath === "multi") {
      setPath(renderPath());
    } else {
      // set path to repo root
      setCurrentPath(currentRepo);
    }
  }, [
    cellToPath,
    currentPath,
    repoData,
    currentRepo,
    setCurrentPath,
    renderPath,
  ]);

  return (
    <div className="pathContainer">
      {path ? path : <p>Choose a repository! </p>}
    </div>
  );
};
