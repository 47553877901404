import { Typography } from "@mui/material";
import { useContext } from "react";
import { TextEditor } from "../../../TextEditor/TextEditor";
import useStore from "../../../../Store/Store";

import "./SimulationDescription.css";
import { SimulationsContext } from "./SimulationsTab";

export const SimulationDescription: React.FC = () => {
  const {
    currentUnsavedSimulationObject,
    isEditingSimulations,
    setIsEditingSimulations,
    setCurrentUnsavedSimulationObject,
  } = useStore((state) => ({
    currentUnsavedSimulationObject: state.currentUnsavedSimulationObject,
    isEditingSimulations: state.simulationsState.isEditingSimulations,
    setIsEditingSimulations: state.setIsEditingSimulations,
    setCurrentUnsavedSimulationObject: state.setCurrentUnsavedSimulationObject,
  }));

  const { isMaxSD, setSimulationDescription, simulationDescription } =
    useContext(SimulationsContext);

  const handleSimulationDescriptionChange = async (data) => {
    setSimulationDescription(data);
    await setCurrentUnsavedSimulationObject({
      ...useStore.getState().currentUnsavedSimulationObject,
      description: data,
    });
  };

  return (
    <>
      {isEditingSimulations === "Description" && (
        <div className="simulationDescriptionEditContainer">
          <div className="text-editor-container">
            <TextEditor
              content={
                simulationDescription ||
                currentUnsavedSimulationObject.description ||
                ""
              }
              disabled={false}
              handleWikiChange={handleSimulationDescriptionChange}
              isMaxSD={isMaxSD}
            />
          </div>
        </div>
      )}

      {isEditingSimulations !== "Description" && (
        <div className="simulationDescriptionPreviewContainer">
          {!currentUnsavedSimulationObject.description && (
            <div
              className="largeGreyButton"
              onClick={async (e) => {
                await setIsEditingSimulations("Description");
              }}
            >
              <Typography variant="h6" fontWeight={"thin"}>
                + Add Simulation Overview
              </Typography>
            </div>
          )}
          {currentUnsavedSimulationObject.description && (
            <div className="simulationDescriptionInnerContainer">
              <Typography
                variant="subtitle1"
                color="primary"
                className="boxTitle"
                style={{ zIndex: 99999 }}
              >
                Simulation Overview
              </Typography>

              <div className="wikiDiv" style={{ height: "calc(100%)" }}>
                <div className="textEditorContainer">
                  {
                    <TextEditor
                      content={currentUnsavedSimulationObject.description}
                      disabled={true}
                      handleWikiChange={handleSimulationDescriptionChange}
                      isMaxSD={isMaxSD}
                    />
                  }
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
