type Props = {
  message: string;
};

const MessageDisplay: React.FC<Props> = ({ message }) => {
  const messageLines = message?.split("\n").map((line, index) => (
    <span
      key={index}
      className="message-line"
      style={{
        display: "block",
      }}
    >
      {line}
    </span>
  ));

  return <>{messageLines}</>;
};

export default MessageDisplay;
