interface ITabPanel {
  children?: React.ReactNode;
  value: Number;
  index: Number;
}

export const TabPanel: React.FC<ITabPanel> = (props: ITabPanel) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="TabPanel"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};
