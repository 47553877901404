import { Dialog as MUIDialog, DialogContent, Typography } from "@mui/material";
import useStore from "../../Store/Store";
import { theme } from "../../Themes/Themes";
import "./Dialog.css";

export default function WarningDialog() {
  const { dialog, setDialog, setDeleteMode } = useStore((state) => ({
    dialog: state.dialog,
    setDialog: state.setDialog,
    currentRepo: state.currentRepo,
    setCurrentPath: state.setCurrentPath,
    setDeleteMode: state.setDeleteMode,
  }));

  async function handleDeleteCells(deleteMode: "saveWiki" | "deleteWiki") {
    try {
      setDeleteMode(deleteMode);
      useStore.getState().postToDrawio({
        drawioRequestId: dialog.data.data.drawioRequestId,
        action: "CONTINUE_DELETE",
        data: "Continue",
      });
    } catch (error) {
      console.error(error);
    }
    closeDialog();
  }

  async function closeDialog() {
    if (dialog.data?.button2Function) {
      await dialog.data.button2Function();
    }
    setDialog("");
  }

  return (
    <MUIDialog
      open={dialog.type === "DELETE_WARNING" && dialog.data !== null}
      sx={{
        "& .MuiPaper-root": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          borderRadius: "30px",
          width: "50vw",
        },
      }}
      onClose={closeDialog}
    >
      <DialogContent className="DialogContent">
        <br />
        <Typography
          textAlign={"center"}
          mx={3}
          my={0.8}
          fontSize="18px"
          fontWeight="Light"
          color={theme.custom.darkerGrey}
          sx={{ paddingBottom: "10px" }}
        >
          {dialog?.data?.message}
        </Typography>
        <div
          className="DialogButtonGroupVertical"
          style={{ paddingBottom: "10px" }}
        >
          <div
            className="Button FullDialogButton"
            onClick={() => handleDeleteCells("deleteWiki")}
          >
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontWeight="Normal"
              color="#FFAEA6"
            >
              Delete all linked wikis!
            </Typography>
          </div>
          {dialog?.data?.data?.isLinkedToFile && (
            <div
              className="Button FullDialogButton"
              onClick={() => handleDeleteCells("saveWiki")}
            >
              <Typography
                mx={0.2}
                my={0.8}
                fontSize="15px"
                fontWeight="Normal"
                color="#FFAEA6"
              >
                Keep wiki's in linked file!
              </Typography>
            </div>
          )}
          <div className="Button FullDialogButton" onClick={closeDialog}>
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontWeight="Normal"
              color="#FFAEA6"
            >
              Cancel
            </Typography>
          </div>
        </div>
      </DialogContent>
    </MUIDialog>
  );
}
