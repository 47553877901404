import { Dialog as MUIDialog, DialogContent, Typography } from "@mui/material";
import useStore from "../../Store/Store";
import { theme } from "../../Themes/Themes";
import "./Dialog.css";
import { linkFileToNodeHandler } from "../../Store/StoreHandlers";
import { discardRecordedActions } from "../../Store/UndoManager";

export default function OverwriteDialog() {
  const { dialog, setDialog, repoData, selectedCell, currentPath } = useStore(
    (state) => ({
      dialog: state.dialog,
      setDialog: state.setDialog,
      repoData: state.repoData,
      currentPath: state.currentPath,
      selectedCell: state.selectedCell,
    })
  );

  async function handleOverwrite(destination: string) {
    try {
      await linkFileToNodeHandler(destination);
    } catch (error) {
      console.error(error);
    }
    closeDialog();
  }

  async function closeDialog() {
    if (dialog.data?.button2Function) {
      await dialog.data.button2Function();
    }
    setDialog("");
  }

  async function handleCancel() {
    closeDialog();
  }
  return (
    <MUIDialog
      open={dialog.type === "OVERWRITE_DIALOG" && dialog.data !== null}
      sx={{
        "& .MuiPaper-root": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          borderRadius: "30px",
          width: "50vw",
        },
      }}
      onClose={closeDialog}
    >
      <DialogContent className="DialogContent">
        <br />
        <Typography
          textAlign={"center"}
          mx={3}
          my={0.8}
          fontSize="18px"
          fontWeight="Light"
          color={theme.custom.darkerGrey}
          sx={{ paddingBottom: "10px" }}
        >
          {dialog?.data?.message}
        </Typography>
        <div
          className="DialogButtonGroupVertical"
          style={{ paddingBottom: "10px" }}
        >
          <div
            className="Button FullDialogButton"
            onClick={() => handleOverwrite(selectedCell)}
          >
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontWeight="Normal"
              color="#FFAEA6"
            >
              Overwrite diagram cell:{" "}
              {repoData[selectedCell]?.cellName || "Untiteld Cell"}
            </Typography>
          </div>
          <div
            className="Button FullDialogButton"
            onClick={() => handleOverwrite(currentPath)}
          >
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontWeight="Normal"
              color="#FFAEA6"
            >
              Overwrite repository item: {repoData[currentPath]?.fileName}
            </Typography>
          </div>
          <div className="Button FullDialogButton" onClick={handleCancel}>
            <Typography
              mx={0.2}
              my={0.8}
              fontSize="15px"
              fontWeight="Normal"
              color="#FFAEA6"
            >
              Cancel
            </Typography>
          </div>
        </div>
      </DialogContent>
    </MUIDialog>
  );
}
