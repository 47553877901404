import useStore from "../Store/Store";

export const manageGoogleAnalyticsConsent = (
  sessionMode: SessionMode
): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "consent_update",
    analytics_storage: sessionMode === "local" ? "denied" : "granted",
  });
};

export default function sendGTMStateChangeEvent(variableName, value) {
  const anonCurrentRepo = useStore.getState().anonymizedMetadata.currentRepo;
  const anonCurrentBranch =
    useStore.getState().anonymizedMetadata.currentBranch;
  const username = useStore.getState().session.user.username;

  const GTMEvent = {
    event: "StateChange", // required by GTM
    eventName: "",
    variableStateChange: {},
    metadata: {
      username: username,
      currentRepo: anonCurrentRepo,
      currentBranch: anonCurrentBranch,
      isLoggedIn: useStore.getState().session.isLoggedIn,
      sessionMode: useStore.getState().session.mode,
    },
  };

  // if currentRepo, currentBranch, or username are in the variableName, replace with anonymized values
  const currentRepo = useStore.getState().currentRepo;
  const currentBranch = useStore.getState().currentBranch;

  // deep copy value
  let anonValue = JSON.parse(JSON.stringify(value));

  if (anonValue.includes(currentRepo)) {
    anonValue = anonValue.replace(currentRepo, anonCurrentRepo);
  }
  if (anonValue.includes(currentBranch)) {
    anonValue = anonValue.replace(currentBranch, anonCurrentBranch);
  }

  GTMEvent.variableStateChange[variableName] = anonValue;
  GTMEvent.eventName = `SC: ${variableName} -> ${anonValue}`;

  if (window.dataLayer) {
    window.dataLayer.push(GTMEvent);
  }
}
