import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "./TextEditor.css";
import { logger } from "../../utils/Logger";

interface ITextEditor {
  content: string;
  handleWikiChange: Function;
  disabled: boolean;
  isMaxSD: boolean;
  customStyle?: object;
}

function setEditorStyles(
  editor: any,
  setUpdate: any,
  update: boolean,
  disabled: boolean
) {
  if (disabled) {
    // remove the toolbar
    editor.ui?.view.toolbar.element.remove();

    const textEditorContent: HTMLWebViewElement =
      document.querySelector(
        ".ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only"
      ) ||
      document.querySelector(
        ".ck-focused.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline"
      );

    const toolbar = document.querySelector(".ck.ck-toolbar") as HTMLElement;
    if (toolbar) {
      toolbar.remove();
    }

    if (textEditorContent) {
      textEditorContent.style.height = "100%";
      textEditorContent.style.top = "0px";
      textEditorContent.style.wordBreak = "break-word";
    }
  } else {
    // Insert the toolbar before the editable area.
    if (editor?.ui?.view?.toolbar?.element?.style) {
      editor.ui.view.toolbar.element.style.zIndex = "9999";
      editor.ui.view.toolbar.element.style.position = "sticky";
      editor.ui.view.toolbar.element.style.width = "100%";
      editor.ui.view.toolbar.element.style.height = "40px";
      editor.ui.view.toolbar.element.style.top = "0px";
    }

    const textEditor = document.querySelector(".text-editor") as HTMLElement;
    // get the content, the second child
    const textEditorContent: HTMLWebViewElement = textEditor
      ?.children[1] as HTMLWebViewElement;

    if (textEditorContent) {
      textEditorContent.style.position = "absolute";
      textEditorContent.style.height = "calc(100% - 60px)";
      textEditorContent.style.top = "60px";
      textEditorContent.style.zIndex = "99";
      textEditorContent.style.width = "100%";
      textEditorContent.style.wordBreak = "break-word";
    }

    editor?.ui
      ?.getEditableElement()
      ?.parentElement.insertBefore(
        editor?.ui.view.toolbar.element,
        editor?.ui.getEditableElement()
      );
  }

  setUpdate(!update);
}

export const TextEditor: React.FC<ITextEditor> = (props: ITextEditor) => {
  const [update, setUpdate] = useState(false);

  const { content, handleWikiChange, disabled, isMaxSD, customStyle } = props;

  return (
    <div
      className="text-editor"
      style={customStyle ? customStyle : { maxHeight: isMaxSD ? "90%" : "90%" }}
    >
      {disabled && (
        <CKEditor
          onReady={(editor) => {
            setEditorStyles(editor, setUpdate, update, true);
          }}
          onFocus={(editor) => {
            setEditorStyles(editor, setUpdate, update, true);
          }}
          onBlur={(editor) => {
            setEditorStyles(editor, setUpdate, update, true);
          }}
          onError={(error, { willEditorRestart }) => {
            logger.error(error);
          }}
          disabled={disabled}
          onChange={(event, editor) => {
            let currentData = editor.getData();
            handleWikiChange(currentData);
          }}
          editor={DecoupledEditor}
          data={content}
          config={{
            link: {
              addTargetToExternalLinks: true,
            },
          }}
        />
      )}
      {!disabled && (
        <CKEditor
          onReady={(editor) => {
            setEditorStyles(editor, setUpdate, update, false);
          }}
          onFocus={(editor) => {
            setEditorStyles(editor, setUpdate, update, false);
          }}
          onBlur={(editor) => {
            setEditorStyles(editor, setUpdate, update, false);
          }}
          onError={(error, { willEditorRestart }) => {
            logger.error(error);
          }}
          disabled={disabled}
          onChange={(event, editor) => {
            let currentData = editor.getData();
            handleWikiChange(currentData);
          }}
          editor={DecoupledEditor}
          data={content}
        />
      )}
    </div>
  );
};

export default TextEditor;
