import { useEffect } from "react";
import useStore from "../Store/Store";
import { getDiffs } from "./DeepObjectCompare";
import useDebugInformation from "./useDebugInformation";

const DebugInformationComponent: React.FC = () => {
  // Diffs the state of the store
  useDebugInformation("Store State", useStore.getState());

  return <></>;
};

// Only renders when debugMode state in store is true
const DevTools: React.FC = () => {
  const { debugMode } = useStore((state) => ({
    debugMode: state.debugMode,
  }));

  // Gives access to handy functions from window
  useEffect(() => {
    window["useStore"] = useStore;
    window["getDiff"] = getDiffs;
    return () => {
      window["useStore"] = null;
      window["getDiff"] = null;
    };
  }, []);

  return <>{debugMode === "verbose" && <DebugInformationComponent />}</>;
};

export default DevTools;
